import React from 'react';

function Feb_15_2024() {
  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        <div className="post-preview">
          <a data-bs-toggle="collapse" href="#collapsePRT17" aria-expanded="false" aria-controls="collapsePRT17" style={{ textDecoration: 'none' }} >
            <h3>ጋዜጣዊ መግለጺ ፈልሲ ሰላምን ደሞክራስን ኤርትራ
              ኣብ ጉዳይ ምንቅስቓስ ንብሩህ መጻኢ ኤርትራዉያን</h3>
          </a>
          <div className="collapse" id="collapsePRT17">
            <article>
              <div>
                <p>ምቅስቓስ ንብሩህ መጻኢ ኤርትራዉያን ብዝምልከት፡ ብዝተፈላለዩ ወገናት ኣብ ዝተፈላለያ ማሕበራዊ መራኸቢ ብዙሓን ዝመሓላለፍ ዘለዉ መልእክትን ሓሳባትን ፈልሲ ሰላምን ደሞክራስን ኤርትራ ይከታተሎ ኣሎ።</p>
                <p>ምንቅስቓስ ንብሩህ መጻኢ ኤርትራዉያን፡ ኣብዚ ኣንጻር ህግደፍ ዝካየድ ዘሎ ቃልሲ፡ ዓቢን ግሉሕን ተራ ዝጸንሖን ዘለዎን ምንቅስቓስ ኢዩ። ምንቅስቓስ ንብሩህ መጻኢ ኤርትራዉያን፡ መንእሰይ ኤርትራ ነቶም ኣቐዲሞም ቀይዶሞ ዝነበሩ ናይ ታሪኽ ማሕለኻታት ብሓይሊን ብትብዓትን ሰይሩ ንኽወጽእን ንመድረኽ ዝበቅዕ ሓዱሽ ኣረኣእያታትን ሓሳባትን ሰኒቑ ክጓዕዝ ኣብ ምግባር መሪሕ ተራ ክጻወት ጸኒሑን ኣሎን።</p>
                <p>ምንቅስቓስ ንብሩህ መጻኢ ኤርትራዉያን፡ ኣብ መስርሕ ከጋጥምዎ ዝጸንሑ ዕንቅፋታት፡ ብትብዓትን ብብስለትን ክፈትሖምን ክሰግሮምን ጸኒሑ ኢዩ። ሕጂውን እቲ ምንቅስቓስ ንኸጋጥምዎ ዝኽእሉ ብድሆታት ከም ልማዱ ክሰግሮ ምዃኑ ፈልሲ ሰላምን ደሞክራስን ጽኑዕ እምንቶ ኣለዎ።</p>
                <p>ፈልሲ ሰላምን ዲሞክራስን ኤርትራ፣ ምንቅስቓስ ንብሩህ መጻኢ ኤርትራዉያን፡ እሙንን መቃልስቲ ኣካልን ምዃኑ ይኣምን። ፈልሲ ሰላምን ደሞክራስን ኤርትራ ካብ ዝምሰረት ጀሚሩ፡ ምስ ምንቅስቃስ ንብሩህ መጻኢ ኤርትራዉያን ኣብ ዝተፈላለየ ጉዳያት ክዓምዎ ዝጸንሑ ናይ ሓባር ስርሓት፡ ሕጂዉን ብዝበለጸን ብዝሓየለን ክቕጽልዎ ምዃኖም ርግጽ እዩ።</p>
                <p>በዚ መሰረት፡ ምንቅስቓስ ንብሩህ መጻኢ ኤርትራዉያን ኣብዚ ሎሚ ቕነ ኣጓኒፍዎ ዘሎ ሒቕታ ኣብ ምፍታሕ፡ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ክገብሮ ዝኽእል ነገር እንተሎ፡ ዝከኣሎ ክገብር ቁሩብ ምዃኑ የረጋግጽ። በዚ ኣጋጣሚ ኩሎም ተቓወምቲ ሓይሊታት፡ መራኸቢ ብዙሓን ከምእዉን ኣብ ደንበ ፍትሒ ዘሎ ሕብረተሰብና ብሓፈሻ እወንታዊ ተራ ክጻወቱ ሓደራ ይብል።</p>
                <p>ፈጻሚ ኣካል ፈልሲ ሰላም ዲሞክራስን ኤርትራ
                  <br />
                  15 ለካቲት 2024
                </p>
              </div>
            </article>
            <p><a href="assets/pdf/press_release_17_tig.pdf">PDF ትግርኛ</a>.</p>
          </div>
          <p className="post-meta">
            Posted on Feb 15, 2024
          </p>
        </div>
      </div>
      <div className="col-sm-6">
        
        <div className="post-preview">
          {/* <a data-bs-toggle="collapse" href="#collapsePRE17" aria-expanded="false" aria-controls="collapsePRE16">
            <h3></h3>
          </a>
          <div className="collapse" id="collapsePRE16">
            <article>
              <div>
                <p></p>
              </div>
            </article>
            <p><a href="assets/pdf/press_release_17_eng.pdf">PDF English</a>.</p>
          </div>
          <p className="post-meta">
            Posted on Feb 15, 2024
          </p> */}
        </div>
      </div>
    </div>
 );
};

export default Feb_15_2024;