import React, { useState } from 'react';

function JoinForm() {
    const [formData, setFormData] = useState({
        fullName: '',
        gender: '',
        email: '',
        phoneNumber: '',
        country: '',
        city: '',
        birthPlace: '',
        spokenLanguages: '',
        profession: '',
        affiliation: ''
    });

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = 'https://api.startbootstrap.com/api/latest/solution/forms';
        const payload = {
            token: '34688f13-f03a-429a-ac98-1e05ad684128',
            submissionObject: {
            fullName_ምሉእስም: formData.fullName,
            gender_ጾታ: {
                male: formData.gender === 'male' ? 'true' : 'false',
                female: formData.gender === 'female' ? 'true' : 'false',
                other: formData.gender === 'other' ? 'true' : 'false'
            },
            emailAddress_ኢሜይል: formData.emailAddress,
            phoneNumber_ቁቴሌፎን: formData.phoneNumber,
            country_እትነብሩሉ_ዓዲ: formData.country,
            city_እትነብሩሉ_ከተማ: formData.city,
            birthPlace_ዝተወለድክምሉ_ቦታ: formData.birthPlace,
            spokenLanguages_እትዛረብሉ_ቛንቛታት: formData.spokenLanguages,
            profession_ሞያ_ደረጃ_ትምህርቲ: formData.profession,
            affiliation_ዘለኹሞ_ውደባ_ምንቅስቓስ: formData.affiliation
            }
        };

        try {
            const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            });

            if (!response.ok) {
                setSubmitError(true);
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                setSubmitSuccess(true);
            }

            const data = await response.json();
            console.log('Response:', data);
            // Handle the response data

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                    <input className="form-control" id="fullName" name="fullName" type="text" placeholder="Full Name | ምሉእ ስም" value={formData.fullName} onChange={handleInputChange} required />
                    <label htmlFor="fullName">Full Name | ምሉእ ስም</label>
                </div>
                <div className="mb-3">
                    <label className="form-label d-block">Gender | ጾታ</label>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="male" type="radio" name="gender" value="Male" checked={formData.gender === 'Male'} onChange={handleInputChange} required />
                        <label className="form-check-label" htmlFor="male">Male</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="female" type="radio" name="gender" value="Female" checked={formData.gender === 'Female'} onChange={handleInputChange} required />
                        <label className="form-check-label" htmlFor="female">Female</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="other" type="radio" name="gender" value="Other" checked={formData.gender === 'Other'} onChange={handleInputChange} required />
                        <label className="form-check-label" htmlFor="other">Other</label>
                    </div>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="emailAddress" name="email" type="email" placeholder="Email Address | ኢ-ሜይል" value={formData.email} onChange={handleInputChange} required />
                    <label htmlFor="emailAddress">Email Address | ኢ-ሜይል</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="phoneNumber" name="phoneNumber" type="tel" placeholder="Phone number | ቁ. ቴሌፎን" pattern="[0-9]{1,14}" value={formData.phoneNumber} onChange={handleInputChange} required />
                    <label htmlFor="phoneNumber">Phone number | ቁ. ቴሌፎን</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="country" name="country" type="text" placeholder="Country | እትነብሩሉ ዓዲ" value={formData.country} onChange={handleInputChange} required />
                    <label htmlFor="country">Country | እትነብሩሉ ዓዲ</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="city" name="city" type="text" placeholder="City | እትነብሩሉ ከተማ" value={formData.city} onChange={handleInputChange} required />
                    <label htmlFor="city">City | እትነብሩሉ ከተማ</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="birthPlace" name="birthPlace" type="text" placeholder="Birth Place | ዝተወለድክምሉ ቦታ" value={formData.birthPlace} onChange={handleInputChange} required />
                    <label htmlFor="birthPlace">Birth Place | ዝተወለድክምሉ ቦታ</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="spokenLanguages" name="spokenLanguages" type="text" placeholder="Spoken Languages | ተወሳኺ ቛንቛታት" value={formData.spokenLanguages} onChange={handleInputChange} />
                    <label htmlFor="spokenLanguages">Spoken Languages | እትዛረብሉ ቛንቛታት</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="profession" name="profession" type="text" placeholder="Profession | ሞያ ወይ ደረጃ ትምህርቲ" value={formData.profession} onChange={handleInputChange} />
                    <label htmlFor="profession">Profession | ሞያ ወይ ደረጃ ትምህርቲ</label>
                </div>
                <div className="form-floating mb-3">
                    <input className="form-control" id="affiliation" name="affiliation" type="text" placeholder="Affiliation | ዘለኹሞ ውደባ ወይ ምንቅስቓስ" value={formData.affiliation} onChange={handleInputChange} />
                    <label htmlFor="affiliation">Affiliation | ዘለኹሞ ውደባ ወይ ምንቅስቓስ</label>
                </div>
                <div className="d-grid">
                    <button className="btn btn-primary btn-lg" type="submit">Submit</button>
                </div>
                {submitSuccess && <div className="alert alert-success" role="alert">Form submission successful!  <br /> ይቐንየልና! ሓበሬታ ተላኢኹ ኣሎ</div>}
                {submitError && <div className="alert alert-danger" role="alert">Error sending message! Please try again or contact us at join.felsiselam@gmail.com <br /> ይቕሬታ፡ ሓበሬታ ብግቡእ ኣይተላእኸን: ብኽብረትኩም ደጊምኩም ፈትኑ ወይ ብ ኢሜይልና join.felsiselam@gmail.com ርኸቡና።</div>}
            </form>
        </div>
    );
}

export default JoinForm;
