// src/view/protected/GuestList.jsx
import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Pagination, Row, Col, Modal, Form } from "react-bootstrap";
import { listUsersInGroup, registerGuest, sendRegistrationNotification } from './../../api/api';
import { IoMdRefresh } from 'react-icons/io';

const GuestList = ({ setGuestCount }) => {
  const [guests, setGuests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [notify, setNotify] = useState(false);

  useEffect(() => {
    loadGuests();
  }, []);

  const loadGuests = async () => {
    setLoading(true);
    try {
      const guestUsers = await listUsersInGroup("guests");
      setGuests(guestUsers);
      setGuestCount(guestUsers.length); // Update guest count
    } catch (error) {
      console.error('Error fetching guests:', error);
    }
    setLoading(false);
  };

  const handleRegisterGuest = async (guest) => {
    setSelectedGuest(guest);
    setShowConfirmModal(true);
  };

  const confirmRegisterGuest = async () => {
    try {
      await registerGuest(selectedGuest);

      if (notify) {
        await sendRegistrationNotification(selectedGuest.email, selectedGuest.firstName, selectedGuest.lastName);
      }

      alert('Guest registered successfully.');
      loadGuests();
      setShowConfirmModal(false);
    } catch (error) {
      console.error('Error registering guest:', error);
      alert('Failed to register guest.');
    }
  };

  const handleNotifyChange = (e) => {
    setNotify(e.target.checked);
  };

  const guestsPerPage = 20;
  const indexOfLastGuest = currentPage * guestsPerPage;
  const indexOfFirstGuest = indexOfLastGuest - guestsPerPage;
  const currentGuests = guests.slice(indexOfFirstGuest, indexOfLastGuest);

  const totalPages = Math.ceil(guests.length / guestsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRefresh = () => {
    loadGuests();
  };

  return (
    <Card className="mt-4">
      <Card.Header>
        <Row>
          <Col>Guests <IoMdRefresh onClick={handleRefresh} style={{ cursor: 'pointer' }} />  </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <div className="d-none d-md-block">
              <div className="table-responsive">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentGuests.map((guest, index) => (
                      <tr key={guest.id}>
                        <td>{(currentPage - 1) * guestsPerPage + index + 1}</td>
                        <td>{guest.firstName}</td>
                        <td>{guest.lastName}</td>
                        <td>{guest.email}</td>
                        <td>{guest.phoneNumber}</td>
                        <td>
                          <Button variant="primary" size="sm" onClick={() => handleRegisterGuest(guest)}>
                            Register Guest
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="d-block d-md-none">
              {currentGuests.map((guest, index) => (
                <Card key={guest.id} className="mb-3">
                  <Card.Header>
                    <div>{(currentPage - 1) * guestsPerPage + index + 1}. {guest.firstName} {guest.lastName}</div>
                  </Card.Header>
                  <Card.Body>
                    <p>Email: {guest.email}</p>
                    <p>Phone: {guest.phoneNumber}</p>
                    <Button variant="primary" size="sm" onClick={() => handleRegisterGuest(guest)}>
                      Register Guest
                    </Button>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        )}
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </Card.Body>
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to register {selectedGuest?.firstName} {selectedGuest?.lastName}? This guest will have all member's access in this site.
          <Form.Group controlId="formNotify" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Notify Member"
              checked={notify}
              onChange={handleNotifyChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmRegisterGuest}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default GuestList;
