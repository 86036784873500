import React from 'react';

function May_20_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT3" aria-expanded="false" aria-controls="collapsePRT3" style={{ textDecoration: 'none' }} >
                        <h3>ናብ ውድብ ናጽነት ትግራይ (ውናት) ከምኡ’ውን ናብ ሳልሳይ ወያነ ትግራይ (ሳወት)</h3>
                    </a>
                    <div className="collapse" id="collapsePRT3">
                        <article>
                            <div>
                                <p>ብምኽንያት ወግዓዊ ምስረታ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ካብ ውደብ ናጽነት (ውናት) ትግራይ ከምኡ’ውን ሳልሳይ ወያነ ትግራይ (ሳወት) ዝተዋህበ ናይ ሓጎስ መግለጽን: ሓቢርካ ናይ ምስራሕ ጻውዒትን: ፈልሲ ሰላምን ደሞክራስን ብሓጎስ ከም ዝተቐበሎን ንተግብራዉነቱ ክዓዪ ምኻኑ ክገልጽ ይፈቱ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ ህላወ: ብቐንዱ ህዝቢን ሃገርን ንምድሓን ነቲ ኣብ ኤርትራ ዘሎ ውልቀ መላኺ ስርዓት ኢሰይያስ ንምእላይን: ኣብ ኤርትራ ኩሉ ሓቆፍ ግዝያዊ መንግስቲ ንምትካል ዝቃለስ ህዝባዊ ሓይሊ ኮይኑ: ንድሕንነትን ረብሓን ህዝቢታት ኤርትራን ትግራይን ንምርግጋጽ: ምስ መንግስቲ: ፖለቲካውያን ሓይሊታት: ሲቪካዊ ማሕበራትን ህዝቢ ትግራይ ብሓፈሽ: ኣብ ኩሉ መዳይ ተሓባቢሩ ንኽሰርሕ ብመትከል ዝቃለስ ዘሎ ህዝባዊ ሓይሊ እዩ።</p>
                                <p>በዚ መሰረት ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ነቲ ስርዓት ኢሰይያስ ኣብ ልዕሊ ህዝቢ ኤርትራ ዝፈጽሞ ጽንተት: ኣብ ልዕሊ ህዝቢ ትግራይ ዝቕጽሎን ዘውረዶን መጉዳእቲ ንምሕዋይ ዝከኣሎ ክገብር እዩ። ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ህዝቢ ትግራይ ካብ ከበባን ዕጽዋን ንኽወጽእ ጥራይ ዘይኮነ: ኣብ ቀጻሊ ክልቲኡ ህዝቢታትና ኣብ ጎደና ሰላምን ምዕባለን ንኽምርሽ ዓሊሙ ዝቃለስ ሓይሊ እዩ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራሲን ኤርትራ ነቲ ብውድባትኩም ዝተዋህበ መግለጺ እንዳ ነኣደ: ናብ ተግባራዊ ስርሓት ከምርሓና ዝኽእል ርክባትን መደባትን ክሰጋገር በዚ ኣጋጣሚ ከዘኻኽር ይደሊ።</p>
                                <p>ሰላም ንህዝቢ ትግራይን ኤርትራን <br /> ፈልሲ ሰላምን ደሞክራስን ኤርትራ-ክፍሊ ድፕሎማሲ</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_3_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on May 20, 2022
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRE3" aria-expanded="false" aria-controls="collapsePRE3" style={{ textDecoration: 'none' }} >
                        <h3>Letter to Charge d’Affaires of US in Eritrea HE Steven C. Walker</h3>
                    </a>
                    <div className="collapse" id="collapsePRE3">
                        <article>
                            <div>
                                <p>We, members of the Seedling for Peace and Democracy in Eritrea, would like to pass
                                    on our heartfelt gratitude for your continued support of the Eritrean people by
                                    being the voice of the millions of voiceless Eritreans who have been silenced by
                                    a regime that doesn’t have any obedience to the rule of law and international
                                    declarations of human right values. As you know, 100 thousands of Eritreans
                                    including former high ranking officials and bright independent journalists have
                                    been languishing in the prison cells for over 21 years not to mention that the
                                    whole country is a giant prison! There are above 300 known and unknown prison
                                    compounds throughout the country including large residential buildings.</p>
                                <p>Our organization is established recently to bring all Eritrean organizations to
                                    work with people of the neighboring countries for democratic transition in Eritrea
                                    and peaceful coexistence in the region. As we are trying to keep up the pressure
                                    on the rogue regime we count on the support of the United States of America
                                    (an adopting country to many of us in this group) and other democratic countries
                                    in the world. We condemn the support the regime in Asmara has been giving to the
                                    Russian Government’s aggression against the people of Ukraine and surrounding countries.
                                    This is a dangerous move against the will of the Eritrean people. A peaceful
                                    Eritrea means a gate to sustainable development in the Horn of Africa and the Red Sea.
                                    We believe the last 25 years were missed golden opportunity for alleviation of poverty
                                    in the region. We are working tirelessly to assure the Eritrean people, the people of
                                    the neighboring countries, and US that the next several years they will work with a
                                    constitutional and democratic Eritrean Government.</p>
                                <p>Thank you for your attention and we look forward to working with you and your office in Asmara.</p>
                                <p>Very Respectfully, <br /> Diplomatic Department, Seedling for Peace and Democracy in Eritrea</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_3_eng.pdf">PDF English</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on May 20, 2022
                    </p>
                </div>
            </div>
        </div>
    );
}

export default May_20_2022;
