import React from 'react';

function Sep_30_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT9" aria-expanded="false" aria-controls="collapsePRT9" style={{ textDecoration: 'none' }}>
                        <h3>ናይ ሓዘን መግለጺ</h3>
                    </a>
                    <div className="collapse" id="collapsePRT9">
                        <article>
                            <div>
                                <p>ዝሓለፈ ዕለተ መስቀል፣ ኣብ ልዕሊ ከተማ ዓዲ ዳዕሮ ብዝተፈጸመ ከቢድ ናይ ነፈርቲ ደብዳብ፣ ህጻናትን ዓበይቲ ሰባትን ሓዊሱ፣ ብዙሓት ሰባት ተቐዚፉን፣ ንብረት ዓንዩን ኣሎ። ብመሰረት ተለቪዥን (Tigrai TV) ትግራይ ዝሓበቶ ሓበሬታ እዚ ናይ ነፈርቲ ደብዳብዚ መንግስቲ ኤርትራ ብዝለኣኸን ናይ ጥፍኣት ነፈርቲ ኢዩ ተፈጺሙ። ንጽባሒቱውን ኣብ ከተማ ዓዲ ግራት ናይ ከበድቲ ኣጽዋር ደብዳብ ብምፍጻም ዩኒቨርሲቲ ዓዲ ግራ ዝርከቦ ዓበይቲ ዕንወታትን ህልቂት ህዝብን ፈጺሙ ኣሎ። በዚ መንገዲዚ ነቲ ኣብ ልዕሊ ህዝቢ ትግራይ ዘርኢ ናይ ምጽናት ወፈርኡ ናብ ዝኸፍአን ዝለዓለን ጥርዚ ኣሰጋጊርዎ ይርከብ።</p>
                                <p>ንሕና ኣባላት ፈልሲ ሰላምን ዲሞክራስን ኤርትራ ዞባ ኤውሮጳ በዚ ናይ መንግስቲ ኤርትራ ጸረ ህዝቢ ወፈራታት ኣጥቢቑ ይኹንን። ምስ ህዝቢ ትግራይ ተመሓዝዩ ዕምሪ ጸላኢና ንምሕጻር ኩሉ ዝከኣሎ ዘበለ ክገብር ከም ዝኾነ ድማ የረጋግጽ። ንህዝቢ ትግራይ ድማ ኣብዚ ናይ ፈተና ግዚኡ ኣብ ጎኑ ከም ዝኾናን፣ ሓዘኑ ሓዘንና ምዃኑ ክንገልጸሉ ንደሊ። ልዑል ፈጣሪ ጽንዓት ክህቦ ድማ ጸሎትና ነዕርግ።</p>
                                <p>ናይ ዓዲ ዳዕሮን ዓዲ ግራትን ኣረሜናዊ ጨፍጫፍ መቐጸልታ ናይቲ ልፍንቲ መንግስቲ ኤርትራን መንግስቲ ኢትዮጲያን፣ ህዝቢ ትግራይ ናይ ምጽናት ሰይጣናዊ መደብን፣ ጥሙር ናይ ጦር ሰራዊት ኢትዮጲያን ወተሃደራት ኤርትራን ትግራይ ናይ ምዕናው ወፍርን ኢዩ። ኣብዚ ተደጋጋሚ ወራራትዚ፣ ብኣማናኢት ኣሽሓት ዝቑጸሩ ሰባት ብግፍዒ ተቐቲሎምን፣ ብዓሰርተታት ኣሽሓት ዝቑጸራ ንጹሃት ደቂ ትግራይ ተዓሚጸንን፣ ብሚሊዮናት ዝቑጸር ተጋሩ ካብ ገዛእ ሃገሮም ተፈናቒሎምን ኣለዉ። ብሓጺሩትግራይ ከም ትግራይ ክትዓኑ ኢዩ ተፈቲኑ። መንግስትን ሰራዊትን ኤርትራ ድማ ኣብዚ ኩሉ ገበናትዚ ብዝፈጸንዎ ተግባራት ተሓተቲ ኢዮም። እቶም ገበነኛታት ድማ ኩሉ ዓይነት ስጉምቲ ተወሲዱ ኣብ ፍርዲ ክቐርቡን ግቡእ መቕጻዕቶም ክቕበሉን ኣለዎም። ፈልሲ ሰላምን ዲሞክራስን ኤርትራ ዞባ ኤውሮጳ፣ ገበነኛታት ንምሓዝን ኣብ ፍርዲ ቀሪቦም መቕጻዕቶም ንኽወስዱን ዝግበር ኩሉ ዓይነት ስጉምታት ይድግፍን፣ ናቱ ተራ ይጻወት ኣሎን።</p>
                                <p>እዚ ድሕሪ ምምጻእ ቀዳማይ ሚኒስተር ኣቢይ ኣሕመድ ኣብ ኢትዮጲያ ዝጀመረ ዘይቅዱስ ልፍንቲ ምስ ፕረሲደንት ኤርትራ ኢሰያስ ኣፈወርቂ፣ ነቲ ደላይ ፍትሕን ሰላምን ነጻነትን ዝኾነን ፍንጫሕ መሰንገለናን ህዝቢ ትግራይ ንምጽናት ካብ ዝነኣሰ ክሳብ ዝዓበየ ዘመናዊ ናይ ጥፍኣት ኣጽዋራት ተሓንጊጦምን፣ ንኣሽቱን ዓበይትን ዘራያት ሃገራት ኣኽቲሎምን፣ ንትግራይ ብ360 ዲግሪ ከቢቦም ከጽንትዎ ተደጋጋሚ ወራራትን ጭፍጨፋን ኣካይዶምን የካይዱ ኣለዉን።</p>
                                <p>ህዝቢ ትግራይ ግን ከምቲ ኣብ ሙሉእ ታሪኹ ደጋጊሙ ዘመስከሮ «እምቢ ኣይውረርን!፣ መሰለይ ኣሕሊፈ ኣይህብን!» ኢሉ ቆፍኡ ከም ዝተተንከፈ ንህቢ ብሓደ ዕላማን ልብን ሰሚሩ ብምቅላስ ነቲ ናይ ጥፍኣት ድግስ መሰስ ኢሉ ወጺእዎስ፣ ሕጂ ገበርቲ እከይ ግቡእ ፍርዶም ክረኽቡሉ ዘኽእል ደረት ትርኢት ክርአ ጀሚሩ ኣሎ። ኣብዚ ድሕሪ ናይ ገለ ኣዋርሕ ምቅርራብ ዝከፈትዎ ሓድሽ ወራር፣ ድማ ጦር ሰራዊት ኢትዮጲያን፣ ኣራዊት ሰራዊት ኤርትራ ኣብ ኩሉ ናይ ኲናት ግንባራት ከቢድ ስዕረት ኣጋጢምዎስ ብዓሰርተት ኣሽሓት ደም ከልቢ ኮይኖም ይሃልቁን፣ ይስንክሉን፣ ይማረኹን ኢዶም ይህቡን ኣለዉ። «ንትግራይ ዝኣቱ እምበር ካብ ትግራይ ዝወጽእ ወራሪ ኣይህሉን!» ዝብል ትንቢታዊ ቃል ፕረሲደንት መንግስቲ ትግራይ ዶክተር ደብርጺዮን ተግባራዊ ክውን ኣብ ዓይንና ንርእዮ ኣሎና። እዚ ህዝቢዝስ እንቋዕ ወገንና ኮነ!</p>
                                <p>ንዓና ንደለይቲ ፍትሒ ኤርትራውያን ብሓፈሻ፣ ንኣባላት ፈልሲ ሰላምን ዲሞክራስን ኤርትራ ድማ ብፍላይ፣ ህዝቢ ትግራይ ዓቢ ኣብነት ኮይኑ ኣሎ። ነታ ዳርጋ ክትጠፍእ ጨለምለም ትብል ዝነበረት ተስፋና መሊሳ በርቲዓ ክትበርህን፣ ሓሪንና ክንቃለስ ክንበራታዕን ገይሩና ኣሎ። ሕጂውን እዚ ዝካየድ ዘሎ ኲናት ብዓወት ህዝቢ ትግራይ ሰራዊት ምኽልኻል ትግራይን ክዛዘም ከም ዝኾነ ምንም ኣይንጠራጠርን ኢና።</p>
                                <p>ኢሰያስ ኣፈወርቂ ክሳብ ዝሃለወ ህዝቢ ኤርትራ ንቡር ህይወቱ ክመልስ ይኹን ፣ ሃገርናን ዞባና ሰላምን ምርግጋእን ክረክብ ስለዘይክእል መንግስቲ ኤርትራ ካብ ስልጣን ክእለ ኣለዎ። ሰልፊ ሰላምን ዲሞክራስን ኤርትራ ምስ ህዝቢ ትግራይን ሰራዊቱን ተመሓዝዩ ነዚ ስርዓትዚ ንምእላይ ክሳብ መወዳእታ ክቃለስ ምዃኑ ሕጂውን ከነረጋግጽ ንደሊ።</p>
                                <p>እቶም ንነዊሕ ዓመታት ኣብ ደንበ ተቓውሞ ኤርትራ ኮይኖም፣ ደለይቲ ፍትሒ ክመስሉ ዝጸንሑ እሞ ፣ ድሕሪ ወራር ትግራይ «ምስ ሰራዊትና ደው ንብል!» ዝብሉ ዘለዉ ኣካላት ድማ ብተግባር ተሓባበርቲ ናይ መንግስቲ ኤርትራ ኮይኖም ከም ዘለዉ ክፈልጡን፣ ጽባሕ ኣብ እዋን «ተሓሳሰብ!» ምስ ተበጽሐ ግቡእ ሚዛኖም ክረኽቡ ከም ዝኾኑን ክንሕብሮምን ንደሊ። ብስቓይን ሞትን ህዝቢ ትግራይ ምልጋጽ፣ ውጺኢቱ እዚ ዝሓለፈ መዓልታት ኣብ ዓዲ ዳዕሮን ዓዲ ግራትን ዝረኣናዮ ኣረመናዊ ጨፍጫፍ ክሳብ ሕጂ ዝቕጽል ዘሎ ኲናትን ኢዩ።</p>
                                <p>ደጊምና ሓዘና ንገልጽ!</p>
                                <p>ህዝቢ ትግራይ ብርግጽ ክዕወት ኢዩ!! <br />
                                ፈልሲ ሰላምን ዲሞክራስን ኤርትራ ዞባ ኤውሮጳ<br />
                                ዕለት 30.09.2022</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_9_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Sep 30, 2022
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                
                {/* <div className="post-preview">
                   <a data-bs-toggle="collapse" href="#collapsePRE9" aria-expanded="false" aria-controls="collapsePRE9">
                       <h3>Statement of Seedling for Peace and Democracy in Eritrea Regarding the Second Invasion of Tigray</h3>
                   </a>
                   <div className="collapse" id="collapsePRE9">
                       <article>
                           <div>
                               <p></p>
                           </div>
                       </article>
                       <p><a href="assets/pdf/press_release_9_eng.pdf">PDF English</a>.</p>
                   </div>
                   <p className="post-meta">
                       Posted on Sep 30, 2022
                   </p>
                </div>  */}
            </div>
        </div>
    );
}

export default Sep_30_2022;