import React from 'react';

function May_24_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT4" aria-expanded="false" aria-controls="collapsePRT4" style={{ textDecoration: 'none' }}>
                        <h3>ህዝብና ናይ ብሓቂ ናጽነት ከስተማቕርን ዞባና ክረጋግእን ስርዓት ኢሰይያስ ክእለ ኣለዎ</h3>
                    </a>
                    <div className="collapse" id="collapsePRT4">
                        <article>
                            <div>
                                <p>ህዝቢ ኤርትራ ምእንቲ ናጽነት ክብል ኣብ ኣፍሪቃ ዝነዉሐን ዝመረረን ዝተባህለሉ ናይ 30 ዓመት ብረታዊን ፖለቲካዊን ቃልሲ ኣካይዱ እዩ። ብዓሰርተታት ኣሻሓት ዝኾኑ ደቁ ንመስዋእቲ ከፊሉ: ንብረቱን ሃብቱን ከሲሩ: ኣብ መወዳእታ ከኣ: ብዓብላሊ ድምጺ ረፈረንደም ኣብ ዓለም ከም ህዝቢን ሃገርን ንኽንፍለጥ በቒዑ እዩ። ይኹን’ምበር ስርዓት ኢሰይያስ ናይ ህዝብና ሕልሚን ራኢን ጨውዩ: ምስ ኩሎም ጎረባብትና ብፍላይ ከኣ ምስ ህዝቢ ትግራይ ንኻልኣይ ግዚኡ ከቢድ ኩናት ኣጉዱ ህዝብናን ህዝቢ ትግራይን ኣደዳ ሞት: ስደትን መከራን ጽንተትን ገይርዎ ይርከብ። እዚ ከይኣክል: ውልቀ መላኺ ኢሰይያስ: ምስ መንግስቲ ኢትዮጵያን ካለኦቶን ብምሽራኽ ንኤርትራ ሃገርና ገበጣ መጻንሒ ስልጣኑ ይጥቀመላ ኣሎ።</p>
                                <p>ውልቀ መላኺ ስርዓት ኢሰይያስ ኣፈወርቂ: ንኤርትራ ዝምልከት ዞባዊ: ኣህጉራዊን ዓለምለኻዊን ዝገብሮ ዝኾነ ይኹን ውዕላትን ስምምዓትን ብፈልሲ ሰላምን ደሞክራስን ኤርትራ ፈጺሙ ተቐባልነት የብሉን። ምኽናያቱ ኣቲ ጉጅለ: ብህዝብና ቆጽሊ ኣውሊዕ ዝተነብረሉ ስርዓት ኣይኮነን። እቲ ስርዓት ብሓይሊ ኣብ ኮረሻ ስልጣን ካብ ዝቕመጥ ጀሚሩ: ኣብ ኤርትራ ባዕሉ ሓጋጊ: ፈራዲን ፈጻሚን ኣካል ኮይኑ ንህዝብና ዝሓርድ: ዝቐትልን ዘጥፍእን ዘለ ጨቃኒ ስርዓት እዩ።</p>
                                <p>በዚ ዕለት ኣጋጣሚ: ፈልሲ ሰላምን ደሞክራስን ኤርትራ: መራሕቲ መንግስቲ ኢትዮጵያ ንህዝቢን ሃገርን ኤርትራ ዝግህስ ብዛዕባ ባሕሪን ወደብ ከተማ ዓሰብን ዝምልከት ዘውጽእዎ ዘለዉ መግለጺታትን ዝወስድዎ ስጉምቲታትን ብጥብቂ ይኹንን።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ህዝብና ናይ ብሓቂ ናጽነት: ሰላምን ምዕባለን ከስተማቕር ዞባና ክረጋግእ ስርዓት ኢሰይያስ ንምእላይ ቃልሱ ከሐይል ምኻኑ ንህዝቢ ኤርትራን ፈተዉትን ከረጋግጽ ይፈቱ። ኩሉ ህዝቢ ኤርትራ ነዚ ተፈጢሩ ዘሎ ጥምረት ክዓስሎ: ጎረባብቲ ሃገራትን ፈተዉትን ከኣ ክተሓጋገዝዎ ጽውዒቱ የቕርብ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_4_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on May 24, 2022
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRE4" aria-expanded="false" aria-controls="collapsePRE4" style={{ textDecoration: 'none' }}>
                        <h3>For Peace and Stability to return to Eritrea and region, Isaias must be removed</h3>
                    </a>
                    <div className="collapse" id="collapsePRE4">
                        <article>
                            <div>
                                <p>The Eritrean people have unique experience in Africa due to the fact that they had to wage the longest war of independence. After enduring a 30 years-long war of attrition that costed them tens of thousands of lives, countless property and environmental destructions, they were able to achieve their independence first by defeating the Ethiopian occupation forces and then through a UN observed referendum.</p>
                                <p>In a stunning reversal of his promises, thereby, dashing the hopes of millions of Eritreans for a free, prosperous and democratic Eritrea, the man who led the armed struggle for independence and later became privileged enough to become its first President - Isaias Afwerki - became a ruthless dictator. Ever since, he has fought with each and all neighboring countries once and twice with Tigray. This has resulted in staggering number of deaths, distraction, displacement and immigration both to the people of Eritrea and the region. By partnering with bad actors and similar dictatorial regimes like the current Ethiopian government, he has continued to make pacts on behalf of Eritrea to continue his genocidal shenanigans in Tigray. Cognizant of the fact that the current regime in Asmara has never been elected and mandated by the Eritrean people to make any dealings on their behalf, Seedlings of Peace and Democracy Eritrea (SPDE) rejects any pacts and agreements to this effect as null and void. It abhorrently condemns statements coming from sources close to the Ethiopian Government regarding its claim of the Red Sea coast and the Port of Assab which are all within the sovereign territories of Eritrea.</p>
                                <p>SPDE reiterates that the ultimate solution for Eritrea and the region to return to peace and democracy, Isaias Afwerki should be removed from the power at any cost. With a view to removing the regime, therefore, SPDE earnestly expresses its commitment to intensify the fight. It calls upon all Eritreans to join the movement, neighboring countries and international community support it, so this goal can be achieved faster.</p>
                                <p>The Seedling for Peace and Democracy</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_4_eng.pdf">PDF English</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on May 24, 2022
                    </p>
                </div>
            </div>
        </div>
    );
}

export default May_24_2022;
