import React from 'react';

function Sep_01_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT7" aria-expanded="false" aria-controls="collapsePRT7" style={{ textDecoration: 'none' }}>
                        <h3>ካብ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ዝወጸ ጋዜጣዊ መግለጺ</h3>
                    </a>
                    <div className="collapse" id="collapsePRT7">
                        <article>
                            <div>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ ነቲ ዉልቀ መላኺ ኢሰይያስ ኣፈወርቂ ኣብ ልዕሊ ህዝቢ ኤርትራ ንዝሓለፈ 30 ዓመታት ብዕላማ ክፍጽሞ ዝጸንሐ ጽንተት ብምቕጻል ምስ ኣቢይ ኣሕመድ ተላፊኑ ንህዝቢ ትግራይ ኣብ ክባን ዕጽዋን ኣእትዩ ብጥምየት: ደብዳብ ሰብኣልቦ ነፈርትን ኩናትን ብሓፈሻ ዝፍጽሞ ዘሎ ዘርኢ ምጥፋእ ብትሪ ይኹንን።</p>
                                <p>ውልቀመላኺ ስርዓት ኢሰይያስ ኣብ ህዝቢ ኤርትራ ዘውርዶ ዘሎ መግለጺ ዘይብሉ ጭቆና: ኣብ ህዝቢ ትግራይ ዝፈጸሞ ወራርን ዘስካሕክሕ ኢሰብኣዊ ተግባራትን ንኽቕጽሎ ኤርትራ ዓባይ ወተሃደራዊ መዓስከር ኢትዮጵያ ቀይርዋ ከምዘሎ ናይ ኣደባባይ ምስጢር እዩ። ስርዓት ኢሰይያስ ኣብ ኤርትራ ከሰልጥኖም ዝጸንሐ ኢትዮጵያዉያን ከይኣኽሎ: ሎሚ ቅነ’ውን ኣብ ምዕራብ ትግራይ ዓሪዶም ዝነበሩ ብዓሰርተታት ኣሽሓት ዝቑጸሩ ሰራዊት ኢትዮጵያ ናብ ኤርትራ ኣስጊሩ: ንህዝቢ ትግራይ ዳግም ንምውራር ምድላዋቱ ወዲኡ ኣሎ። ፈልሲ ሰላምን ደሞክራስን ኤርትራ ውልቀመላኺ ሰርዓት ኢሰይያስ ኣፈወርቂን ኣቢይ ኣሕመድን ካብ ኤርትራ ናብ ህዝቢ ትግራይ ኣንፈት ዝግበር ወራርን ተጻብኦታትን ኣጥቢቑ ይኹንን። ኣብ ኤርትራን ትግራይን ንዝወርድ ሰባዊ ህልቂትን ንዋታዊ ዕንወትን ውልቀመላኺ ስርዓት ኢሰይያስን መላፍንቱ ስርዓት ኣቢይን ተሓተቲ ምኻኖም የተሓሳስብ።</p>
                                <p>ህዝቢ ትግራይ: ነቲ ብ 24 ነሓሰ 2022 ብስርዓት ኣቢይ ኣሕመድ ዳግም ኣብ ልዕሊኡ ዝኸፈቶ ሰፊሕ ኩናት ኣፍሺሉ ኣብ ጎደና ዘይተርፍ ዓወት ይምርሽ ብምህላዉ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ይሙጉስ። ብዓወት ክትፍጽሞ ከኣ ዘለዎ ተስፋ ይገልጽ።</p>
                                <p>ህዝቢ ትግራይ ምእንቲ ህላዉነቱን ብሄራዊ ክብሩን ክብል: ነዞም ክልተ ጸላእቱ ብሓይሉን ጅግንነቱን ስዒሩ ካብ’ዚ ዘለዎ ክባን ዕጽዋን: ኩናትን ጽንተትን መሊኡ ክወጽእን: መሰል ርእሰ ዉሳነ ትግራይ ከረጋግጽን ኣብ ዘካይዶ ዘሎ ህዝባዊ ቃልሲ: ፈልሲ ሰላምን ደሞክራስን ኤርትራ ወትሩ ኣብ ጎኒ ህዝቢ ትግራይ ከምዘሎ የረጋግጽ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ኣብ’ዚ ተኣፋፊን ህጹጽን እዋን: ኣብ ኤርትራ ንዘሎ ህዝቢን ሰራዊትን ኤርትራ: ኩሉ ዘለዎ ዕጥቃዊ ኣጽዋር: ዓቕሚን ክእለትን ኣንጻር እቲ ዘጽንቶ ዘሎ ስርዓት ከውዕሎ ጻውዒት የቕርብ። ኣብ ወጻኢ ንዘለዉ ተቓወምቲ ፖለቲካዊ ሓይሊታት ብፍላይ ከኣ መንእሰያት: ነቲ ኣብ ኣዉሮጳን ኣሜሪካን ተጀሚሩ ዘሎ ምልዕዓላት ብሓይሊ ክቕጽሎን ነቲ ንህዝቢ ኤርትራን ትግራይን ዘጽንት ዘሎ: ንህዝቢታት ቀርኒ ኣፍሪቃ ከኣ ዝህውኽን ዝሕምስን ዘሎ ውልቀ መላኺ ኢሰይያስን ጉጅልኡን ንምእላይ ሓድነቱን ቃልሱን ከሐይል ይላቦ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ህዝቢን ሃገርን ንምድሓን ኣብ ዝግበር ቃልሲ ምስ ኩሎም ኣካላት ህዝቢ ኤርትራን ከምኡ’ውን ጎረባብቲ ህዝቢታት ትግራይ: ኢትዮጵያ: ሱዳንን ካለኦትን ክተሓጋገዝ ድሉዉ ምኻኑ የረጋግጽ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_7_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Sep 01, 2022
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                {/* The second column's content is commented out in your HTML, represented here as empty for potential future use. */}
                <div className="post-preview">
                    {/* Future content */}
                </div>
            </div>
        </div>
    );
}

export default Sep_01_2022;
