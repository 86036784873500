import React from 'react';

function May_17_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT2" aria-expanded="false" aria-controls="collapsePRT2" style={{ textDecoration: 'none' }} >
                        <h3>ናይ ሓጎስ መልእኽቲ ንህዝቢን ፕረሲደንትን ሶማልያ ኮይኑ ዝተመረጸ ክቡር ሓሰን ሸኽ መሓመድ</h3>
                    </a>
                    <div className="collapse" id="collapsePRT2">
                        <article>
                            <div>
                                <p>ፈልሲ ስለማን ደሞክራስን ኤርትራ: ንሙሉእ ህዝቢ ሶማልያ ከምኡ’ውን ነቲ ዳግም ፕረሲደንት ፈደራላዊት ረፓብሊክ ሶማልያ ኮይኑ ዝተመርጸ ክቡር ሓሰን ሸኽ መሓመድ ልባዊ ሓጎሱ ከም ዝተሰምዖ ክገልጽ ይፈቱ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ህዝቢ ሶማልያ: ኣብ 15 ግንቦት 2022 ብግሉጽ ናይ ብቴሌቪዥን ኣገባብ ስልጣን ብምስግጋሩ: ታሪኽን ምስልን ሶማልያ ከም ዝተመሓየሸ ይኣምን። ሶማልያውያን: ነቲ ንዓሰርተታት ዓመታት ኣብ ህውከት ዘሎ ዞባ ጽቡቕ ኣብነት ኮይኖም ኣለዉ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ኣብ ሕሉፍ ኣሰር ፕረሲደንት ክቡር ሓሰን ሸኽ መሓመድ ሙርኩስ ብምግባር: ኣብ’ቲ ተኣፋፊ ዞባ: ሰላም: ደሞክራስን ምዕባለን ንኽሰፍን ኣወንታዊ ጸላዊ ሓይሊ ክኸውን ከምዝኽእል ዓቢ እምነት ኣለዎ። እዚ ከኣ: ንሶማልያ ጥራይ ዘይኮነ: ንሙሉእ ቀርኒ ኣፍሪቃ ዓቢ ዓወት እዩ። ፈልሲ ሰላምን ደሞክራስን ኣብ ኤርትራ: እቲ ምርጫ: ኣብ ብሙሉእ እቲ ዞባ ሓዱሽ ናይ ስርዓት ሃዋሁዉ ከም ዘንብር ብልቢ ይኣምን።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ምስ ሓዱሽ ምምሕዳር ክቡር ሓሰን ሸኽ መሓመድ ኣብ ናይ ሓባር ረብሓ ዝተሞርኮሰ ሃናጺ ናይ ምትሕብባር ጽምዶ ክገብር ትጽቢት ይገብር።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን: ንኻለኦት ሓይሊታት ብምትሕብባር ነቲ ኣብ ቀርኒ ኣፍሪቃን ክንይኡን ናይ ዘይምርግጋእ ቀንዲ ተዋሳኣይ ዝኾነ ጨካን ውልቀ መላኺ ኢሰይያስ ኣፈወርቂ ካብ ስልጣን ንምውራድ ዝቃለስ ዘሎ ምንቅስቓስ እዩ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ኣብ ኤርትራ ሰላምን ደሞክራስን: ኣብ ቀርኒ ኣፍሪቃ ከኣ ምርግጋእን ምዕባለን ንኽመጽእ ዓሊሙ ዝቃለስ ዘሎ ፖለቲካዊ ሓይሊ እዩ።</p>
                                <p> ዮሴፍ ገ/ሂወት <br /> ላዕለዋይ ሓላፊ ፈጻሚ ኣካል <br /> ፈልሲ ሰላምን ደሞክራስን ኤርትራ <br /> </p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_2_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on May 17, 2022
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRE2" aria-expanded="false" aria-controls="collapsePRE2" style={{ textDecoration: 'none' }} >
                        <h3>Congratulations to the People of Somalia and President-Elect of the Federal Republic of Somalia, H.E. Hassan Sheikh Mohammed </h3>
                    </a>
                    <div className="collapse" id="collapsePRE2">
                        <article>
                            <div>
                                <p>Seedling for Peace and Democracy in Eritrea would like to express its heartfelt congratulations
                                    to the people of Somalia in general, and to H.E. Hassan Sheikh Mohammed, the President-Elect
                                    of the Federal Republic of Somalia for his re-election to the Office of the Presidency of Somalia.
                                    Seedling believes, by effecting peaceful transition of power in a televised and transparent
                                    manner, on May 15, 2022 the people of Somalia have changed history and the image of Somalia
                                    for the better. You have become a good example for the region that has been engulfed in turmoil
                                    for decades.</p>
                                <p>Seedling for Peace and Democracy in Eritrea strongly believes the President-Elect, H.E. Hassan
                                    Sheikh Mohammed, true to his track record, will be a positive influence and force for peace,
                                    stability, democracy and prosperity to this volatile region. Thus, we do not consider this as only a
                                    win for Somalia but also for the entire Horn of Africa. We sincerely believe that this election has
                                    set the tone for a new code of conduct in the entire region.</p>
                                <p>Seedling for Peace and Democracy in Eritrea also envisions to engaging in constructive
                                    cooperative work, on matters of mutual interest, with the new administration of H.E. Hassan
                                    Sheikh Mohammed.</p>
                                <p>Seedling for Peace and Democracy in Eritrea is a movement of Eritreans who struggle for the
                                    removal of Dictator Isaias Afewerki from power by engaging other actors who have been
                                    negatively affected by his ruthless nature, a man who has been the main actor of instability in the
                                    Horn of Africa and beyond. Our organization aims at bringing about peace and democracy to the
                                    people of Eritrea and a stable and prosperous Horn of Africa region.</p>
                                <p>Yosief Ghebrehiwet <br />
                                    Head of the Executive Committee <br />
                                    Seedling for Peace and Democracy in Eritrea </p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_2_eng.pdf">PDF English</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on May 17, 2022
                    </p>
                </div>
            </div>
        </div>
    );
}

export default May_17_2022;
