import { useState } from 'react';

export default function June_17_2022() {
  const [collapsePRT5, setCollapsePRT5] = useState(false);
  const [collapsePRE5, setCollapsePRE5] = useState(false);

  const toggleCollapse = (id) => {
    if (id === "collapsePRT5") {
      setCollapsePRT5(!collapsePRT5);
    } else if (id === "collapsePRE5") {
      setCollapsePRE5(!collapsePRE5);
    }
  };

  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRT5")}
            aria-expanded={collapsePRT5}
            aria-controls="collapsePRT5"
            style={{ cursor: 'pointer' }}
          >
            <h3>ኣገዳስን ህጹጽን መጸዋዕታ ን ኣብ ኩሉ ዓለም ዘለኹም ኤረትራውያን</h3>
          </a>
          <div className={`collapse ${collapsePRT5 ? 'show' : ''}`} id="collapsePRT5">
            <article>
              <div>
                <p>ክቡራት ደለይቲ ፍትሕን ደሞክራስን ኤረትራውያን፡</p>
                <p>1. ብልኡላውነት ኤረትራ ትኣምኑ ‘ዶ?</p>
                <p>2. እቲ እንኮ ጸላኢ ኤረትራውያን ኢሰያስ ኣፈወርቅን ናይ ቀረባ ደገፍቱን ምዃኖም ትኣምኑ ‘ዶ?</p>
                <p>3. ኩላትና ደለይቲ ፍትሕን ደሞክራስን ምስ እንሓብር ብተወሳኺ ምስ ኩለን ጎረባብቲ ሃገራት፡ ብፍላይ ድማ ምስ ትግራይ ተወሃሂድና ምስ እንሰርሕ መሰረታዊ ለውጢ ክመጽእ ከም ዝከኣል ትኣምን ‘ዶ?</p>
                <p>እምበኣርከስ ነዘን ስለስተ ዓበይቲ ሕቶ እወ እንተኾይኑ መልስኹም፡ ሃየ ሓላፍነትኩም ኣበርክቶኹም ክትገብር ብትሕትና ንዕድመኩም።</p>
                <p>ዕላማ ፈልስን ሰላምን ደሞክራስን ኣብ ኤረትራ (SPDE) ካብ ውልቀሰብ ጀሚርካ ንኹሎም በርጌሳውያን ማሕበራትን ፖለቲካዊ ሓይሊታት ኣወሃሂድካ እቲ ዝድለ ለውጢ ንምምጻእን ንህዝቢ ኤረትራ ምድሓንን እዩ።</p>
                <p>ኩነታት ህዝብናን ሃገርና ካብ ዝኸፍአ ናብ ኣዚዩ ኣሻቓሊ ኣንቆልቂሉ ከም ዘሎ ንኹላትና ርዱእ እዩ። ኽሳዕ ሕጂ ክግበር ዝጸንሐ ኣበርክቶ ብኹለን ውድባትን ህዝባዊ ምንቅስቓስን ዝነኣድ እዩ፡ እንተኾነ ግና እዚ ዘለናዮ መድረኽ ኣዚዩ ተኣፋፊ ስለ ዝኾነ፡ ፍሉይ ኣተሓሳስባን ውደባን ስለ ዝጠልብ፡ ስለዚ ኩላትና ብሕብረት ከም ሓደ ጋንታ ጉድለትና ተመላሊእና፡ ጸላኢና ኣለሊና፡ ሽቶና ኣነጺርና፡ ፍልልይና ኣጽቢብና እዛ ወርቃዊት እዋን ክንጥቀመላ ልዕሊ ኹሉ ኣገዳሲ እዩ። ከም እኹላት ሰባት ናይ ሕሉፍ ረሲዕና፡ ብሓድሽ መንፈስ ብሉጽ ኣመለኻኽታ ወኒንና ብሕብረት እንተተቓሊስና፡ ጸልማት ናብ ብርሃን: ውርደት ናብ ክብረት: ስዕረት ናብ ዓወት ከም ዝልወጥ ጥርጥር የብልናን።</p>
                <p>እምበኣርከስ እዚ ቓልሲ ብዝበለጸን ብዝሓየለን ኣድማዒ ለውጢ ንምርካብ ንነፍሲወከፍ ውልቀሰብ: በርጌሳውያን ማሕበራት: ህዝባዊ ምንቅስቓሳት: ውድባትን ግንባራትን ተወሃሂድና ንምስራሕ ሓላፍነትናን ግዴታናን ንምብርካት ብትሕትና ንዕድመኩም። ነፍሲወከፍ ግዱስ ኤረትራዊ ንወርሒ $10.00 ወርሓዊ ክፍሊት ወይ $120.00 ዓመታዊ እንዳኸፈለ ክምዝገብ ነትሓሳስብ። ብተወሳኺ ብሓሳብ ኮነ ብሞያዊ ክእለት ክበርክት ዝደሊ ኩሉ ዜጋ በዚ ዝስዕብ ኢመይል ጽሒፉ ወይ ብቴሌፎን ደዊሉ ክረኽበና ብትሕትና ነተሓሳስብ።</p>
                <p>ሰላም ብሓበራ</p>
                <p> ፈልሲ ሰላምን ደሞክራስን ኣብ ኤረትራ
                  <br />
                  E: felsiselam@gmail.com
                  <br />
                  P: (713) 364-9464
                  <br />
                  ምሳና ሕበሩ! <a href="join.html">https://felsiselam.org/join.html</a>
                </p>
              </div>
            </article>
          </div>
          <p className="post-meta">Posted on June 17, 2022</p>
        </div>
      </div>
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRE5")}
            aria-expanded={collapsePRE5}
            aria-controls="collapsePRE5"
            style={{ cursor: 'pointer' }}
          >
            <h3>A call to all Eritreans</h3>
          </a>
          <div className={`collapse ${collapsePRE5 ? 'show' : ''}`} id="collapsePRE5">
            <article>
              <div>
                <p>Do you firmly believe in the sacrosanctity of Eritrea’s sovereignty?</p>
                <p>Do you agree that the only enemy of all Eritreans and their dreams to democratic and prosperous nation is Isaias Afwerki and supporters of his dictatorial regime?</p>
                <p>Do you find it convincing that, reading the geopolitics of the region and its dynamics, we need to make alliance with all neighboring forces and especially Tigrayan forces to bring about a meaningful change in Eritrea?</p>
                <p>If your answer to all questions above is yes, Seedling of Peace and Democracy Eritrea, with utmost sense of duty, calls upon you to please join us and make your contribution to and leave your indelible marks on the change you wish to bring about in your beloved Eritrea. SPDE firmly believes that more than any time before victory is within reach, if only we seize the moment and work together. The goal of SPDE is to bring all Eritrean civic and political forces of different orientations together to a minimum consensus of removing dictatorship and installing democracy in the country.</p>
                <p>While appreciating all the efforts and good fight each and every civic and political organization has exerted and conducted so far, SPDE would kindly ask you to make note of the current small window of opportunity that has been created out of the dynamism of the last 2 years and conflict in the region to effect change in Eritrea.</p>
                <p>So please join us and become a member by contributing $ 10.00/month or a one payment $ 120.00/year. If you feel that you can contribute in your area of profession or any idea that can move the struggle forward, please reach at the email or phone number listed below.</p>
                <p> Seedling for Peace and Democracy in Eritrea (SPDE)
                  <br />
                  E: felsiselam@gmail.com
                  <br />
                  P: (713) 364-9464
                  <br />
                  Join us! <a href="join.html">https://felsiselam.org/join.html</a>
                </p>
              </div>
            </article>
          </div>
          <p className="post-meta">Posted on June 17, 2022</p>
        </div>
      </div>
    </div>
  );
}
