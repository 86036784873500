// src/components/PaymentsComponent.js
import React from 'react';
import { Card, Table } from "react-bootstrap";

function PaymentsComponent() {
  const payments = [
    { id: "1", date: "****-**-**", amount: "$***", receipt: "#" },
    { id: "2", date: "****-**-**", amount: "$***", receipt: "#" },
  ];

  return (
    <Card className="mt-4">
      <Card.Header>Membership Payment History</Card.Header>
      <Card.Body>
        <p style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "grey" }}>Coming Soon</p>
        <Table bordered>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.date}</td>
                <td>{payment.amount}</td>
                <td>
                  <a href={payment.receipt} target="_blank" rel="noopener noreferrer">
                    View Receipt
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default PaymentsComponent;


// // src/components/PaymentsComponent.js
// import React from 'react';
// import { Card, Table } from "react-bootstrap";

// function PaymentsComponent() {
//   const payments = [
//     { id: "1", date: "2024-01-15", amount: "$100", receipt: "/receipts/receipt1.pdf" },
//     { id: "2", date: "2024-02-15", amount: "$150", receipt: "/receipts/receipt2.pdf" },
//     { id: "3", date: "2024-03-15", amount: "$200", receipt: "/receipts/receipt3.pdf" },
//   ];

//   return (
//     <Card className="mt-4">
//       <Card.Header>Membership Payment History</Card.Header>
//       <Card.Body>
//         <Table bordered>
//           <thead>
//             <tr>
//               <th>Date</th>
//               <th>Amount</th>
//               <th>Receipt</th>
//             </tr>
//           </thead>
//           <tbody>
//             {payments.map((payment) => (
//               <tr key={payment.id}>
//                 <td>{payment.date}</td>
//                 <td>{payment.amount}</td>
//                 <td>
//                   <a href={payment.receipt} target="_blank" rel="noopener noreferrer">
//                     View Receipt
//                   </a>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </Card.Body>
//     </Card>
//   );
// }

// export default PaymentsComponent;