import React from 'react';
import Nav from './Nav';
import MenuNav from './Menu';
import Footer from './Footer';
import { View } from '@aws-amplify/ui-react';
import SymposiumBanner from './banner/BannerTemplate';

function RegimeChangeSymbosium() {

    const customBannerStyle = {
        backgroundImage: "url('../assets/img/symposium_flyer.png')",
        backgroundSize: "350px",
        backgroundRepeat: "repeat-x"
    };
    
    return (
        <main>
            <header>
                <Nav />
                <SymposiumBanner 
                    bannerStyle={customBannerStyle} 
                    heading= "JULY 14, 15 & 16, 2023 10:00AM - 7:00 PM" 
                    subHeading="620 MICHIGAN AVE NE, WASHINGTON, DC 20064" />
            </header>
            <View classNameName="container">
                <main className="mb-4">
                    <div className="container px-4 px-lg-5">
                        <MenuNav />
                        <div className="row text-muted">
                            <div className="col-sm-12 text-center">
                                <h2>Regime-change in Eritrea!</h2>
                            </div>
                        </div>
                        <hr className="my-4" />
                        <div className="row gx-4 gx-lg-5">
                            <div className="col-md-4">
                                <div className="post-preview">
                                    <article>
                                        <div className="py-2">
                                            <img className="img-fluid" alt="Symposium 2023" src="assets/img/symposium_schedule1.png" />
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="col-md-4">

                                <div className="post-preview">
                                    <article>
                                        <div className="py-2">
                                            <img className="img-fluid" alt="Symposium 2023" src="assets/img/symposium_schedule2.png" />
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="post-preview">
                                    <article>
                                        <div className="py-2">
                                            <img className="img-fluid" alt="Symposium 2023" src="assets/img/symposium_schedule3.png" />
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="col-md-6">
                                <div className="post-preview">
                                    <article>
                                        <div className="py-2">
                                            <img className="img-fluid" alt="Symposium 2023" src="assets/img/symposium_flyer_speakers1.png" />
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="post-preview">
                                    <article>
                                        <div className="py-2">
                                            <img className="img-fluid" alt="Symposium 2023" src="assets/img/symposium_flyer_speakers2.png" />
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="col-md-6">
                                <div className="post-preview">
                                    <article>
                                        <div>
                                            <p>Felsi (Seedling for Peace and Democracy in Eritrea) is organizing a symposium in Washington DC on July
                                                14-16, 2023, a three-days long event. [Arguably, Felsi is the largest Eritrean Opposition organization—
                                                now having dozens of branches all over the world—working hard to bring about regime-change in
                                                Asmara.]</p>
                                            <p>The main theme of this symposium is: <strong>why do we need regime change in Eritrea now?</strong></p>
                                            <p>It will involve many writers—academics, journalists, activists, researchers and other experts—either
                                                through their physical presence in Washington DC or through zoom. Hundreds will be invited to
                                                physically attend the event; and hundreds more through zoom.
                                            </p>
                                            <p>We, the Felsi organizers, believe that every subject matter mentioned below points to the need for
                                                regime-change in Eritrea; and, added up with others, will provide a strong argument to that end. The
                                                audience we have in mind is mainly the outside world, especially the West (the US, EU, Western
                                                institutions, think-tanks, humanitarian organizations, NGOs, etc.) and world organizations (UN, AU, ICC,
                                                etc.); and, hence, the need to frame it mainly in English (and other Western languages) and to involve
                                                writers from all over the world: Eritreans, Tigrayans, Ethiopians, Westerners, and others. There could be a
                                                few of them presented in the local language, Tigrigna.
                                            </p>
                                            <p>There are more than 160 topics (or subthemes), each and every one of them a subject matter vast enough to attract
                                                a writer’s attention, but also clustered around <strong> 26 major themes </strong>.</p>
                                        </div>
                                    </article>
                                    <div className="py-5">
                                        <img className="img-fluid" alt="Symposium 2023" src="assets/img/symposium_flyer.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="post-preview">
                                    <article>
                                        <div>
                                            <p><strong> Felsi Menu: </strong>.</p>
                                            <p>Below is the “Felsi Menu” from which we expect the invited writers to choose the subject matter that they
                                                are comfortable with.</p>
                                            <p><strong> A – The nature of the Asmara regime </strong>.</p>
                                            <p><strong> B – Eritrea voting with its feet </strong>.</p>
                                            <p><strong> C – The state of Eritrean refugees everywhere </strong>.</p>
                                            <p><strong> D – Incarceration state </strong>.</p>
                                            <p><strong> E – Genocide in Eritrea </strong>.</p>
                                            <p><strong> F – Indefinite National Service </strong>.</p>
                                            <p><strong> G – The economy of Eritrea </strong>.</p>
                                            <p><strong> H – The anti-institutional nature of the regime </strong>.</p>
                                            <p><strong> I – The educational system in Eritrea </strong>.</p>
                                            <p><strong> J – Victimized population groups </strong>.</p>
                                            <p><strong> K – Religious persecution </strong>.</p>
                                            <p><strong> L – Against all kinds of democratic rights </strong>.</p>
                                            <p><strong> M – Democracy from outside </strong>.</p>
                                            <p><strong> N – President Isaias Afwerki </strong>.</p>
                                            <p><strong> O – Ideology </strong>.</p>
                                            <p><strong> P – Shaebia in mieda </strong>.</p>
                                            <p><strong> Q – Eritreanism </strong>.</p>
                                            <p><strong> R – Martyrdom in Eritrea </strong>.</p>
                                            <p><strong> S – Asmara under Shaebia’s rule </strong>.</p>
                                            <p><strong> T– The colonial Project of Shaebia </strong>.</p>
                                            <p><strong> U – Eritrea and Tigray </strong>.</p>
                                            <p><strong> V – Eritrea’s role in Tigray genocide: </strong>.</p>
                                            <p><strong> W – Eritrea’s confrontational policy: regional and global </strong>.</p>
                                            <p><strong> X – Pan-Africanism and Eritrea (and Ethiopia) </strong>.</p>
                                            <p><strong> Y – Post-regime change </strong>.</p>
                                            <p><strong> Z – Felsi (Seedling for Peace and Democracy in Eritrea) </strong>.</p>
                                            <p>Our region is right now in a total mess, and Eritrea is at the center of it. Our hope is that this event’s
                                                panelists will throw enough light as to why. So, let us make it a success!
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </View>
            <Footer />
        </main>
    );
};

export default RegimeChangeSymbosium;