// src/view/protected/ProfileCard.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { Card, Col, Row, Button, Modal, Form } from "react-bootstrap";
import { FaEdit, FaCamera } from "react-icons/fa";
import { getMemberData, loadProfilePicture, uploadProfilePicture, updateMemberData } from './../../api/api';
import './../../ProfileCard.css';

function ProfileCard({ userAttributes }) {
  const [profilePic, setProfilePic] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    country: '',
    city: '',
    profession: ''
  });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  useEffect(() => {
    if (userAttributes) {
      loadMember();
      loadProfilePicture(userAttributes.email).then(setProfilePic);
    }
  }, [userAttributes]);

  const loadMember = useCallback(async () => {
    try {
      const member = await getMemberData(userAttributes.sub);
      const memberData = {
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        email: member.email || '',
        phoneNumber: member.phoneNumber || '',
        gender: member.gender || '',
        country: member.country || '',
        city: member.city || '',
        profession: member.profession || ''
      };
      setFormValues(memberData);
      setInitialFormValues(memberData);
    } catch (error) {
      console.log('Error reading member details:', error);
    }
  }, [userAttributes]);

  const handleProfilePictureUpload = async (e) => {
    setProfilePic("assets/img/profilePicLoading.gif");
    const file = e.target.files[0];
    if (file) {
      try {
        const newProfilePic = await uploadProfilePicture(userAttributes.email, file);
        setProfilePic(newProfilePic);
      } catch (error) {
        console.log('Error uploading profile picture:', error);
        alert("Updating profile picture failed. Please ask admin to register you as a member if you just signed up.");
        setProfilePic("assets/img/avatar-placeholder.jpg");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });

    setIsFormUpdated(
      value !== initialFormValues[name]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateMemberData(userAttributes.sub, formValues);
      setShowEditModal(false); // Close the modal after save
    } catch (error) {
      console.log('Error updating user:', error);
      alert("Updating user data failed. Please ask admin to register you as a member if you just signed up.");
      setShowEditModal(false);
    }
  };

  const isFormChanged = () => {
    return (
      formValues.firstName !== initialFormValues.firstName ||
      formValues.lastName !== initialFormValues.lastName ||
      formValues.email !== initialFormValues.email ||
      formValues.phoneNumber !== initialFormValues.phoneNumber ||
      formValues.gender !== initialFormValues.gender ||
      formValues.country !== initialFormValues.country ||
      formValues.city !== initialFormValues.city ||
      formValues.profession !== initialFormValues.profession
    );
  };

  return (
    <Card className="profile-card">
      <Card.Body className="profile-card-body">
        <Row className="align-items-center">
          <Col md={5} className="text-center">
            <img
              src={profilePic || "assets/img/avatar-placeholder.jpg"}
              alt="Profile"
              className="rounded-circle img-fluid"
              style={{ width: "200px", height: "200px", objectFit: "cover" }}
            />
          </Col>
          <Col md={7} className="text-center">
            <h3>{formValues.firstName || userAttributes?.name} {formValues.lastName || userAttributes?.family_name}</h3>
            <p>Email: {userAttributes?.email}</p>
            <p>Phone: {userAttributes?.phone_number}</p>
            <Button variant="primary" onClick={() => setShowEditModal(true)} className="mt-0">
              <FaEdit /> Edit Profile
            </Button>
          </Col>
        </Row>
      </Card.Body>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} backdrop="static">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <img
                src={profilePic || "assets/img/avatar-placeholder.jpg"}
                alt="Profile"
                className="rounded-circle img-fluid"
                style={{ width: "150px", height: "150px", objectFit: "cover" }}
              />
              <div className="mt-2">
                <label htmlFor="upload-photo" className="btn btn-primary btn-sm">
                  <FaCamera /> Upload
                </label>
                <input
                  type="file"
                  id="upload-photo"
                  style={{ display: "none" }}
                  onChange={handleProfilePictureUpload}
                />
              </div>
            </div>
            {['firstName', 'lastName', 'email', 'phoneNumber', 'country', 'city', 'profession'].map((field) => (
              <Form.Group controlId={`form${field}`} key={field}>
                <Form.Label>{field.charAt(0).toUpperCase() + field.slice(1)}</Form.Label>
                <Form.Control
                  type={field === 'email' ? 'email' : 'text'}
                  name={field}
                  value={formValues[field]}
                  onChange={handleInputChange}
                />
              </Form.Group>
            ))}
            <Form.Group controlId="formGender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={formValues.gender}
                onChange={handleInputChange}
              >
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">Other</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={!isFormChanged()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Card>
  );
}

export default ProfileCard;