// src/view/Nav.jsx
import React, { useState, useEffect } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { loadFromLocalStorage, clearCache } from '../cache';

const Nav = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        checkUser();
    }, []);

    const checkUser = async () => {
        try {
            const userData = await loadFromLocalStorage('currentUser');
            setUser(userData);
        } catch {
            setUser(null);  // No user is signed in
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            clearCache(); // Clear the cache on sign out
            setUser(null);
            navigate('/home');
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark navbar-custom py-4 fixed-top">
            <div className="container px-5">
                <a className="navbar-brand" href="/home">ፈልሲ ሰላም</a>
                {user ? (
                    <Link onClick={handleSignOut} className="nav-link navbar-nav ms-auto text-white" style={{ fontSize: '20px', fontWeight: 'bold', background: 'none', border: 'none' }}> Log Out </Link>
                ) : (
                    <Link to="/dashboard" className="nav-link navbar-nav ms-auto text-white" style={{ fontSize: '20px', fontWeight: 'bold' }}> Member <br /> Log In </Link>
                )}
            </div>
        </nav>
    );
};

export default Nav;