import React from 'react';

function Apr_18_2023() {
  return (
      <div className="row gx-4 gx-lg-5">
        <div className="col-sm-6">
          <div className="post-preview">
            <a data-bs-toggle="collapse" href="#collapsePRT13" aria-expanded="false" aria-controls="collapsePRT13" style={{ textDecoration: 'none' }}>
              <h3>ጻውዒት ፈልሲ ንምቁራጽ ተጻብኦታትን
                ንሰላማዊ ዘተን ኣብ ሱዳን</h3>
            </a>
            <div className="collapse" id="collapsePRT13">
              <article>
                <div>
                  <p>ፈልሲ ሰላምን ዲሞክራስን ኤርትራ (ፈልሲ) ኣብ ሱዳን ኣጋጢሙ ብዘሎ ተጻብኦ ልባዊ ሓዘና እንዳገለጸት፣ ነባሪ መፍትሒ ንዝኾነ ፖለቲካዊ ጸገማት ኣብ ክቢ ጠረጴዛ ዝግበር ዘተ እምበር ኣፈሙዝ ከም ዘይኮነ ትኣምን። ስለዚ ድማ ኣብ ሱዳን ተጻብኦታት ቀልጢፉ ደው ክብልን ህዝቢ ሱዳን ሰላም ንክረክብን ፈልሲ ትጽውዕ። ተጻባእቲ ወገናት ተረጋጊኦም፣ ፍልልያቶም ብሰላማዊ መንገዲ፣ ንኩሉ ህዝቢ ሱዳን ብዘርብሕ፣ ከምኡውን ኣብ ዞባ ቀርኒ ኣፍሪቃ ሰላምን ምርግጋእን ብዘምጽእ መንገዲ ክፈትሕዎ ፈልሲ ትጽውዕ።</p>
                  <p>ህዝቢ ሱዳን ካብ 1960ታት ጀሚሩ ንህዝቢ ኤርትራን ካሎኦትን ክገብሮ ዝጸንሐን ዘሎን ሕያዉነት፣ ለውሃት፣ እንግዶት ፈልሲ ትዝክሮን ትፈልጦን እያ። ህዝቢ ሱዳን፡ ብሰንኪ ውግእን ግፍዒን ካብ ሃገሮም ንዝተሰዱ ኤርትራውያን፣ ኣብ ኣድላዪ እዋን ሓቀኛ መሓዛ ብሙዃኑ፡ ፈልሲ ስለቲ ሰናይ ተግባር ህዝቢ ሱዳን ብጣዕሚ ሕጉስቲ ምዃና ትገልጽ።</p>
                  <p>ከምዚ ኣብ ላዕሊ ዝተጠቕሰ፡ ፈልሲ፡ ኣብታ ፍትዉቲ ሃገረ ሱዳን፡ ነዚ ኩሉ ዓመታት ንህዝብና ብዘርኣኽሞ ሓልዮት ዘለዎ ኣተሓሓዛ ተመስግን። ኣብዚ ከቢድ እዋንዉን እንተኾነ፣ ህዝብን ሰበ ስልጣንን ሱዳን፡ እቲ ዘሎ ኩነታት ከይዓንቀፈኩም፣ ብዝተኻእለኩም መጠን ንህዝብና ዘድልዮ ሓለዋ ከም ትገብርሉ ጥርጥር የብልናን።</p>
                  <p>ምርግጋእ ዝዓሰላ ሱዳን፡ ኣብቲ ተነቃፊ ዞባ ቀርኒ ኣፍሪቃ፡ ሰላም፣ ምርግጋእ፣ ዲሞክራስን ሞዕባለን ንምምጻእ ኣወንታዊ ጽልዋን ሓይልን ከም ዝህልዋ ፈልሲ ብትሪ ትኣምን። ፈልሲ ብተወሳኺ ኣብ ሓባራዊ ጉዳያት ንናይ ሓባር ረብሓ፡ ኣብ ሃናጺ ዝኾነ ስራሓት ንምትሕግጋዝ፡ ምስ ህዝብን መንግስትን ሱዳን ክትጸምድ ትደሊ።</p>
                  <p>ፈልሲ፣ ምስ እቶም በቲ ጨካን ፍጥረት ኢሰይያስ ኣፈወርቂ ብኣሉታ ዝተጸልዉ ባእታት ብምልፋን፡ ነቲ ኣብ ቀርኒ ኣፍሪቃን ወጻኢን ምንጪ ዘይምርግጋ ኮይኑ ዘሎ ዉልቀ መላኺ ስርዓት ኢሰይያስ ኣፈወርቂ ካብ ስልጣን ንምዕላዉ ትንቀሳቐስ ናይ ኤርትራውያን ጥምረት እያ።</p>
                  <p>ፈልሲ ንህዝቢ ኤርትራ ሰላምን ዲሞክራስን፡ ኣብ ዘባ ቀርኒ ኣፍሪቃ ኸኣ ምርግጋእን ምዕባለን ንምምጻእ ዓሊማ ትንቀሳቐስ።</p>
                  <p>ዮሴፍ ገብረሂወት
                    <br />
                    ኣቦ ወንበር ፈጻሚት ሽማግለ ፈልሲ ሰላምን ዲሞክራስን ኤርትራ
                    <br />
                    18 ሚያዝያ 2023
                  </p>
                </div>
              </article>
              <p><a href="assets/pdf/press_release_13_tig.pdf">PDF ትግርኛ</a>.</p>
            </div>
            <p className="post-meta">
              Posted on Apr 18, 2023
            </p>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="post-preview">
            <a data-bs-toggle="collapse" href="#collapsePRE13" aria-expanded="false" aria-controls="collapsePRE13" style={{ textDecoration: 'none' }}>
                <h3>Call for the Immediate Silencing of Guns and for Peace to the Brotherly People of Sudan</h3>
            </a>
            <div className="collapse" id="collapsePRE13">
                <article>
                    <div>
                        <p>Seedling for Peace and Democracy in Eritrea (Seedling) would like to express it heartfelt sadness because of the conflict within Sudan and believes lasting solution to any political problems comes through discussion in a round table, and not through guns. Seedling would therefore like to call for the immediate silencing of guns and calls for peace to the brotherly people of Sudan. Seedling calls for the parties to the current conflict to calm down and resolve their differences in a peaceful manner, in a way that benefits the people of Sudan, and in a way that brings about peace and stability to the Horn African Region</p>
                        <p>Seedling recalls the hospitality the people of Sudan have been affording the people of Eritrea, and others, from as far as the 1960 up to the present. Seedling appreciates the people of Sudan for truly being a friend in need for Eritreans who have been fleeing their country because of war and persecution. Seedling is very thankful of this generous act from the people of Sudan.</p>
                        <p>Seedling, as mentioned above, appreciates the hospitality our people have been getting in your beloved country all these years. We are sure that even at this precarious moment, the people, and authorities of Sudan, despite their present situation will continue to try to give our people the protection they need as much as they can.</p>
                        <p>Seedling strongly believes a stable Sudan would be a positive influence and force for peace, stability, democracy, and prosperity to this volatile region. Seedling also envisions to engaging in constructive cooperative work, on matters of mutual interest, with the people and government of Sudan.</p>
                        <p>Seedling is a movement of Eritreans who struggle for the removal of Dictator Isayas Afewerki from power by engaging other actors who have been negatively affected by the ruthless nature of Dictator Isayas Afewerki, who has been the main actor of instability in the Horn of Africa Region and beyond. Seedling aims at bringing about peace and democracy to the people of Eritrea, and a stable and prosperous Horn of Africa region.</p>
                        <p>Yosief Gebrehiwet
                          <br />
                          Head of the Executive Committee Seedling for Peace and Democracy in Eritrea
                          <br />
                          Apr 18, 2023
                        </p>
                    </div>
                </article>
                <p><a href="assets/pdf/press_release_13_eng.pdf">PDF English</a>.</p>
            </div>
            <p className="post-meta">
                Posted on Apr 18, 2023
            </p>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="post-preview">
            <a data-bs-toggle="collapse" href="#collapsePRA13" aria-expanded="false" aria-controls="collapsePRA13" style={{ textDecoration: 'none' }}>
              <h3>م والديمقراطية في إريتريا
                نداء إلى إسكات البنادق الفورية والسلام للشعب  السوداني الشقيق
                يعبر حزب 'شتلات' السلام والديمقراطية في إريتريا</h3>
            </a>
            <div className="collapse" id="collapsePRA13">
              <article>
                <div>
                  <p>عن حزنه العميق بسبب الصراع الذي يجري في السودان ، ويعتقد الحزب أن الحل الدائم لأي مشاكل سياسية يأتي بالتفاوض في مائدة مستديرة، وليس من خلال البنادق، ولذلك يدعو حزب 'الشتلات' إلى إسكات البنادق على الفور ، وفي الوقت الذي يتمنى السلام للشعب السوداني الشقيق. يدعو أطراف الصراع الحالي إلى الهدوء وحل خلافاتهم بطريقة سلمية وبما يفيد شعوب السودان، وبطريقة تجلب السلام والاستقرار إلى منطقة القرن الأفريقي. </p>
                  <p>وحتى 1960 إننا إذ لا ننسى الضيافة التي قدمها شعب السودان لشعب إريتريا، وشعوب أخرى منذ العامالوقت الحاضر. يقدر حزب 'الشتلات' شعب السودان لكونه صديقًا حقًا للإريتريين الذين فروا من بلادهم بسبب الحرب والاضطهاد، وكذلك إننا ممتنون جدا لهذا العمل السخي من شعب السودان. نقدر الضيافةالتي حصل عليها شعبنا في السودان الحبيب طوال هذه السنوات </p>
                  <p>نحن على يقين من أنه حتى في هذه اللحظة غير المستقرة ، سيستمر شعب السودان وسلطاته، على الرغم من وضعهم الحالي ، في منح شعبنا الحماية التي يحتاجونها بقدر ما يستطيعون.
                    يؤمن حزب 'الشتلات' بقوة أن السودان المستقر سيكون له تأثير إيجابي وقوي من أجل السلام والاستقرار والديمقراطية والازدهار لهذه المنطقة المتقلبة.</p>
                  <p>كما يتطلع حزب 'الشتلات' الانخراط في عمل تعاوني بناء ، فيما يتعلق بالمسائل ذات الاهتمام المشترك ، مع شعب وحكومة السودان.</p>
                  <p>حزب' الشتلات' هي حركة للإريتريين الذين يناضلون من أجل إزالة الديكتاتور إسياس أفورقي من السلطة من خلال إشراك جهات فاعلة أخرى تأثرت سلبًا بالطبيعة القاسية للديكتاتور  أفورقي ، الذي كان الفاعل الرئيسي لعدم الاستقرار في منطقة القرن الأفريقي وخارجها.</p>
                  <p>يهدف حزب 'الشتلات' إلى إحلال السلام والديمقراطية لشعب إريتريا ، وجعل منطقة القرن الأفريقي مستقرة ومزدهرة.</p>
                  <p>يوسيف جبرهيويت
                    <br />
                    رئيس اللجنة التنفيذية لحزب شتلات السلام والديمقراطية في إريتريا
                    <br />
                    ١٧ ابريل ٢٠٢٣م
                  </p>
                </div>
              </article>
              <p><a href="assets/pdf/press_release_13_arb.pdf">PDF عربي</a>.</p>
            </div>
            <p className="post-meta">
              Posted on Apr 18, 2023
            </p>
          </div>
        </div>
      </div>
    );
  };

export default Apr_18_2023;
