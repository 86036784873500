import React, { useState }  from 'react';
import MenuNav from './Menu';
import Footer from './Footer';
import MoreMedia from './MoreMedia';
import BannerTemplate from './banner/BannerTemplate';

const Media = () => {
     // State to manage the visibility of the component
    const [showMoreMedia, setShowMoreMedia] = useState(false);

    // Function to toggle visibility
    const toggleMoreMedia = () => {
        setShowMoreMedia(prevShowComponent => !prevShowComponent);
    };

    const bannerStyle = {
        backgroundImage: "url('../../assets/img/media_img.png')",
        backgroundSize: "875px",
        backgroundRepeat: "repeat-x"
    };

    return (
        <main>     
            <header>
                <BannerTemplate
                    bannerStyle={bannerStyle}
                    heading="Media"
                    subHeading="ሓበሬታ" />
            </header>
            <div className="container">
            <MenuNav />
            {/* <!-- Post Content--> */}
            <article className="mb-4">
                <div className="container px-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <h2 className="col-sm-12 text-center">ሓበሬታ ካብ መራኸቢ ቡዙሓን</h2>

                        {/* <!-- Divider --> */}
                        <hr className="my-4" />
                        {/* <!-- /Divider --> */}
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/AU6LTUEgSAs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/C1QA7w0s_2I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>

                        {/* <!-- Divider --> */}
                        <hr className="my-4" />
                        {/* <!-- /Divider --> */}
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/tI2fTT5glhA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/2q33asSqXgc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/Qs8xV69u0Vw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>

                        {/* <!-- Divider --> */}
                        <hr className="my-4" />
                        {/* <!-- /Divider --> */}
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/Dqo2-LGl2vA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/e4svR8DYkhQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/Hy5X11YDwkk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>

                        {/* <!-- Divider --> */}
                        <hr className="my-4" />
                        {/* <!-- /Divider --> */}
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/ApZYYAHytHk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/gdLYpKRgCJo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="320" height="180" src="https://www.youtube.com/embed/cq5Ph5K6SoE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Divider --> */}
                    <hr className="my-4" />
                    {/* <!-- /Divider --> */}

                    <div className="row justify-content-center">
                        <button type="button" className="btn btn-outline-info col-md-4" id="more-media-btn" onClick={toggleMoreMedia}><strong> {showMoreMedia ? 'Less' : 'More ...'} </strong></button>
                        {showMoreMedia && <MoreMedia />}
                    </div>
                </div>
            </article>
        </div>
        <Footer />
        </main>
    );
};

export default Media