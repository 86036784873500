import React from 'react';

function Oct_18_2024() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT21" aria-expanded="false" aria-controls="collapsePRT21" style={{ textDecoration: 'none' }} >
                        <h3 className="text-center"> ኤርትራ፣ ኣብ ድሮ ካልእ ኲናት </h3>
                        <h3> መግለጺ ብዛዕባቲ ኣብ ሞንጎ ኤርትራን ግብጽን ሶማልያን ተመስሪቱ ዘሎ ዝምድና </h3>
                    </a>
                    <div className="collapse" id="collapsePRT21">
                        <article>
                            <div>
                                <p>ኣብ ዝሓለፈ ቅንያት መራሕቲ ሶማልያን ግብጽን ኣብ ኤርትራ ምስ ውልቀ መላኺ ኢሰያስ ኣፈወርቂ
                                    ተራኺቦም ናይ ሰለስቲኤን ሃገራት ጥምረት መስሪቶም ኣለዉ። ናይ ሰለስቴን ሃገራት ናይ ወጻኢ ጉዳያት
                                    ሚኒስተራት ዝርከብዎ ልዑል ናይ ሓባር ኮምቴውን መስሪቶም ኣለዉ። ኤርትራ ምስዘን መዳውብታ
                                    ዘይኮና ክልተ ሃገራት ርኡይ ዝኾነ ኤኮኖሚያዊ ይኹን ማሕበራዊ ዝምድናታት የብላን።
                                    ስለዚ እቲ ናይ ሰለስቲኦም ርክብ ነዞም ጉዳያት እዚኣቶም ዘሳልጥ ክኸውን ኣይክእልን።
                                    እምበኣር እዚ ጉዳይዚ ኣብ ድሮ ካልእ እኲናት ኣቲና ከም ዘሎና ጥራሕዩ ከመላኽት ዝኽእል።
                                    ሕጂ ኸኣ መንእሰያት ኤርትራ ኣብ ዘይምልከቶም ኲናት ኣትዮም ዝጠፍኡሉ ሓደገኛ ኩነት ንኣቱ
                                    ከም ዘሎና ንርኢ ኣሎና። ናይቶም ኣብ ትግራይ ብዝወጽዎ ዝተረፉ ዓሰርተታት ኣሽሓት ዝቑጸሩ
                                    ደቆም ሓዘን ብወግዒ ዘይጀመሩን ዘይዛዘሙን ወለዲ ካልእ ናይ ሓዘንን ዋይዋይታን መዓት ኢዩ ዝዳለወሎም ዘሎ።</p>
                                <p>ውልቀ መላኺ ኢሰያስ ኣብ 2018 ምስ ዶር. ኣቢይ ኣሕመድን ናይ ሽዑ መራሒ ሶማሊያ ዓብደላ ፎርማጆን
                                    ተመሳሳሊ ናይ ሰለስተ መራሕቲ ናይ ኲናት ኪዳን ፈጺሞም ነይሮም ኢዮም። እቲ ኩሉ ካብ ሓደ ሃገር
                                    ናብ ካልእ ሃገር ዝግበር ዝነበረ ዙረትን፣ ዝምደብ ዝነበረ ውጥናትን፣ ሸብዳዕድዕን ፈስታታትን ግን
                                    ንሰላምን፣ ዕብየትን፣ ጠቕምን፣ ሕውነትን ህዝብታት ናይዘን ሃገራት ዘይኮነስ ኣብ ልዕሊ ህዝቢ ትግራይ
                                    ናይ ጽንተት ኲናት ንምሕንጻጽን ንምፍጻምን ኢዩ ነይሩ። ኣብዚ ሰራዊት ኤርትራ ብሙሉእ ትሕዝቶኡ
                                    ዝተሳተፈሉ ኲናት ድማ ኣብ ልዕሊ ህዝቢ ትግራይ ናይ ጽንተት ወፈራ ተኻይዱ። ኣብዚ መራሕቲ ሻዕብያ
                                    "ኢትዮጲያ ከይትብተን" ብዝብል ምስምስ ዝፈጸምዎ ናይ ህልቂት ወራር ሃገርና ኤርትራን ህዝብናን
                                    ብዘይካ ብርቱዕ ጉድኣትን ሓዘንን ዋላ ሓንቲ ጠቕሚ ኣይረኸብሉን።</p>
                                <p>ኣብ መወዳእትኡ ድማ። እቲ ናይ ጽንተት ኲናት ፈሺሉ፣ ናይ ፕረቶርያ ናይ ሰላም ውዕል ተፈሪሙ፣
                                    ውልቀ መላኺ ኢሰያስ ተኾሊፉ ኣብ ትግራይ ኲናት ጠጠው ኢሉ፣ ህዝቢ ትግራይ ድማ ሰሪሩ።
                                    እቶም ኣብ ግዜ ኲናት ዝተዓራረኹ፣ ኣብ ግዜ ሰላም ግን ዝበኣሱ ኢሰያስን ኣፈወርቅን ዶር. ኣቢይን
                                    ተባኢሶም ድማ፣ ሕጂ ኢሰያስ ካልእ ናይ ሰለስተ መራሕቲ ጥምረት ጀሚሩ ላዕልን ታሕትን ክብል ከም
                                    ዝጀመረ ንርእዮ ኣሎና።  እዚውን ከምቲ ዝሓለፈ፣ ኣብ ድሮ ናይ ካልእ ኲናት ኣቲና ከም ዘሎና ኢዩ ዘመላኽት።</p>
                                <p>ድሮውን እቲ ስርዓት ከም ኣመሉ ነዚ ኲናትዚ ዝኸውን መድናገሪታት ብውሽጢ ውሽጥን ብጋህድን ይዝርግሕ ኣሎ።
                                    እቶም ልሙዳት መመኽነታታት፣ "ትግራይ ክትወረና ትሓስባ ኣላ" "ኢትዮጲያ ዓሰብ ክትወስድ ክትወረና ኢያ"
                                    "ሶማሊያ ብኢትዮጲያ ከይትውረር" . . .ዝብሉ ምስምሳት ድሮ ክንሰምዕም ጀሚርና ኣሎና።
                                    ህዝብና ሕጂውን በዞም ከምዚኦም ዝብሉ ምስምሳት ክታለል የብሉን።</p>
                                <p>ንሕና ከም ፈልሲ ሰላምን ዲሞክራስን ኤርትራ፣ ከምቲ ዝሓለፈ ነዚ ዝግበር ዘሎ ናይ ሰለስተ ሃገራት
                                    ኣዕናዊ ምትእስሳርን፣ ምቅርራብ ንኲናትን ክንኩንኖን ክንቃለሶን ኢና። ምስ ካልኦት ተቓለስትን
                                    ኣብ ደገ ዝርከብ ህዝብናን ብሓባር ኣንጻርቲ ክስዕብ ዝኽእል ሓደጋታት፣ ጽዑቕ ዲፕሎማሲያዊ
                                    ስራሓት ምክያድን ናይ ተቓውሞ መደባት ምስራዕን ከድልየና ኢዩ። ኩላትና ብሓባር ህዝብናን
                                    መንእሰያታትና ንምድሓን ከይተሓለለና ክንሰርሕን ክንቃለስን ኢና።</p>
                                <p>መጸዋዕታ ንህዝብን ሰራዊትን ኤርትራ!</p>
                                <p>እቲ ኣብ ኤርትራ ዘሎ ህዝብና ነዚ ከይስዕብ ንፈርሖ ዘሎና ኲናት ተረዲኡ ካብቲ ክመጽእ ዝኽእል
                                    ሓደጋ ነብሱ ከድሕነሉ ዝኽእል መንገድታት ክሓስብ የድሊ። እቶም ኣባላት ሰራዊትውን ናይ መጀመርታ
                                    ግዳይ ንሳቶም ስለዝኾኑ ብኣግኡ ካብቲ ሓደጋ ንምድሓን ፍታሕ ከናድዩን ጠበንጃታቶም ኣንጻርቶም
                                    ወግሐ ጸብሐ ኣብ ኲናት ዝሸምዎም ዘለዉ መራሕቶም ከቕንዑን ንጽውዖም።</p>
                                <p>ፈልሲ ሰላም ዲሞክራስን ኤርትራ
                                    <br />
                                    18.10.2024
                                </p>
                            </div>
                        </article>
                        <p>
                            <a href="/press-release/21">PDF ትግርኛ</a>
                        </p>

                    </div>
                    <p className="post-meta">
                        Posted on October 18, 2024
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    {/* If there is an English version available, it can be added here */}
                </div>
            </div>
        </div>
    );
}

export default Oct_18_2024;
