import React from 'react';
import MenuNav from './Menu';
import Footer from './Footer';
import { View } from '@aws-amplify/ui-react';
import JoinForm from './JoinForm';
import BannerTemplate from './banner/BannerTemplate';

function Join() {

    const bannerStyle = {
        backgroundImage: "url('../assets/img/join.jpg')",
        backgroundSize: "1000px",
        backgroundRepeat: "repeat-x"
    };

    return (
        <main>
            <header>
                <BannerTemplate 
                    bannerStyle={bannerStyle}
                    heading="Join the union!"
                    subHeading="ምሳና ሕበሩ!"/>
            </header>
            <View classNameName="container">
                <main className="mb-4">
                    <div className="container px-4 px-lg-5">
                        <MenuNav />
                        <div className="row gx-4 gx-lg-5 justify-content-center">
                            <div className="col-lg-12">
                                <h3>ናብ'ዚ ጥርናፈ ንምጽምባር ሓበሬታኹም ኣብ ታሕቲ መሊእኩም ልኣኹልና። ብዝቐልጠፈ ክንምልሰልኩም ኢና።</h3>
                                <br />
                                <h3>Please fill out the form below to be part of this coalition and we will get back to you as soon as possible.</h3>
                                <div className="my-5">
                                    {/* <JoinForm /> */}
                                    {/* <UserUpdateForm /> */}
                                    <JoinForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </View>
            <Footer />
        </main>
    );
};

export default Join;