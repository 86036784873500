import React from 'react';

function Sep_08_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT8" aria-expanded="false" aria-controls="collapsePRT8" style={{ textDecoration: 'none' }}>
                        <h3>መግለጺ ፈልሲ ሰላምን ዲሞክራስን ኤርትራ፡ ኣብ ጉዳይ ዳግማይ ወራር ትግራይ</h3>
                    </a>
                    <div className="collapse" id="collapsePRT8">
                        <article>
                            <div>
                                <p>ፈልሲ ሰላምን ዲሞክራስን ኤርትራ፡ ነቲ ብሓይልታት ኢትዮጵያን ኤርትራን ኣብ ልዕሊ ህዝቢ ትግራይ ዝካየድ ዘሎ ዳግማይ ወራር ብጥብቂ ይኹንን።</p>
                                <p>ድሕሪ ናይ 9 ኣዋርሕ ኣብ ፍጹም ከበባ: ንህዝቢ ትግራይ ብጥምየትን ብሕማምን ከምበርክኽዎ ስለዘይከኣሉ፡ እቲ ዝጀመርዎ ህዝባዊ ህልቂት (ጀኖሳይድ) ንምቅልጣፍ፡ ብኣማኢታት ኣሽሓት ወተሃደራት ዝተሰነየ ቅሉዕ ወራር የካይዱ ኣለዉ። ከም ኤርትራውያን መጠን ከኣ፡ ብፍላይ በቲ ዘሕፍር ዝኾነ ስርዓት ኢሳያስ ዝግበር ዘሎ ቁሉዕ ወራር (ብኹሉ ሸነኻት - ካብ ሩባ ተከዘ ክሳብ ዳሉል)፡ ንህዝብታት ኤርትራን ትግራይን ኣብ ወለዶታት ክወጽእ ዘይክእል ጽልኢ ንምትካል ዝዓለመ ስለዝኾነ፡ ብኹሉ ዓቕምታትና ክንቃለሶ ምዃና ነፍልጥ።</p>
                                <p>ዉልቀመለኽቲ ኣብይን ኢሳያስን ነዚ ወራር ከካይድዎ ዝደፈሩ፡ ሕብረተሰብ ዓለም ነቲ ዝሓለፈ ግፍዒ ብስቕታ ወይ ብሸለልትነት ወይ’ውን ብኣድልዎ ስለዝርኣዮ እዩ። ንሕና ኣባላት ፈልሲ፡ ነዚ ኢሰብኣዊ ዝኾነ ስቕታ ኣይንጽምበሮን ጥራሕ ዘይኮነስ፡ ብትሪ ንኹንኖ። ካብኡ ሓሊፍና’ውን፡ ብዝክኣለና ተግባራት ምስ ህዝቢ ትግራይ ሓቢርና ክንቃለሶ ምዃና: ንኹሎም ደለይቲ ፍትሒ ኤርትራውያን ንኽትሓባበሩና ጻዊዕትና ነቕርብ።</p>
                                <p>ዝኸበርካ ህዝቢ ትግራይ፡</p>
                                <p>ነዚ: በዞም ፋሺስታውያን ወረርቲ ሓይልታት ኢትዮጵያን ኤርትራን ዝወርደካ ዝነበረን ዘሎን ዓሌት ናይ ምጥፋእ ወራር መኪትካ ከም እትዕወት ምንም ጥርጥር የብልናን። ኣኳ ድኣ፡ በቲ እትገብሮ ዘለኻ ዘይሕለል ምርብራብ፡ ብምግራምን ኣድናቖትን ኢና እንዕዘበካ ዘለና። ኣብ መጨረሽትኡ፡ ካብ’ዚ መዓት እዚ ብዓወት መሰስ ኢልካ ከምእትወጽእ ርግጸኛታት ኢና።</p>
                                <p>ኦ ህዝቢ ትግራይ፡</p>
                                <p>እቶም ኣብ ፈልሲ ተኣኻኺብና እንርከብ ደለይቲ ጥምረት ኤርትራውያን እዚ ኽንብል ንደሊ፡</p>
                                <ul>
                                    <span>➢ እቲ ስቓይኩም፡ ስቓይና </span><br />
                                    <span>➢ እቲ ሓዘንንኩም፡ ሓዘንና</span><br />
                                    <span>➢ እቲ ቃልስኹም፡ ቃልስና</span><br />
                                    <span>➢ እቲ ኲናትኩም፡ ኲናትና</span><br />
                                    <span>➢ እቲ ዓወትኩም፡ ዓወትና</span>
                                </ul>
                                <p>ብሓጺሩ፡ ኣብዚ እዋን ብድሆኹም፡</p>
                                <ul>
                                    <span>➢ ንሕና፡ ንስኹም</span><br />
                                    <span>➢ ንስኹም፡ ንሕና</span>
                                </ul>
                                <p>ከምዝኾና ኸነረጋግጸልኩም ንፈቱ።</p>
                                <p>ትግራይ ትስዕር!</p>

                                <p>ፈጻሚት ኮሚቲ ፈልሲ ሰላምን ዲሞክራስን ኤርትራ</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_8_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Sep 08, 2022
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRE8" aria-expanded="false" aria-controls="collapsePRE8" style={{ textDecoration: 'none' }}>
                        <h3>Statement of Seedling for Peace and Democracy in Eritrea Regarding the Second Invasion of Tigray</h3>
                    </a>
                    <div className="collapse" id="collapsePRE8">
                        <article>
                            <div>
                                <p>Seedling for Peace and Democracy in Eritrea condemns, in the strictest sense, the second round
                                    of invasion of the people of Tigray by Ethiopian and Eritrean forces.</p>
                                <p>The invading forces of Eritrea and Ethiopia are engaged in this open invasion, by lining up
                                    hundreds of thousands of soldiers and artilleries, both ground and air, including drones, in order
                                    to speed up their genocidal acts; and they are doing this because they have failed to make the
                                    people of Tigray kneel down by the 9 months of absolute siege and encirclement meant to finish
                                    them off through famine and disease.</p>
                                <p>As Eritreans, we would like to confirm that we will fight in all ways we can against these
                                    invaders, as we understand that the main aim of this open invasion (that stretches from the
                                    Tekeze river all the way to Dallol in Afar) by the despot regime of Isaias Afewerki is to plant a
                                    seed of hatred and enmity between the peoples of Eritrea and Tigray now, and for generations to
                                    come.</p>
                                <p>Seedling for Peace and Democracy in Eritrea believes the reason why Dictators Isaias Afewerki
                                    of Eritrea and Abiy Ahmed of Ethiopia dared to start this second invasion is because of the
                                    silence, bias and ignorance of the international community to the past invasion and genocide
                                    against the people of Tigray. Not only are we, members of Seedling for Peace and Democracy in
                                    Eritrea, refuse to be part of this inhuman silence, we also condemn it in the strongest terms
                                    possible.</p>
                                <p>Further, we will fight against the invasion and invaders in all ways we can, hand in hand with the
                                    people of Tigray, and we call upon other Eritrean justice seekers to join us in this humane fight.</p>
                                <p>To the people of Tigray,</p>
                                <p>We have no single doubt that you will defeat these fascist invading forces of Eritrea and
                                    Ethiopia, and prevail in the fight for your very existence. In fact, we are at awe and admiration;
                                    watching your relentless struggle against these inhuman forces. Finally, we are confident that
                                    you will come victorious from this calamity.</p>
                                <p>As a final note,</p>
                                <p>We, Eritreans who are in Seedling for Peace and Democracy in Eritrea, would like to state the
                                    following to the patriotic people of Tigray:</p>
                                <ul>
                                    <span>➢ Your suffering is our suffering </span><br />
                                    <span>➢ Your sadness is our sadness</span><br />
                                    <span>➢ Your struggle is our struggle</span><br />
                                    <span>➢ Your war is our war</span><br />
                                    <span>➢ You victory is our victory</span>
                                </ul>
                                <p>In short, we would like to confirm to you, that at this challenging time,</p>
                                <span>➢ We are you, and</span><br />
                                <span>➢ You are us</span>
                                <p>Tigray Prevails!</p>
                                <p>Seedling for Peace and Democracy in Eritrea</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_8_eng.pdf">PDF English</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Sep 08, 2022
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Sep_08_2022;