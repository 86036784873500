import React from 'react';
import './../App.css';

const MoreMedia = () => {
    return (
        <main>
            <div className="row gx-4 gx-lg-5 justify-content-center" id="more-media-placeholder">
                {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/OPQ2cVlt1pI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/9K2irJi8QJM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/2K6KVQ5g2wk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/c371imxS1DI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/cwUfy1oVgqg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/HwVS4bSmo0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/LVV47nJEcNg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/-RGkA8UZ9Pk?start=75" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/Ta2T7Z6NNVk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/n23mjiQLqDA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/_AoeeNc0qZ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/XVHexEmtJrA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="post-preview">
                <div className="col-sm-12 text-center">
                    <a className="link-primary" href="https://martinplaut.com/2022/11/02/text-of-todays-peace-agreement-between-the-ethiopian-government-and-tigray-administration-signed-in-south-africa/" target="_blank">
                        <h3>Text of today’s peace agreement between the Ethiopian government and Tigray administration signed in South Africa</h3>
                    </a>
                    <p className="post-meta">
                        Published by Martin Plaut <br />
                        Journalist specialising in the Horn of Africa and Southern Africa <br />
                        Posted on Nov 02, 2022
                    </p>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/Q6eRKqR80zE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Ftedros.menghistu.9%2Fvideos%2F5670710566292703%2F&show_text=true&width=560&t=0"
                        width="320"
                        height="180"
                        style={{ border: "none", overflow: "hidden", scrolling: "no" }}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/uYrD-IZaHGk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/ZaTXbT9l8OU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/7fSsQbAW7CQ?start=88" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/UetXhyxz-K4?start=558" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/HVeB5Yb4cf0?start=322" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/b4rQgAhl4-U?start=403" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/O9eKZEmGrW4?start=29" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/t8FOo7j3uko?start=79" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/OXnDQF9Uuho?start=428" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100048891292421%2Fvideos%2F2154562468047077%2F&show_text=false&width=560&t=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/1RctSGvcqw8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2F100048891292421%2Fvideos%2F1002795727079467%2F&show_text=false&width=560&t=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2F100048891292421%2Fvideos%2F710297533381395%2F&show_text=false&width=560&t=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>


            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/y_UibevzURw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/mwujEfF3FuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/sD0qb2LaAP8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/aV767jQHmwI?start=76" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div><div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/SjrvzXmvmW0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/VQZFR-yBDQk?start=137" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/adeFUFrabLE?start=3142" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/zkQ1tn53qkc?start=555" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/nwW8qTBrTiM?start=555" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/a99Be-AEJjY?start=407" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/JgSnQYVGH3I?start=36" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/Z1_l8CUlRxU?start=388" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/9MdR_uOO7fs?start=46" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/riVVQsLkrAg?start=3825" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/XfjoGReTP2o?start=411" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/b6640Ge2mSs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/FKXRbbu0Xz4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/c8f43Zj6stQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>

            {/* <!-- Divider --> */}
            <hr className="my-4" />
            {/* <!-- /Divider --> */}

            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/QQCHGE6IJg4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/AZm3I787N2k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-md-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe width="320" height="180" src="https://www.youtube.com/embed/gdHQWHX2Wwo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            </div>
        </main>
    );
};

export default MoreMedia