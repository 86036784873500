import React from 'react';

function Nov_29_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                {/* The content for the first column is commented out, so it's represented as empty for potential future use. */}
                <div className="post-preview">
                    {/* Future content */}
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRE11" aria-expanded="false" aria-controls="collapsePRE11" style={{ textDecoration: 'none' }}>
                        <h3>Letter to President Biden - Rescind Invitations to Leaders of Ethiopia and Eritrea</h3>
                    </a>
                    <div className="collapse" id="collapsePRE11">
                        <article>
                            <div>
                                <p>Dear Mr. President,</p>
                                <p>
                                    Seedling for Peace and Democracy in Eritrea (hereinafter referred to as Seedling) would like to
                                    express its utmost disappointment to the Biden administration for inviting Eritrean and Ethiopian
                                    leaders to the U.S.-African Leaders Summit from December 13 to 15 of 2022 in Washington,
                                    DC. Both leaders happen to be the cause of unimaginable horror, an ongoing genocide that has
                                    so far killed hundreds of thousands of Tigrayans, and the destabilization of the whole region.
                                </p>
                                <p>
                                    As your administration is very much aware of, the government of the Federal Democratic
                                    Republic of Ethiopia, assisted by the government of the State of Eritrea, has put a complete siege
                                    of Tigray for two years, a siege that has caused over 600,000 Tigrayan deaths so far. Tens of
                                    thousands of Tigray women have also been raped. Tens of thousands more have been massacred.
                                    Millions more have been forcibly displaced. These are genocidal governments that use mass
                                    killings, mass rapes, starvation, denial of medicine and all kinds of services as means of warfare
                                    against the civilian population. Documents are abundant, including a statement by Secretary
                                    Antony John Blinken, confirming that these brutal governments committed a massive ethnic
                                    cleansing in Western Tigray, intentionally displacing more than a million people. And these
                                    atrocities are continuing as of this time by the forces of the aforementioned governments.
                                </p>
                                <p>
                                    To make matters worse, though it has been a month since the Pretoria ceasefire agreement was
                                    signed, so far there is no unfettered access to basic needs. Eritrea and Amhara forces soldiers are
                                    still inside Tigray committing all kinds of genocidal atrocities despite repeated calls to leave
                                    Tigray from AU, the USA and other national and international organizations. The lives of
                                    thousands of civilians which would have been saved are perishing in vain right now.
                                </p>
                                <p>
                                    In addition, as your administration is well aware of, the main cause of instability in the Horn of
                                    Africa is the Eritrean leader, Mr. Isaias Afewerki. In the past three decades, the people of Eritrea
                                    have been living under indescribable totalitarian terror. This regime has been committing crimes
                                    against humanity and genocide against the people of Eritrea both pre and post Eritrean
                                    "independence" in 1993, resulting in a mass exodus of almost a million people. It has waged war
                                    against all its neighbors—Yemen, Sudan, Ethiopia, Djibouti and, now, Tigray — and sponsored
                                    terrorism in all these areas by training and arming all kinds of dissident groups.
                                    Your government (and the UN) has continuously documented many of these crimes, including the
                                    recent crimes against the people of Tigray, and the destabilizing role of the Eritrean regime in the
                                    Horn of Africa and beyond. Years of inaction by the international body has brought us to this
                                    massive unimaginable death and pain in Tigray.
                                </p>
                                <p>
                                    Mr. President, for all the reasons mentioned above, Seedling hopes you rescind your invitation to
                                    these genocidal leaders or the representatives of their respective governments. It would be a
                                    grave mistake to disregard the magnitude of the crimes committed by these leaders. Instead, the
                                    most powerful and democratic nation ought to stop the ongoing genocide in Tigray, facilitate
                                    immediate access of basic needs to the thousands who are still dying due to lack of food and life
                                    saving medicines.
                                </p>
                                <p>
                                    After the Rwanda genocide, America vowed, “Never again!” Yet, Mr. President, this genocide
                                    has been taking place for two long years; which happen to fall exactly during the two years you
                                    have been in the White House. Hence, in years to come, no one will be able to claim ignorance.
                                    That is to say, Mr. President, this entire genocide has been taking place on your watch!
                                </p>
                                <p>
                                    Seedling is a movement of Eritreans who struggle for the removal of Eritrean totalitarian leader,
                                    Isaias Afewerki, from power by engaging other actors, including the United States; that is, a man
                                    who has been the main actor of instability inside and outside Eritrea. Seedling aims at bringing
                                    about peace and democracy to the people of Eritrea, and a stable and prosperous Horn of Africa
                                    region.
                                </p>
                                <p>
                                    Regards,
                                </p>
                                <p>Yosief Ghebrehiwet <br />
                                   Chair of the Executive Committee <br />
                                   Seedling for Peace and Democracy in Eritrea
                                </p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_11_eng.pdf">PDF English</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Nov 29, 2022
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Nov_29_2022;
