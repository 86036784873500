import React from 'react';
import Nav from './Nav';
import MenuNav from './Menu';
import Footer from './Footer';
import { View } from '@aws-amplify/ui-react';
import BannerTemplate from './banner/BannerTemplate';

function YouthSymbosium() {

    const customBannerStyle = {
        backgroundImage: "url('../assets/img/YouthSymposiumFlyer.jpg')",
        backgroundSize: "700px",
        backgroundRepeat: "repeat-x"
    };

    return (
        <main>
            <header>
                <Nav />
                <BannerTemplate 
                    bannerStyle={customBannerStyle}
                    heading="መንእሰያት ኤርትራ - ካበይ፡ ኣበይ፡ ናበይ"
                    subHeading="The Eritrean Youth: Where-from, Where-to" />
            </header>
            <View classNameName="container">
                <main className="mb-4">
                    <div className="container px-4 px-lg-5">
                        <MenuNav />
                        <div className="row text-muted">
                            <div className="col-sm-6 text-center">
                                <h2>መንእሰያት ኤርትራ - ካበይ፡ ኣበይ፡ ናበይ</h2>
                            </div>
                            <div className="col-sm-6 text-center">
                                <h2>The Eritrean Youth: Where-from, Where-to</h2>
                            </div>
                        </div>
                        
                        <hr className="my-4" />

                        <div className="row gx-4 gx-lg-5">
                            <div className="col-sm-6">
                                <div className="post-preview">
                                    <article>
                                        <div>
                                            <p>ዕለታት፡ ለካቲት 23, 24, 25 <br />
                                                ሰዓታት፡ ብበርሊን ካብ 4:00 ድ.ቀ. ክሳብ 11 ምሸት። ብኒዉ ዮርክ ካብ 10 ናይ ንጉሆ ክሳብ 5 ድ.ቀ እዩ። <br />
                                                ናይ ዌቢናር ጥብቆ https://us02web.zoom.us/j/84723860967</p>
                                            <p>ሰላም፡ ኣብ ደንበ ፍትሒ ዘለኹም ኩሉኹም መእሰያት ኤርትራ፡ ብፈልሲ ሰላምን ደሞክራስን ኤርትራ ዝተዳለወ፡ “መንእሰያት ኤርትራ - ካበይ:
                                                ኣበይ: ናበይ” ኣብ ትሕቲ ዝብል ኣርእስቲ፡ በቶም ኣብ ዝተፈላለየ ዓዉዲ ዝነጥፉ ዘለዉ ፍትዋት መንእሰያትና፡ ሓደ ዓቢ ሲምፖዝዮም
                                                ተዳልይልኩም ኣሎ።</p>
                                            <p>ኣብቲ ብመንእሰያት ብዛዕባ መንእሰያት፡ ንፈለማ እዋን ዝቐርብ ዘሎ ሲምፖዝዮም፡ ኣብዚ ዝሓለፈ 30 ዓመታት ንሕብረተ-ሰብና ብሓፈሻ፡
                                                ንመንእሰያትና ኸኣ ብፍላይ፡ ብዝተፈላለየ መዳይ ኣብ ሂወቶም ክጸልዎም ዝጸንሐ ዓበይቲ ዛዕባታት፡ ዘለዎም ሃብታም ፍልጠትን ተመኩሮን ከካፍሉና
                                                እዮም። ኣብነት ንምጥቃስ ዝኣክል፡-</p>
                                            <ul>
                                                <span>1. ኩነታት መንእሰያት (ደቂ-ተባዕትዮን ደቂ-ኣንስትዮን) ኣብ ውሽጢ ወጻኢን ኤርትራ፡ ኣብ ሳዋ፡ ኣብ ኣገለግሎት፡ ኣብ
                                                    ቤት-ማእሰርትታት፡ ኣብ መዓስከር ስደተኛታት፡ ኣብ መገዲ ስደት፡ ኣብ ዝበጽሕዎ ሃገራት፡ ወዘተ፣ </span><br /><br />
                                                <span>2. ብድሆታት ናብራ እዚ ወለዶ እዚ - ኣብ ዓውዲ ትምህርቲ፡ ኣብ ቁጠባ ናብርኦም፡ ኣብ ወሊድ፡ ኣብ ምትሕዉዋስ ሕብረተሰብ
                                                    (assimilation)፡ ኣብ ጥዕና ስነ-ኣእምሮ፡ ወዘተ፣</span><br /><br />
                                                <span>3. ፖለቲካዊ ብድሆታት እዚ ወለዶ - ደምበ ተቓውሞ፡ ብሪጌድ ንሓመዱ፡ ብሄራውነት፡ መንነት፡ ኤርታራውነት። ኣውራጃውነት።
                                                    ወዘተ፣</span><br /><br />
                                                <span>4. መንእሰያት ትግራይ ብዝምልከት - ርክብ ምስ መንእሰያት ኤርትራ፡ ኩነታት መንእሰያት ትግራይ፡ ጃምላዊ ዓመጽ ደቀንስትዮ፡
                                                    ምንቅስቓስ ደቂ-ኣንስትዮ ትግራይ (ይኾኖ”)፣</span><br /><br />
                                                <span>5. ክንዮ ኤርትራውነት (Beyond Eritreanism) – Eritrean youth and cosmpolitanism,
                                                    Pan-Africanism, Black Lives Matter, Feminism, etc.</span>
                                            </ul>
                                            <p>እዞም እትርእይዎም ዘለኹምን ካለኦትን መንእሰያትና ኣዝዩ ሰፊሕን ኣገዳሲን፡ ልዕሊ 30 ኣርእስቲታት ዝሓዘ መጽናዕታዊን ስነ-ጥበባዊን
                                                ትሕዝትኦም ከሺኖም ከቕርቡልና ዝዳለዉሉ ዘለዉ ስምፖዝዮም ብምዃኑ፡ ሓደራ ከይሓልፈኩም። ኹሉኹም ኸኣ ብኽብሪ ትዕደሙ ኣለኹም።</p>
                                            <p>ቆጸራኹም፡ ቆጸራና ምስ’ዞም መንእሰያትና ይኹን።
                                                <br /><br />
                                                ፈልሲ ሰላምን ደሞክራስን ኤርትራ
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="post-preview">
                                    <article>
                                        <div>
                                            <p>Date፡ February 23, 24, 25 <br />
                                                Time፡ 4:00 PM to 11 PM Berlin Time : 10:00AM to 5:00PM New York Time. <br />
                                                Link to join Webinar https://us02web.zoom.us/j/84723860967
                                            </p>
                                            <p>This is a youth symposium organized by the Seedling for Peace and Democracy in Eritrea,
                                                under the motto of “By the youth, about the youth”. Thus, the title of this symposium
                                                is: “The
                                                Eritrean Youth: Wherefrom, Whereto”.</p>
                                            <p>The symposium is a platform for the youth of Eritrea to share their rich experience and
                                                knowledge on the most difficult and complex challenges they have been facing inside and
                                                outside Eritrea over the past 30 years and their solutions. About forty women and men
                                                will
                                                participate in the event.</p>
                                            <p>The content will cover the following and others.</p>
                                            <ul>
                                                <span>1. The situation of youth (men and women) inside Eritrea, in SAWA, in the military
                                                    service, in
                                                    prisons, in refugee camps, on the way to exile, in the countries they finally reach,
                                                    etc.;</span><br /><br />
                                                <span>2. The life challenges of this generation – in the field of education, in their
                                                    economic livelihood,
                                                    in family raising, in assimilation, in mental problems, etc.;</span><br /><br />
                                                <span>3.Political challenges of this generation – in the opposition camp, in brigade
                                                    Nhamedu; and in
                                                    regards to nationalism, identity, Eritreanism, regionalism, etc.,</span><br /><br />
                                                <span>4. Tigray Youth - relations with Eritrean youth, the current situation of Tigray
                                                    youth, mass
                                                    violence against women, Tigray Women’s Movement (“Ykono”), etc.;</span><br /><br />
                                                <span>5. Beyond Eritreanism – Eritrean youth and cosmopolitanism, Pan-Africanism, Black
                                                    Lives
                                                    Matter, Feminism, etc.</span>
                                            </ul>
                                            <p>You are all cordially invited to attend the youth-to-youth program.
                                                <br /><br />
                                                The Seedling for peace and democracy in Eritrea.
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        
                        <hr className="my-4" />
                        <article className="mb-4">
                            <div className="container px-4 px-lg-5">
                                <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="row text-muted">
                                        <div className="col-sm-6 text-center">
                                            <h3>ሰነዳት ሲምፖዚዮም</h3>
                                        </div>
                                        <div className="col-sm-6 text-center">
                                            <h3>Symposium Archive</h3>
                                        </div>
                                    </div>

                                    
                                    <hr className="my-4" />
                                    
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/JoDkqtxLwdI?si=7I9UWL2Fzc0KZilp"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/QWUlLPv5GOU?si=P-iJ6h_EZTGcBBiE"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/202MdGnnaFc?si=-fvAHsYh-hPMZRt0"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    
                                    <hr className="my-4" />
                                    
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/YfB8ZJaSARQ?si=9xjIfKKwZDs-d-WE"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/_34e8nqa99o?si=Iy0wMs4my4azdQD4"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/7enQQgt0z8I?si=FSQs5N4mpzRXYMlj"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    
                                    <hr className="my-4" />
                                    
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/uZe2XI3dVi4?si=pfz6zUAscqW3v7uq"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/2FrKWNGuuK8?si=mCXUJbEkzUqgWQlY"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/9wDGNn_92J8?si=J9qLF4OXqxs5XeM3"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    
                                    <hr className="my-4" />
                                    
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/cV_uNGxdIJk?si=YdMIGYq3HYZ8qTDT"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/sQHQFQvWqRk?si=kssPXaxRPqbHKLmf"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/1gAElUIJtZs?si=ASTSE_z1pxiV-MNM"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    
                                    <hr className="my-4" />
                                    
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/ZZATqW7kmyQ?si=zWHe_YBsWrzfz24S"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/9qPo4GJ2LAk?si=lodVmwjX7t1Xossq"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/oMzZ4O0Jmjs?si=0soZ9Ap3DYj2P4XR"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    
                                    <hr className="my-4" />
                                    
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/Od3sdaTzzDA?si=1Upbop_05Yvxz4Zw"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/1neq4r3ltIw?si=ZKcYfQ7YypBLGMAo"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/Ww5COJMWg34?si=Jr5zsX4J4uKt7JY-"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>

                                    
                                    <hr className="my-4" />
                                    
                                    <div className="col-md-4">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="320" height="180"
                                                src="https://www.youtube.com/embed/EqYDLht6Pmg?si=xQHWkqVK3RC3EGAT"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                        
                    </div>
                </main>
            </View>
            <Footer />
        </main>
    );
};

export default YouthSymbosium;