import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PRESS_RELEASE_IMAGES } from './pr_constants';
import './../../PressRelease.css';  // Import CSS for styling
import Footer from '../Footer';

const PressRelease = () => {
  const { id } = useParams();  // Get the dynamic id from the URL
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Get the list of image file names from the constants map based on the id
    const imgArray = PRESS_RELEASE_IMAGES[id] || [];  // Fallback to empty array if id not found

    // Construct the full image paths
    const imagesDirectory = `${process.env.PUBLIC_URL}/assets/img/pr/`;
    const fullImagePaths = imgArray.map(img => `${imagesDirectory}${img}`);

    setImages(fullImagePaths);
  }, [id]);

  if (images.length === 0) {
    return <p>No images found for this press release.</p>;
  }

  return (
    <main>
      <header className="masthead py-5" />
      <div className="press-release-container">
        <div className="vertical-slideshow-container">
          {images.map((image, index) => (
            <div className="vertical-image-container" key={index}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="vertical-slideshow-image"
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PressRelease;
