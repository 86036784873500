import { useState } from 'react';

export default function Jul_10_2023() {
  const [collapsePRT14, setCollapsePRT14] = useState(false);

  const toggleCollapse = (id) => {
    if (id === "collapsePRT14") {
      setCollapsePRT14(!collapsePRT14);
    }
  };

  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRT14")}
            aria-expanded={collapsePRT14}
            aria-controls="collapsePRT14"
            style={{ cursor: 'pointer' }}
          >
            <h3>ናይ ደገፍ መግለጺ ፈልሲ ሰላምን ደሞክራስን ኤርትራ</h3>
          </a>
          <div className={`collapse ${collapsePRT14 ? 'show' : ''}`} id="collapsePRT14">
            <article>
              <div>
                <p>ህዝቢ ኤርትራ ካብቲ ብስርዓት ህግደፍ ኣብ ልዕሊኡ ዘወርዶ ዘሎ ከቢድ ግፍዒ፡ መከራን ስደትን ጽንተትን ንምውጻእ ዘካይዶ ኩሉ መዳያዊ ከቢድን ዝተሓላለኸን ቃልሲ ኣብ መወዳእትኡ ሓርነቱ ብምርግጋጽ ከም ዝዛዘም ዝተረጋገጸ እዩ። እዚ ኣብ ዝሓለፈ ቀዳመ ሰንበት፡ ካብ ሙሉእ ኣዉሮጳ ብዝመጹ ኣባላት በርጌድ ንሓመዱን ካለኦት ደለይቲ ፍትሒን ኣንጻር ፈስቲቫል ህግደፍ ኣብ ጊሰን ጀርመን ዝተኻየደ ትብዓትን ዉሕልለትን ዝመልኦ ታሪኻዊ ዓለም ዘዛረበ፡ ሰለማዊ ቃልሲ፡ መጀመርታ ናይቲ ዘይተርፍ ዓወት ህዝብና እዩ። ከምዚ ዓይነት ቃልሲ፡ ኣብ ኩሉ ዓለም፡ እንኮላይ ኣብ ውሽጢ ሃገርና ክቀጻጸል ምዃኑ ዘረጋግጽ እዩ።</p>
                <p>በዚ ኣጋጣሚ፡ ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ በርጌድ ንሓመዱ ኣብ ኣዉሮጳን ወጻኢን ዘካይድዎ ዘለዉ ሰለማዉን ሕጋዉን ኩሉ መዳያዊ ቃልሲ፡ የሞጉስን ወግዓዊ ደገፉን ይገልጽ። ኣብቲ ዝካየድ ዘሎ ቃልሲ፡ ኩሎም ኣባላት ፈልሲ፡ ካብኦም ዘድሊ ዘበለ ብሙሉእ ዓቕሞም የበርክቱ ከም ዘለዉ ፍሉጥ ኮይኑ፡ ብማእከላይ መሪሕነት ፈልሲ ደረጃውን፡ በርጌድ ንሓመዱ ብዝደልዮን ዝጠልቦን መሰረት፡ ብምኽሪ፡ ብሞያዊ ክእለት፡ ቁጠባን ካልእን፡ ዓቕሙ ብዝፈቐዶ ንከበርክት ኣብ ጎንኹም ከም ዘሎ ከረጋግጽ ይፈቱ።</p>
                <p>እዚ ከምዚ ኢሉ እንከሎ፡ ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ነቲ ዝካየድ ዘሎ ቃልሲ ንምድራዕን ምልኣት ንኽእህዎን፡ ኣብ ዲፕሎማስያዉን ፖለቲካዉን ከምኡዉን ኣብ ባይታ ብዝከኣሎ ይሰርሕ ከም ዘሎ ከረጋግጸልኩም ይደሊ። ሓደ ካብኡ እቲ፡ “ናይ ስርዓት ለውጢ ኣብ ኤርትራ (Regime Change in Eritrea) ብዝብል ጭርሖ፡ ካብ 14-16 ሓምለ 2023፡ ኣብ ዋሽንግተን ዲሲ ዘካይዶ ዘሎ ሱምፖዝዮም እዩ። እቲ ሱምፖዝዮም፡ እቲ ኣብ ኤርትራ ዘሎ ስርዓት ኣብ ልዕሊ ህዝብናን ኣብ ህዝቢታት ቀርኒ ኣፍሪቃን ብዝሕምሶን ብዝፍጽሞን ዘሎ ግፍዒን መከራን፡ ክእለ ጥራይ ከም ዝግባእ ንህዝቢ ዓለም ብዓዉታ ንጹር መልኽቲ ንምትሕልላፍ እዩ።</p>
                <p>እዚን ካልእን ተወሃሂዱ፡ ብፍላይ ከኣ በቲ ኣብ ውሽጥን ኣብ ከባቢ ሃገርናን እነካይዶ ሓበራዊ ተግባራዊ ቃልሲ፡ ኣብ መወዳእትኡ ነቲ ኣብ ኤርትራ ዘሎ ሰይጣናዊ ስርዓት ካብ መሰረቱ ዝጸራርገሉ እዋን ርሑቕ ኣይክኸዉንን እዩ። ፈልሲ ሰላምን ደሞክራስን ኤርትራ በዚ ኣጋጣሚ፡ ምስ በርጌድ ንሓመዱን ብተግባር ዝንቀሳቐሱ ካለኦት ፖለቲካዊ ሓይሊታትን ሃገርና፡ ኣብ ጭቡጥ ዝኾነ መደብን ዕዮ ስራሕን ንምጽማድ ድልዉ ከም ዘሎ እንዳ ገለጸ ጻዉዒቱ የቕርብ።</p>
                <p>ዮሴፍ ገብረሂወት
                  <br />
                  ግዝያዊ ኣቦ ወንበር ፈጻሚት ሽማግለ ፈልሲ ሰላምን ዲሞክራስን ኤርትራ
                  <br />
                  10 ሓምለ 2023
                </p>
              </div>
            </article>
            <p><a href="assets/pdf/press_release_14_tig.pdf">PDF ትግርኛ</a>.</p>
          </div>
          <p className="post-meta">Posted on Jul 10, 2023</p>
        </div>
      </div>
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          {/* Placeholder for the English version */}
        </div>
      </div>
    </div>
  );
}
