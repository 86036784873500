// src/components/EventsList.jsx
import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Form, Row, Col, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchEvents, createEvent, deleteEvent } from '../../api/api';

function EventsList({ isBranchAdmin }) {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ title: "", date: new Date(), zoomLink: "" });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    try {
      const eventsData = await fetchEvents();
      setEvents(eventsData);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleNewEventChange = (field, value) => {
    setNewEvent({ ...newEvent, [field]: value });
  };

  const addEvent = async (e) => {
    e.preventDefault();
    try {
      const eventData = await createEvent({
        title: newEvent.title,
        date: newEvent.date.toISOString(),
        zoomLink: newEvent.zoomLink
      });
      setEvents([...events, eventData]);
      setNewEvent({ title: "", date: new Date(), zoomLink: "" });
      setShowModal(false);
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const handleDeleteEvent = async (id) => {
    try {
      await deleteEvent(id);
      setEvents(events.filter(event => event.id !== id));
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  return (
    <Card className="mt-2">
      <Card.Header>
        <Row>
          <Col>Upcoming Events</Col>
          <Col className="text-end">
            {isBranchAdmin && (
              <Button variant="primary" size="sm" onClick={() => setShowModal(true)}>
                <FaPlus /> Add Event
              </Button>
            )}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ maxHeight: '245px', overflowY: 'auto' }}>
        {events.length === 0 ? (
          <p>There are no upcoming events</p>
        ) : (
          <Table bordered>
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Zoom Link</th>
                {isBranchAdmin && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id}>
                  <td>{event.title}</td>
                  <td>{new Date(event.date).toLocaleDateString()}</td>
                  <td>
                    <a href={event.zoomLink} target="_blank" rel="noopener noreferrer">
                      Join Zoom
                    </a>
                  </td>
                  {isBranchAdmin && (
                    <td>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteEvent(event.id)}>
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addEvent}>
            <Form.Group className="mb-3">
              <Form.Label>Event Title</Form.Label>
              <Form.Control
                type="text"
                value={newEvent.title}
                onChange={(e) => handleNewEventChange("title", e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Event Date : </Form.Label>
              <span style={{ marginLeft: '15px' }}></span>
              <DatePicker
                selected={newEvent.date}
                onChange={(date) => handleNewEventChange("date", date)}
                className="form-control"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Zoom Link</Form.Label>
              <Form.Control
                type="text"
                value={newEvent.zoomLink}
                onChange={(e) => handleNewEventChange("zoomLink", e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Event
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default EventsList;