// src/view/protected/AddMemberForm.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { addMemberToCognito, addMemberToDB, assignUserToGroup, getCurrentUserGroups } from './../../api/api';
import { countryCodes } from '../../constants'; // Import the country codes
import './../../AddMemberForm.css'; // Import the CSS file
import { userGroups } from '../../constants';

function AddMemberForm({ show, handleClose, fetchMembers }) {
  const [newMember, setNewMember] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });

  const [selectedGroup, setSelectedGroup] = useState('members'); // Default group
  const [availableGroups, setAvailableGroups] = useState([]);
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });

  const [countryCode, setCountryCode] = useState("+1");

  useEffect(() => {
    const fetchCurrentUserGroups = async () => {
      const currentUserGroups = await getCurrentUserGroups();
      const highestPrecedence = Math.min(...currentUserGroups.map(group => userGroups.find(g => g.name === group.name).precedence));
      const filteredGroups = userGroups.filter(group => group.precedence >= highestPrecedence);
      setAvailableGroups(filteredGroups);
    };
    fetchCurrentUserGroups();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember({
      ...newMember,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ""
    });
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(number);
  };

  const handleAddMember = async () => {
    let isValid = true;
    let newErrors = {};

    const fullPhoneNumber = countryCode + newMember.phoneNumber;

    if (!validatePhoneNumber(fullPhoneNumber)) {
      isValid = false;
      newErrors.phoneNumber = "Invalid phone number. Use international format (e.g., +1234567890).";
    }

    if (isValid) {
      const userId = await addMemberToCognito({ ...newMember, phoneNumber: fullPhoneNumber });
      if (userId) {
        await addMemberToDB(userId, { ...newMember, phoneNumber: fullPhoneNumber });
        await assignUserToGroup(newMember.email, selectedGroup);
        setNewMember({
          email: "",
          firstName: "",
          lastName: "",
          phoneNumber: ""
        });
        handleClose();
        fetchMembers();
      } else {
        alert("User not added to DB.");
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={newMember.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formFirstName" className="mt-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={newMember.firstName}
              onChange={handleInputChange}
              required
            />
            {errors.firstName && <Form.Text className="text-danger">{errors.firstName}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formLastName" className="mt-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={newMember.lastName}
              onChange={handleInputChange}
              required
            />
            {errors.lastName && <Form.Text className="text-danger">{errors.lastName}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formPhoneNumber" className="mt-3">
            <Form.Label>Phone Number</Form.Label>
            <div className="d-flex">
              <div className="country-code-wrapper">
                <Form.Control
                  as="select"
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  className="country-code-dropdown"
                  size="10"
                >
                  {countryCodes.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </Form.Control>
                <div className="selected-code">{countryCode}</div>
              </div>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={newMember.phoneNumber}
                onChange={handleInputChange}
                required
                placeholder="1234567890"
                className="ml-2"
              />
            </div>
            {errors.phoneNumber && <Form.Text className="text-danger">{errors.phoneNumber}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formGroup" className="mt-3">
            <Form.Label>Select Group</Form.Label>
            <Form.Control as="select" value={selectedGroup} onChange={handleGroupChange}>
              {availableGroups.map(group => (
                <option key={group.name} value={group.name}>{group.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddMember}>
          Add Member
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMemberForm;