import React from 'react';
import MenuNav from './Menu';
import Footer from './Footer';
import { View } from '@aws-amplify/ui-react';
import BannerTemplate from './banner/BannerTemplate';

function Contact() {

    const bannerStyle = {
        backgroundImage: "url('assets/img/contact_us_img2.png')",
        backgroundSize: "875px",
        backgroundRepeat: "repeat-x"
    };

    return (
        <main>
            <header>
                <BannerTemplate 
                    bannerStyle={bannerStyle}
                    heading="Contact us"
                    subHeading="ርኸቡና!"/>
            </header>
            <View classNameName="container"> 
                <main className="mb-4">
                <div className="container px-4 px-lg-5">
                    <MenuNav />
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <h5>ኣኣብ እትነብሩሉ ዓዲ፡ ብ ኣካል ወፈያ ንምኽፋል፡ ነዞም ዝስዕቡ ኣባላትና ክትረኽብዎም ትኽእሉ </h5>
                        <h5>To make your contribution in person, you can reach out to the following members.</h5>
                        <hr className="my-4" />
                    </div>
                    <br />
                    <h3>United States of America (USA)</h3>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>   <th scope="col">Telephone</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr><td>Yordanos G/meskel</td>   <td>240 461 0067</td>
                            </tr>
                            <tr><td>Yosief Asghedom</td>  <td>615 812 3717</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <h3>CANADA</h3>
                    <table className="table">
                        <thead>
                            <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>Tesfay Tecle</td>   <td>519 562 9819</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <h3>EUROPE</h3>
                    <table className="table">
                        <thead>
                        <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>  <th scope="col">Country</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr><td>George Tesfazion</td>    <td>31648564575</td>  <td>Holland</td>
                        </tr>
                        <tr><td>Weldu Berhane</td>  <td>491788924556</td>  <td>Germany</td>
                        </tr>
                        <tr><td>Dr kibreab</td> <td>447951572984</td> <td>UK</td>
                        </tr>
                        <tr><td>Mussie Dagnew</td>  <td>4791266615</td>  <td>Norway</td>
                        </tr>
                        <tr><td>Kedawi Mogos</td>   <td>4528680079</td>  <td>Denmark</td>
                        </tr>
                        <tr><td>Zewdi Sereke</td> <td>46735221382</td>  <td>Sweden</td>
                        </tr>
                        </tbody>
                    </table>
                    <br />
                    <h3>ISRAEL</h3>
                    <table className="table">
                        <thead>
                        <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr><td>Berhe Abadi</td>    <td>0545289517</td>
                        </tr>
                        <tr><td>Shishay kidane</td>  <td>972532314112</td>
                        </tr>
                        </tbody>
                    </table>
                    <br />
                    <h3>AFRICA, UGANDA</h3>
                    <table className="table">
                        <thead>
                        <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr><td></td>    <td>256708923173</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </main>
            </View>
            <Footer />
        </main>
    );
};

export default Contact;