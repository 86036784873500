import { useState } from 'react';

export default function Mar_24_2023() {
  const [collapsePRT12, setCollapsePRT12] = useState(false);

  const toggleCollapse = (id) => {
    if (id === "collapsePRT12") {
      setCollapsePRT12(!collapsePRT12);
    }
  };

  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRT12")}
            aria-expanded={collapsePRT12}
            aria-controls="collapsePRT12"
            style={{ cursor: 'pointer' }}
          >
            <h3>ጋዜጣዊ መግለጺ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ብምኽንያት ምቛም ግዝያዊ መሰጋገሪ መንግስቲ ትግራይ</h3>
          </a>
          <div className={`collapse ${collapsePRT12 ? 'show' : ''}`} id="collapsePRT12">
            <article>
              <div>
                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ብመሰረት እቲ ኣብ 2 ሕዳር 2022 ኣብ ፕሪቶርያን ናኣይሮቢን ኣብ ሞንጎ ህዝባዊ ወያኒ ሓርነት ትግራይን ፈደራላዊ ሪፓብሊክ መንግስቲ ኢትዮጵያን ዝተኸተመ ናይ ሰላም ስምምዕ፡ ዛጊት ተበጺሑ ዘሎ ምዕባሌታት ንህዝቢ ትግራይን ፕረሲደንት ግዝያዊ መሰጋገሪ መንግስቲ ትግራይ ኣቶ ጌታቸዉ ረዳን ናይ እንቛዕ ሓጎሰኩም መልእኽቲ የመሓላልፍ።</p>
                <p>በዚ ጀሚርዎ ዘሎ ከይዲ ኸኣ፡ ህዝቢ ትግራይ ድሕነቱን ህላዉነቱን ከውሕስ፡ መጻኢ ዕድሉ ባዕሉ ክውስን፡ ራህዋን ምዕባሌን ብቐጻሊ ከረጋግጽ፡ ሓድነቱ ከስጥም፡ ሰላም ፍትሒን ደሞክራስን ክሰፍን ፈልሲ፡ ተስፍኡ ይገልጽ።</p>
                <p>ኣብ ውሽጢን ወጻኢን ዘሎ ህዝቢ ትግራይ፡ ኣብዚ ዝሓለፈ ክልተ ዓመታት ዘርኣዮ ሓድነት፡ ናይ ቃልሲ ጥበብ፡ ጽንዓትን፡ ተወፋይነትን ንዓለም ዘዛረበ፡ ዘስተምሃረን ዝድነቕን እዩ። ከም ውጽኢቱ ኸኣ፡ ካብቲ ብኣእምሮን ብቓላትን መግለጺ ዘይተረኸቦ ዝወረዶ ከቢድ ኩናት፡ ጽንተት፡ ግፍዒን ዕንወትን፡ ከምቲ ዝተባህለ ሓመድ ልሒሱ ብዘገምታ፡ ኣብ ምትንሳእ ይርከብ።</p>
                <p>ኣብዚ ተኣፋፊ እዋን፡ ቅድሚን ልዕል ኩሉን ጉዳይ ድሕነትን ህላዉነትን ብምስራዕ፡ ህዝቢ ትግራይ ኣካላዊን ማሕበረ ቁጠባዊ ውሕስና የድልዮ። ነዚ ክዉን ንምግባር፡ ትግራይ ዓዲ ሰላም፡ ፍትሒን ደሞክራስን ክትከዉን ናይ ፈልሲ ባህግን ድሌትን እዩ። ትግራይ ነዚ ከብቅዓ ዝኽእል እኹል፡ ታሪኻዊ፡ ልምዳዊን ባህላዊን ሰረት ኣለዋ እዩ። ነዚ ሕጂ ኣጓኒፍዋ ዘሎ ኩነታት፡ ከም ሓደ መንጠሪ ባይታ ተጠቂማ ኸኣ፡ ብሓዱሽ ሓይሊን መንፈስን ክትቅጽሎ ምዃና ዘጠራጥር የልቦን።</p>
                <p>እቲ ሕወሓት፡ ብመሰረት ናይ ፕሪቶርያ ስምምዕ ንገለ ኣካላት ሕብረተሰብ ትግራይ ኣሳቲፋ ኣቑማቶ ዘላ ካቢነ ግዝያዊ መሰጋገሪ መንግስቲ ትግራይ ዝነኣድ እዩ። በዚ ኣጋጣሚ፡ ኣብ ዝመጽእ ወግዓዊ ምርጫ፡ ፖለቲካ ደንበ ትግራይ፡ ኣሳታፊን ሓቛፊን ከምኡውን ነጻን ፍትሓዊን ክኸዉን፡ ፕረሲደንት ግዝያዊ መሰጋገሪ መንግስቲ ትግራይ፡ ኣቶ ጌታቸዉ ረዳ ዝከኣሎ ክገብር ፈልሲ ሰላምን ደሞክራስን ኤርትራ ይላቦ።</p>
                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ካብዚ ዝሓለፈ ኣርባዕተ ዓመታት ኣብ ኢትዮጵያ ዝነበረን ዘሎን ፖለቲካዉን ወተሃደራዉን ኩነታት ኣብ ግምት ብምእታዉ፡ ህዝቢ ትግራይ ካብ ዝኾነ ይኹን እዋን ንላዕሊ፡ ምስ’ቲ ፍትሒ ዝደሊ ዘበለ ህዝቢ ኤርትራ፡ ብፍላይ ከኣ ምስ ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ዘለዎ ምሕዝነትን ሓበራዊ ቃልሲን ከደልድል ይላቦ።</p>
                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ህላወ ህዝቢ ኤርትራ ምስ ጎረባብቲ ሃገራት፡ ብፍላይ ድማ ምስ ህዝቢ ትግራይ፡ ዝተተሓሓዘ ብምዃኑ ኣሚኑ፣ ኤርትራ ኣብ ቀርኒ ኣፍሪቃ ናይ ሰላም፡ ፍትሒን ሓበራዊ ምዕባለን አወንታዊ ተርኣ ንኸተበርክት ንጹር መርገጺ ሒዙ ዝቃለስ ዘሎ ጥምረት እዩ።</p>
                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ ደጊሙ ንህዝቢን ንፕረሲደንት ግዛያዊ መሰጋገሩ መንግስቲ ትግራይ ኣቶ ጌታቸዉ ረዳን እንቛዕ ሓጎሰኩም ይብል።</p>
                <p>ፈልሲ ሰላምን ዲሞክራስን ኤርትራ
                  <br />
                  24 መጋቢት 2023
                </p>
                <p><a href="assets/pdf/press_release_12_tig.pdf">PDF ትግርኛ</a>.</p>
              </div>
            </article>
          </div>
          <p className="post-meta">Posted on Mar 24, 2023</p>
        </div>
      </div>
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          {/* Placeholder for the English version */}
        </div>
      </div>
    </div>
  );
}
