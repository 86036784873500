// src/view/protected/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav, Badge, Alert } from "react-bootstrap";
import '@aws-amplify/ui-react/styles.css';
import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from '@aws-amplify/auth';
import MenuNav from "../Menu";
import ProfileCard from "./ProfileCard";
import EventsList from './EventsList';
import PaymentsComponent from './PaymentsComponent';
import ActivitiesComponent from './TaskTracker';
import VotingManager from './VotingManager';
import './../../Dashboard.css'; 
import { Link, useNavigate } from 'react-router-dom';
import MemberList from './MemberList';
import { getCurrentUserGroups, listUsersInGroup } from './../../api/api';
import { clearCache } from './../../cache';
import GuestList from './GuestList';

function Dashboard({ signOut, user }) {
  const [userAttributes, setUserAttributes] = useState(null);
  const [isBranchAdmin, setIsBranchAdmin] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [activeTab, setActiveTab] = useState('members');
  const [guestCount, setGuestCount] = useState(0);
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadUserAttributes();
  }, []);

  useEffect(() => {
    if (userAttributes) {
      checkUserGroupPrecedence();
    }
    if (isBranchAdmin) {
      loadGuestCount();
    }
  }, [userAttributes, isBranchAdmin, isGuest, guestCount]);

  async function loadUserAttributes() {
    try {
      const userAttribute = await fetchUserAttributes(user);
      setUserAttributes(userAttribute);
    } catch {
      setUserAttributes(null);
    }
  }

  async function checkUserGroupPrecedence() {
    try {
      const groups = await getCurrentUserGroups();
      const group = groups.reduce((lowest, current) => {
        return current.precedence < lowest.precedence ? current : lowest;
      });
      setIsGuest(group.precedence === 5);
      setIsBranchAdmin(group.precedence <= 3);
    } catch (error) {
      console.error('Error checking user group precedence:', error);
      setIsBranchAdmin(false);
    }
  }

  async function loadGuestCount() {
    try {
      const guestUsers = await listUsersInGroup("guests");
      setGuestCount(guestUsers.length);
    } catch (error) {
      console.error('Error fetching guest count:', error);
    }
  }

  const handleSignOut = async () => {
    try {
      await signOut();
      clearCache(); // Clear cache on sign out
      navigate('/home');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Container className="mt-3 dashboard-container">
      <header className="header-container">
        <a className="dashboard-title" href="/home">ፈልሲ ሰላም</a>
        {user ? (
          <Link onClick={handleSignOut} className="nav-link navbar-nav ms-auto text-white" style={{ fontSize: '20px', fontWeight: 'bold', background: 'none', border: 'none' }}> Log Out </Link>
        ) : (
          <Link to="/dashboard" className="nav-link navbar-nav ms-auto text-white" style={{ fontSize: '20px', fontWeight: 'bold' }}> Member <br /> Log In </Link>
        )}
      </header>
      <MenuNav />
      <Row className="justify-content-center">
        <Col md={12}>
          {showWelcomeBanner && isGuest && userAttributes && (
            <Alert 
              variant="info" 
              onClose={() => setShowWelcomeBanner(false)} 
              dismissible
              className="text-center"
              style={{ padding: '10px 20px', fontSize: '14px' }}
            >
              <Alert.Heading>Welcome to Felsi Selam Dashboard, {userAttributes.name}!</Alert.Heading>
              <p>We will contact you soon to register you as a member.</p>
            </Alert>
          )}
        </Col>
        <Col md={6}>
          <ProfileCard userAttributes={userAttributes} />
          <ActivitiesComponent userAttributes={userAttributes} />
        </Col>
        <Col md={6}>
          <EventsList isBranchAdmin={isBranchAdmin} />
          <PaymentsComponent userAttributes={userAttributes} />
        </Col>
        <hr className="my-4" />
        <Col md={12}>
          <VotingManager isBranchAdmin={isBranchAdmin} />
        </Col>
        <hr className="my-4" />
        <Col md={12}>
          {isBranchAdmin && (
            <>
              <Nav variant="tabs" activeKey={activeTab} onSelect={(selectedKey) => setActiveTab(selectedKey)}>
                <Nav.Item>
                  <Nav.Link eventKey="members">Members</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="guests">
                    Guests {guestCount > 0 && <Badge bg="danger">{guestCount}</Badge>}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="tab-content mt-3">
                {activeTab === 'members' && <MemberList userAttributes={userAttributes} />}
                {activeTab === 'guests' && <GuestList userAttributes={userAttributes} setGuestCount={setGuestCount} />}
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withAuthenticator(Dashboard);