import React from 'react';

function May_13_2024() {
  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        <div className="post-preview">
          <a data-bs-toggle="collapse" href="#collapsePRT18" aria-expanded="false" aria-controls="collapsePRT18" style={{ textDecoration: 'none' }} >
            <h3>ናይ ሓዘን መግለጺ</h3>
          </a>
          <div className="collapse" id="collapsePRT18">
            <article>
              <div>
              <p>ብሰንኪቲ ኣብ ኤርትራ ዘሎ ግፍዓዊ ምምሕዳር ብሓፈሽኡ ህዝቢ ኤርትራ፣ ብፍላይ ድማ መንእሰያት ኤርትራ ካብ
                ሃገሮም ተባሪሮም ከም ደቂ ዛግራ ዓለም ሙሉእ ፋሕ ኢሎም ይርከቡ ኣለዉ። ኣብቲ ናይ ስደት ሃገራትውን ኣብ ልዕሊቲ
                መስገደላት ስደት፣ እቲ ናይ ኤርትራ ስርዓት ደድሕሪኦም መጺኡ ሰላም ከሊእዎም ይርከብ። ብዘይካዚ ኣብ ልዕሊ
                ተቓለስቲ መንእሰያት ሕሉፍ ጨቆናን ራዕድን ሽበራን ዘሪኡ ንርእዮ ኣሎና። ከም መቐጸልታ ናይዚ፣ ኣብ ዝሓለፈ ሓሙስ
                ዕለት 09.05.2024፡ ኣብ ሃገረ ኢስራኤል፡ ከተማ ተልኣቪቭ ኣብ ልዕሊ ይብራህ ቀሺ ኣፈወርቂ ዝተባህለ ተቓላሳይ ሓውና
                ኣረመንያዊ መጥቃዕቲ ፈጺሞም ቀቲሎሞ ኣለዉ።</p>
              <p>በዚ መሰረት ድማ ፈልሲ ሰላምን ዲሞክራስን ዝተሰምዖ መሪር ሓዘን ክገልጽ ይደሊ።</p>
              <p>ንመዋቲ መንግስተ ሰማይ፣ ንቤተ ሰቡ ከኣ ጽንዓት ይሃብ።</p>
              <p>ኣብ እስራኤል ዝርከቡ ይኹን፡ ካልኦት ኩላትና ኣባላት ፈልስን ደለይቲ ፍትሒን፣ ፖሊስ ኢስራኤል ነዚ ጉዳይዚ ግቡእ
                መርመራ ገይሩ ንገበናኛታት ኣብ ፍርዲ ከቕርብን መቕጻዕቶም ክረኽቡን ንጽውዕ። ንሕናውን እዚ ጉዳይዚ ግቡእ
                መዐረፊኡ ክረክብ ወዓል ሕደር ከይበልና ክንጽዕር ኢና።</p>
              <p>እዚ ዘሕዝን ኩነትዚ መቐጸልታ ናይቲ ህዝቢ ኤርትራ መዓልታዊ ናብርኡ ኮይኑ ዘሎ መሪር ሓቂ ኢዩ። ምንጪ ናይቲ ከምዚ
                ዝበለናይ ወግሐ ጸብሐ መርድእ ሃለዋት፣ እቲ ኣብ ኤርትራ ዝገዝእ ዘሎ ኣረሜናዊ ስርዓት ኢዩ። እዚ ስርዓትዚ ክሳብ
                ዝቐጸለ ድማ ካብዚ ናይ ሞትን ወረ ሞትን ክዉንነት ክንገላገል ኣይክንክእልን ኢና።</p>
              <p>ስለዚ ሕጂውን ንህጹጽ ለውጢ ስርዓት ንቃለስ!</p>  
              <p>ፈጻሚ ኣካል ፈልሲ ሰላም ዲሞክራስን ኤርትራ
                <br />
                13 ግንቦት 2024
              </p>
              </div>
            </article>
            <p><a href="assets/pdf/press_release_18_tig.pdf">PDF ትግርኛ</a>.</p>
          </div>
          <p className="post-meta">
            Posted on May 13, 2024
          </p>
        </div>
      </div>
      <div className="col-sm-6">
        
        <div className="post-preview">
          {/* <a data-bs-toggle="collapse" href="#collapsePRE18" aria-expanded="false" aria-controls="collapsePRE18">
            <h3></h3>
          </a>
          <div className="collapse" id="collapsePRE18">
            <article>
              <div>
                <p></p>
              </div>
            </article>
            <p><a href="assets/pdf/press_release_18_eng.pdf">PDF English</a>.</p>
          </div>
          <p className="post-meta">
            Posted on May 13, 2024
          </p> */}
        </div>
      </div>
    </div>
 );
};

export default May_13_2024;