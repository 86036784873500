import React from 'react';

function Nov_04_2022() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT10" aria-expanded="false" aria-controls="collapsePRT10" style={{ textDecoration: 'none' }}>
                        <h3>መግለጺ ፈልሲ ሰላምን ዲሞክራስን ኤርትራ፡
                            ኣብ ዝኽሪ መብል ካልኣይ ዓመት ህልቂትን ወራርን ትግራይ</h3>
                    </a>
                    <div className="collapse" id="collapsePRT10">
                        <article> 
                            <div>
                                <p>ፈልሲ ሰላምን ዲሞክራስን ኤርትራ፡ ነቲ ብጥምረት ኢትዮጵያን ኤርትራን ኣብ ልዕሊ ህዝቢ ትግራይ ንክልተ ዓመት ክካይድ ዝጸንሐን ዝካየድ ዘሎን ወራርን ዘስካሕክሕ ህዝባዊ ጽንተትን (ጀኖሳይድ) ብጥብቂ ይኹንን።</p>
                                <p>እቲ ብውልቀ መላኺ ኢሰይያስ ኣፈወርቅን ናይ ኣፍሪቃ ሂትለር ኣቢይ ኣሕመድን ንዝሓለፈ 3 ዓመታት ንህዝቢ ትግራይ መግቢን መድሃኒት፡ ከምኡውን ሰብኣዊ ረድኤትን ንኸያኣቱ ዓጽዮም፡ ህዝቢ ትግራይ ብጥምየትን ብሕማምን ክሃልቕን ጥራይ ዘይኮነ ብስደትን ብኩናት ክጠፍእ ሓደ ሚልዮን ዝኣክል ሰራውት ኣኽቲቶም ብምድሪን ብከበድቲ ብረትን ተወንጨፍትን፡ ብሰማይ ከኣ ብነፈትን ድሮናትን ጨፍጫፍን ኢሰብኣዊ ተግባራት ኣብ ምፍጻም ይርከቡ።</p>
                                <p>ዝኸበርካ ህዝቢ ትግራይ፡ ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ነቲ ኣብ ልዕሊ ፍንጫሕ መሰንገልና ዝኾነ ህዝቢ ትግራይ ዝፍጸም ዘሎ ጆኖሳይድ ብትሪ ንኹንኖ። ምስ ህዝቢ ትግራይ ሓቢርና ክንምክቶ ምዃና ከኣ ንህዝቢ ትግራይ ከነረጋግጸሉ ንፈቱ።</p>
                                <p>ዝኸበርካ ህዝቢ ትግራይ፡ ፋሺስታውያን ወረርቲ ሓይልታት ኢትዮጵያን ኤርትራን ደገፍቶምን፡ ከም ህዝቢ ከምበርክኹኻን ከጽንቱኻን ሺሾ ሰራውትን፡ ብብዝሒ ኣጽዋርን ድሮናትን ብጅግንነትካ መኪትካዮም ኢኻ። ሕጂ ድማ ክልቲኦም ጸላእቲ ነቲ ደማዊ ኩናት ብሕጊን ሕጋዉነትን ኣብ ናይ ሰላም መኣዲ ዘተ ኮፍ ኢሎም ንኽፈትሕዎ ኣገዲድካዮም ኢኻ።</p>
                                <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ነቲ ኣብ ፕሪቶርያ ደቡብ ኣፍሪቃ ብ 2 ሕዳር 2022 ዝተኸተመ ተኹሲ ደው ናይ ምባል ናይ ሰላም ስምምዕ ይድግፍ። ምኽንያቱ ህዝቢ ትግራይ እቶም ወረርቲ ካብ ዘስዕብዎ ሰብእዊን ንዋታዊን ተፈጥራውን ጥፍኣትን ዕንወትን ምፍንቓልን ክትድሕንን ትንፋስ ክትረክብን የኽእለካ እዩ። እቶም ብወገን ኤርትራ፡ ኢትዮጵያን ተገዲዶም ዝሃልቁ ህዝቢታትን ዝባኽን ሃብትን ንብረትን ንምድሓን ተኽእሎ ስለዝፈጥር።</p>
                                <p>ዝኸበርካ ህዝቢ ትግራይ፡ ሰላምን ፍሪኡን ኩሉ ግዜ ጽቡቕን ጥዑምን እዩ። ይኹን እምበር ነቲ ሃገራዊ ሕጊ ብቕንዕና ዘተግብር ኣካል ምስ ዝህሉ እዩ። ታሪኽ ኢሰይያስ ኣፈወርቂን ኣቢይ ኣሕመድን ግና ነዚ ዘረጋግጽ ከምዘይኮነ፡ ንሕና ጥራይ ዘይኮና ዓለም ብዓለሙ ትፈልጦ እያ። ስለዚ ኸኣ ሕጂ እዉን፡ ህላዉነትካን ድሕንነት ህዝብኻን መራሕትኻን ብቐጥታ ይኹን ብተዘዋዋሪ ኣብ ትሕቲ ድነ ውልቀመላኺ ስርዓት ኢሰይያስን ፋሺሽት ኣቢይ ኣሕመድን ከይወድቅ ብትኩርነት ክትርእዮን ክትሕዞን ነተሓሳስብ። ሓድነትካ ኣትሪርካ ብጽንዓትን ጅግንነትን ክትምክት ንላቦ።</p>
                                <p>ሰላምን ራህዋን ንህዝቢ ትግራይን ህዝቢታት ኢትዮጵያን <br />
                                    ህዝቢ ኤርትራን ህዝቢ ቀርኒ ኣፍሪቃ ብሓፈሻ </p>
                                <p>ካብ ፈጻሚት ኮሚቲ ፈልሲ ሰላምን ዲሞክራስን ኤርትራ<br />
                                    4 ሕዳር 2022</p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_10_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Nov 04, 2022
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    {/* Future content */}
                </div>
            </div>
        </div>
    );
}

export default Nov_04_2022;
