import React from 'react';

const FelsiMegazine01 = () => {
  return (
    <div className="card">
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="card-img-top d-block w-100"
              src="../assets/img/magazine001.png"
              alt="Magazine 1"
            />
          </div>
          <div className="carousel-item">
            <img
              className="card-img-top d-block w-100"
              src="../assets/img/magazine002.png"
              alt="Magazine 2"
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      {/* Product details */}
      <div className="card-body p-4">
        <div className="text-center">
          {/* Product name */}
          <h5 className="fw-bolder">Magazine</h5>
          {/* Product price */}
          $35.00
        </div>
      </div>
      {/* Product actions */}
      <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
        <div className="text-center">
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_self">
            <input type="hidden" name="shopping_url" value="https://felsiselam.org/shop" />
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="9WUV7MTUYBWHN" />
            <input type="hidden" name="currency_code" value="USD" />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Add to Cart"
            />
          </form>
          <span>Or</span><br />
          <a href="#" data-bs-toggle="modal" data-bs-target="#zelle-info-modal-magazine">
            <img src="assets/img/Zelle-logo.png" alt="Zelle" width="75" height="35" />
          </a>
        </div>
      </div>

      {/* Zelle Modal */}
      <div
        className="modal fade"
        id="zelle-info-modal-magazine"
        tabIndex="-1"
        aria-labelledby="zelleInfoModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="zelleInfoModalTitle">
                Use Zelle to save transaction fee!
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>
                Recipient Name - <strong>Felsi Selam</strong>
              </p>
              <p>
                Send by e-mail - <strong>EriTigPeace@gmail.com</strong>
              </p>
              <hr className="my-4" />
              <p>Memo - Item Name and your shipping address </p>
              Example - <strong>Felsi Dialog Magazine. 123 Main St. Apt 10B. DC 20003 </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FelsiMegazine01;
