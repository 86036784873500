import React from 'react';

function Nov_03_2024() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT22" aria-expanded="false" aria-controls="collapsePRT22" style={{ textDecoration: 'none' }} >
                        <h3>ቀዳማይ ጸላኢ ልዑላውነት ህዝብናን ሃገርናን፡ ኣብ ኤርትራ ዘሎ ስርዓት ኢዩ።</h3>
                    </a>
                    <div className="collapse" id="collapsePRT22">
                        <article>
                            <div>
                                <p>ኢሰያስ ኣፈወርቂ፡ እቲ ኣብ ሜዳ ዝፈጸሞ ገበናት ከይኣኽሎ፡ ኣብ 1991 ኣብ ኤርትራ ስልጣን ካብ ዝጭብጥ ኣትሒዙ
                                    ክሳብ ሕጂ ኣብ ልዕሊ ህዝብናን ሃገርናን ብጀኖሳይድ ዝግለጽ ገበን ኢዩ ክፍጽም ጸኒሑን ገና ዝፍጽም ዘሎን። ኣብ
                                    ገጊዚኡ ሓደስቲ መደናገሪታትን ህዝቢ ከታልለሉ ዝኽእል፡ ሓንሳብ ብሓምሻይ መስርዕ፡ ሓንሳብ ብባድመ ካልእ ግዜ ድማ
                                    ኢትዮጲያ ከይትበታተን፡ ሕጂውን ብስም ዓሰብን ዝብሉ ምኽንያታት እንዳመሃዘ ከጥፍኣናን ክሳብ ሕጂ ኣብ ልዕሌና ሰይ
                                    ሰይ ክብል ኪኢሉ ኣሎ። ከምዚ እንዳገበረዩ ንነብሱ ከም ተሓላቓይን ተኸላኻልን ልዑላውነት ኤርትራ ገይሩ ክቕርባን
                                    ህዝቢ ከታልልን ዝፍትን። ገለ ተቓውምቲ ኢና ዝብሉ ኣካላት ከይተረፉውን፡ ከምቲ ኣብ ግዚ ወራር ትግራይ
                                    ዝተረኣየ፡«ዓሰብ»፡ «ልዑላውነት» ዝብሉ ዝተፈላለዩ ምኽንያታት ህግደፍ ክደግሙ ክንሰምዕ ጀሚርና ኣሎና። እቲ ቀንድን
                                    ቀዳማይን ጸላኢ ልዑላውነት ህዝብን ሃገርን ኤርትራ ግን እቲ ብውልቀመላኺ ኢሰያስ ኣፈወርቅን ጉጅልኡን ዝምራሕ
                                    ኣካል ኢዩ። ስለዚ ድማ ምስዚ ጉጅለዚ ኰንካ ክሕሎን ክኽበርን ዝኽእል ልዑላውነት ሃገር ይኹን ልዑላውነት ህዝቢ
                                    የለን።</p>
                                <p>ከምቲ ኩላትና ንከታተሎ ዘሎና ኣብ ዞባና ናብ ዝኸፍአ ናዕብን ግጭታትን ከእቱ ዝኽእል ሓደገኛ ኩነት ይቕጽል ኣሎ። እቲ
                                    ቅድሚ ኣስታት ኣርባዕተ ዓመታት ኣብ ልዕሊ ህዝቢ ትግራይ ዝተኻየደ ወራር ሕጂ ናብ ኩሉ መኣዝናት ኢትዮጲያን እቲ
                                    ዞባን ልሒዂ ፡ ተወሰኽቲ ዞባዊ ሓይልታትውን ሓዊሱ ሓደገኛ ኩነታት ይፍጠር ኣሎ። ሃገርና ኤርትራ ድማ ብገባቲ
                                    ኢሰያስ ኣፈወርቂ ኣቢላ ኣብዚ ናይ ጥፍኣትን ኲናትን ኩነት ቀንዲ ተዋሳኢት ኮይና ጸኒሓን ኣላን። እቲ ኣብ ዝሓለፈ
                                    ሰሙናት ኣብ ሞንጎ ኤርትራን ግብጽን ሶማልያን ዝተገበረ ናይ ሰለስተ መንግስታት ሽርክነት ሓደ ካብ ብዙሓት ጭቡጣት
                                    መረኣያታት ኢዩ።</p>
                                <p>ብዘይካዚ ጻሕታር ኲናት ውልቀ መላኺ ዝጨወያ ሃገርና ኤርትራ ኣብ ዞባና ይኹን ኣብ ዓለምለኻዊ መድረኽ ዘተኣማምን
                                    መሻርኽቲ የብላን። ኣብ ውሽጢዚ ዝሓለፈ ሰላሳ ዓመታት ኤርትራ ኣብ ልዕሊ ኩለን ጎረባብታሃገራት ኲናት ከፊታ ኢያ።
                                    ናብ ከም ሶማልን፡ ረፓብሊክ ኮንጎን ደቡብ ሱዳንን ሰራዊታ ልኢኻውን ምስቶም መንግስታት ይኹን ህዝብታት ተዳምያ
                                    ኢያ። ስለዚ ድማ፡ ጎረባብትና ሃገራትን ውድብ ሓድነት ኣፍሪቃን ካልኦት ትካላት ኣፍሪቃን ንኤርትራ ከም ግዝኣተ
                                    ዕግርግር ኢዮም ዝፈልጡዋ። ኣብ ዓለምለኻዊ መዳይውን ጸላእቲ እንዳፈጠረ ኢዩ ክኸይድ ጸኒሑን ዘሎን። ስለዚዩ ድማ
                                    ቀጻልነት ህግደፍ፡ ንህዝብናን ግዝኣታዊ ልዑላውነት ሃገርናን ሓደጋ ዝኸውን።</p>
                                <p>ንሕና ከም ፈልሲ ሰላምን ዲሞክራስን ኤርትራ ኣብ ኤርትራ ናይ ስርዓት ለውጢ (Regime Change) ክግበርን፣ ህግደፍን
                                    ጨፍጨፍቲ ትካላቱን፡ ማለት፡ ፖለቲካውን፡ ወተሃደራውን፡ ጸጥታውን፡ ማሕበራውን ትካላቱ ተኣልዮም፡ ብሓድሽትን ህዝባውያንን
                                    ትካላት ክትክኡ ኢና ንቃለስ ዘሎና። ኣብዚ እነካይዶ ዘሎና ቃልሲ ድማ ብደረጃ ሃገር ምስ ተመሳሳሊ ዕላማታት
                                    ዘለዎም ኣካላት ብሓባር ኰና ንቃለስ ኣሎና። ካብዚ ሓሊፉውን ምስ ኣብ ከባቢና ዘለዉ ህዝብታትን ኣካላትንውን ኣብ
                                    ንጹር መትከላት ዝተሰረተ ዝምድናታት ፈጢርና ምእንቲ ናይ ሓባር ረብሓታት ኣንጻር ናይ ሓባር ጸላኢናን ኩለ መዳያዊ
                                    ቃልሲ ከነካይድ ኢና።</p>
                                <p>ኣብዚ እዋንዚ እምበኣር ኩሉ ዓቕምታ ተቓለስቲ ኣንጻር ቀዳማይ ጸላኢና ኢሰያስ ኣፈወርቅን ስርዓቱንዩ ክቐንዕ ዝግብኦ።
                                    ኣብ ጎኒ ኢሰያስ ኣፈወርቅን ትካላቱን ኰንካ ክሕሎ ዝኽእል ልዑላውነት ህዝቢ ይኹን ልዑላውነት ሃገር የለን። ምኽንያቱ
                                    ቀንዲ ጸላኢ ልዑላውነትና ኣብ ኤርትራ ስልጣን ጨቢጡ እንዳሃለወ፡ ምስኡ ኰንካ ክሕሎን ክኽበርን ዝኽእል
                                    ልዑላውነት ክህሉ ስለዘይክኣል።</p>
                                <p>መጸዋዕታ ንህዝቢ ኤርትራን ኣባላት ሰራዊትን ኣብ ደገ ዝርከቡ ደገፍቲ’ቲ ስርዓትን!</p>
                                <p>ክቡር ህዝቢ ኤርትራ፡ በቶም ኣብ ገጊዚኡ ዝቐርቡልካ ምኽንያታትን ምስምሳትን ከይተታለልካ፡ ነቲ ኣብ ኤርትራ ዘሎ
ስርዓት ወጊድ ክትብሎ ከነተሓሳስበካ ንደሊ። እዚ ስርዓትዚ ብዘይካ ኣብ ጸጽባሕ ደቅኻ ናብ ኲናትን ሞትን ምልኣኽን
ዝተረፉ ድማ ናብ ስደት ሃጽ ኢሎም ክጠፍኡ ምግባርን ሓሊፉ ካልእ ከምጸኣልካ ዝኽእል ዋላ ሓንቲኳ ረብሓ የልቦን።</p>
                                <p>ኣባላት ሰራዊት ኤርትራ እቲ ሰርዓት መደባቱ ንምዕዋት ዝጥቀመልኩምን ንቡር ህይወትኩም ዝተመንጠለኩምን ግዳያት
ኢኹም። እዚ ስርዓትዚ ክሳብ ዝሃለወ ድማ ካብ ሞትን ጥፍኣትን ክትድሕኑ ኣይትኽእሉን ኢኹም። ስለዚ ሕጂውን ናይ
ኢሰያስ ኣፈወርቂ መወዳእታ ዘይብሉ ናይ ኲናትን ሞትን መደባት ክትጻረሩን ነብስኹም ከተድሕኑን ንምሕጸነኩም።
ካብዚ ሓሊፉውን ብረትኩም ኣንጻር እቶም ልዑላውነት ሃገርና ከም ዝድፈርን ህዝብና ናብ ዘይውጻእ ዓዘቕቲ ከም
ዝሸመምን ዝገብርዎ ዘለዉ መራሕትኹም ከተቕንዕዎን ናብ ተቓለስቲ ኣሕዋትኩም ክትጽንበሩን ንጽውዓኩም።</p>
                                <p>ኣብ ደገ ዘሎኹም እሞ ንህግደፍ ትድግፉ ህዝብና ድማ ካብ ዝሓለፈ ተሞክሮታትኩም ተማሂርኩም ነዚ ኣብ ስደት
ከርተት ዘብለና ዘሎ ስርዓት ዝግበር ደገፍ ሙሉእ ብሙሉእ ጠጠው ከተብልዎን ኣብ ጎኒ ተቓለስቲ ደቅኹም ደው
ክትብሉን ንምሕጸነኩም።</p>
                                <p>ኩሉ ዓቕምታትና ኣንጻር ቀዳማይ ጸላኢና ጉጅለ ህግደፍ ይቕናዕ!</p>
                                <p>ፈልሲ ሰላም ዲሞክራስን ኤርትራ
                                    <br />
                                    ሕዳር 03/2024
                                </p>
                            </div>
                        </article>
                        <p>
                            <a href="/press-release/22">PDF ትግርኛ</a>
                        </p>

                    </div>
                    <p className="post-meta">
                        Posted on November 03, 2024
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    {/* If there is an English version available, it can be added here */}
                </div>
            </div>
        </div>
    );
}

export default Nov_03_2024;
