// src/view/protected/EditRoleModal.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { assignUserToGroup, getUserGroups } from './../../api/api';

function EditRoleModal({ show, handleClose, member, refreshMembers, availableGroups }) {
  const [selectedGroup, setSelectedGroup] = useState('');
  const [notify, setNotify] = useState(false);
  const [userLowestPrecedenceGroup, setUserLowestPrecedenceGroup] = useState('');

  useEffect(() => {
    const fetchUserGroups = async () => {
      if (member) {
        try {
          const groups = await getUserGroups(member.email);

          const mappedGroups = groups
            .map(group => availableGroups.find(g => g.name === group))
            .filter(group => group !== undefined);

          if (mappedGroups.length > 0) {
            const lowestPrecedenceGroup = mappedGroups.reduce((prev, curr) => (prev.precedence < curr.precedence ? prev : curr));
            setUserLowestPrecedenceGroup(lowestPrecedenceGroup.name);
          } else {
            setUserLowestPrecedenceGroup('No Group');
          }
        } catch (error) {
          console.error('Error fetching user groups:', error);
        }
      }
    };
    fetchUserGroups();
  }, [member, availableGroups]);

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleNotifyChange = (e) => {
    setNotify(e.target.checked);
  };

  const handleAssignGroup = async () => {
    await assignUserToGroup(member.email, selectedGroup, notify);
    refreshMembers();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formUserLowestPrecedenceGroup">
            <Form.Label>Current Group</Form.Label>
            <div className="form-control-plaintext">
              <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>• {userLowestPrecedenceGroup}</span>
            </div>
          </Form.Group>
          <Form.Group controlId="formGroup" className="mt-3">
            <Form.Label>Select Group</Form.Label>
            <Form.Control as="select" value={selectedGroup} onChange={handleGroupChange}>
              <option value="">Select a group</option>
              {availableGroups.map(group => (
                <option key={group.name} value={group.name}>{group.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formNotify" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Notify Member"
              checked={notify}
              onChange={handleNotifyChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAssignGroup} disabled={!selectedGroup}>
          Assign Group
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditRoleModal;