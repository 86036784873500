import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Form, Alert, Row, Col, Modal } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import EditVotingModal from './EditVotingModal';
import { getCurrentAuthenticatedUser, submitVote, addVoteRecord, checkIfUserHasVoted, updateVoteEvent, createVoteOption, deleteVoteOption, fetchProfilePicture } from '../../api/api';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function VotingComponent({ event, onSave, onDelete, isBranchAdmin }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [votes, setVotes] = useState({});
  const [voted, setVoted] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState(event.options || []);
  const [profilePictures, setProfilePictures] = useState({});
  const chartRef = useRef(null);

  useEffect(() => {
    if (event && event.options && Array.isArray(event.options)) {
      const initialVotes = {};
      event.options.forEach(option => {
        const key = option.type === 'STATEMENT' ? option.value : option.name;
        initialVotes[key] = option.votes ? option.votes.items.length : 0;
      });
      setVotes(initialVotes);
    }
  }, [event]);

  useEffect(() => {
    checkIfUserHasVoted(event.id).then(setVoted);
  }, [event]);

  useEffect(() => {
    const loadProfilePictures = async () => {
      const newProfilePictures = {};
      for (let option of updatedOptions) {
        if (option.type === 'PERSON' && option.email) {
          newProfilePictures[option.email] = await fetchProfilePicture(option.email);
        }
      }
      setProfilePictures(newProfilePictures);
    };

    loadProfilePictures();
  }, [updatedOptions]);

  const handleVote = () => {
    if (selectedOption) {
      setShowConfirmation(true);
    }
  };

  const submitVoteHandler = async () => {
    const currentUser = await getCurrentAuthenticatedUser();
    const selectedOptionObject = event.options.find(
      (option) => (option.type === 'STATEMENT' ? option.value : option.name) === selectedOption
    );

    try {
      await submitVote(currentUser.userId, selectedOptionObject.id);
    } catch (error) {
      try {
        console.log("Vote submitted by - " + error.data.createVote?.userId);
      } catch (error) {
        if (error.errors[0].errorType === "Unauthorized") {
          alert("Voting failed because you're not authorized to vote in this event.");
          setShowConfirmation(false);
        } else {
          alert("Voting Failed. Something went wrong. Please try again or contact us.");
          setShowConfirmation(false);
        }
      }
    }

    try {
      await addVoteRecord(currentUser.userId, event.id);
      setVoted(true);
      setShowConfirmation(false);
      setVotes((prevVotes) => ({
        ...prevVotes,
        [selectedOption]: (prevVotes[selectedOption] || 0) + 1
      }));
    } catch (error) {
      if (error.errors[0].errorType === "Unauthorized") {
        alert("Voting failed because you're not authorized to vote in this event.");
        setShowConfirmation(false);
      } else {
        alert("Voting Failed. Something went wrong. Please try again or contact us.");
        setShowConfirmation(false);
      }
    }
  };

  const handleSave = async (updatedTopic, updatedDescription, updatedOptions, updatedDeadline, updatedHideResults, deletedOptionIds) => {
    const updatedEvent = {
      ...event,
      topic: updatedTopic,
      description: updatedDescription,
      deadline: updatedDeadline,
      hideResults: updatedHideResults,
    };

    try {
      await updateVoteEvent(updatedEvent);

      for (let option of updatedOptions) {
        if (!option.id) {
          await createVoteOption({
            type: option.type,
            value: option.value,
            name: option.name,
            email: option.email,
            votingEventID: updatedEvent.id
          });
        }
      }

      for (let optionId of deletedOptionIds) {
        await deleteVoteOption(optionId);
      }

      onSave(updatedEvent, deletedOptionIds);
      const newVotes = {};
      updatedOptions.forEach((option) => {
        const key = option.type === 'STATEMENT' ? option.value : option.name;
        newVotes[key] = votes[key] || 0;
      });
      setVotes(newVotes);
      setUpdatedOptions(updatedOptions);
      setShowEditModal(false);
    } catch (error) {
      console.error("Error saving voting event:", error);
      alert("Saving failed. Please try again or contact us.");
    }
  };

  if (!event || !event.options || !Array.isArray(event.options)) {
    return null;
  }

  const { id, topic, description, deadline, hideResults } = event;
  const isVotingOpen = new Date() <= new Date(deadline);

  const data = {
    labels: updatedOptions.map((option) => (option.type === 'STATEMENT' ? option.value : option.name)),
    datasets: [
      {
        label: 'Votes',
        data: updatedOptions.map((option) => votes[option.type === 'STATEMENT' ? option.value : option.name] || 0),
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)'],
      },
    ],
  };

  const optionsConfig = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const getOptionLabel = (optionValue) => {
    const option = updatedOptions.find((opt) => (opt.type === 'STATEMENT' ? opt.value : opt.name) === optionValue);
    return option ? (option.type === 'STATEMENT' ? option.value : `${option.name}`) : '';
  };

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        {topic}
        {isBranchAdmin && <div>
          <Button variant="secondary" size="sm" onClick={() => setShowEditModal(true)} className="me-2">
            Edit
          </Button>
          <Button variant="danger" size="sm" onClick={() => onDelete(event.id)}>
            Delete
          </Button>
        </div>}
      </Card.Header>
      <Card.Body>
        <Card.Text className="my-2">
          <Row className="justify-content-center">
            <Col md={8}>{description}</Col>
            <Col md={4} className="my-2">
              <strong>Voting Deadline: <br /></strong> {new Date(deadline).toDateString()}
            </Col>
          </Row>
        </Card.Text>
        <Form>
          <div className="border p-3 mb-3">
            <Row>
              {updatedOptions.map((option, index) => (
                <Col md={6}>
                  <div key={index} className="d-flex align-items-center mb-2">
                    {option.type === 'PERSON' && (
                      <img
                        src={profilePictures[option.email] || 'assets/img/avatar-placeholder.jpg'}
                        alt="Profile"
                        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
                      />
                    )}
                    <Form.Check
                      type="radio"
                      label={option.type === 'STATEMENT' ? option.value : option.name}
                      name={`voteOptions-${id}`}
                      value={option.type === 'STATEMENT' ? option.value : option.name}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      disabled={!isVotingOpen || voted}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center">
              <Col md={4}>
              <Button variant="primary" className="mt-3" onClick={handleVote} disabled={!isVotingOpen || voted}>
                Vote
              </Button>
              </Col>
            </Row>
          </div>
        </Form>
        {voted && <Alert variant="success" className="mt-3">Thank you for voting!</Alert>}
        {!isVotingOpen && <Alert variant="warning" className="mt-3">Voting is closed.</Alert>}
        {!hideResults && (
          <div className="border p-3 mt-3">
            <Bar data={data} options={optionsConfig} ref={chartRef} />
          </div>
        )}
      </Card.Body>
      <EditVotingModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        onSave={handleSave}
        initialTopic={topic}
        initialDescription={description}
        initialOptions={updatedOptions}
        initialDeadline={deadline}
        initialHideResults={hideResults}
        profilePictures={profilePictures}  // Pass profilePictures as prop
      />
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Vote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to vote for "{getOptionLabel(selectedOption)}"? You can't change your vote later.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitVoteHandler}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default VotingComponent;