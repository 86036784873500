import React from 'react';
import { Container } from "react-bootstrap";
import Footer from './Footer';

const PrivacyPolicy = () => {
    return (
        <Container className="mt-3 dashboard-container">
            <header className="header-container">
                
            </header>
            <div className="privacy-policy">
                <h1>Privacy Policy</h1>
                <p>Last updated: August 03, 2024</p>

                <h2>Introduction</h2>
                <p>
                    Welcome to Felsiselam. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at support@felsiselam.org.
                </p>

                <h2>Information We Collect</h2>
                <p>
                    We collect personal information that you voluntarily provide to us when registering at the website, expressing an interest in obtaining information about us or our products and services, when participating in activities on the website, or otherwise contacting us.
                </p>

                <h2>How We Use Your Information</h2>
                <p>We use the information we collect in the following ways:</p>
                <ul>
                    <li>To facilitate account creation and logon process</li>
                    <li>To send administrative information to you</li>
                    <li>To fulfill and manage your orders</li>
                    <li>To deliver and facilitate delivery of services to the user</li>
                    <li>To respond to user inquiries/offer support to users</li>
                    <li>To send you marketing and promotional communications</li>
                </ul>

                <h2>Sharing Your Information</h2>
                <p>
                    We may process or share your data that we hold based on the following legal basis:
                    <ul>
                        <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
                        <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
                        <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
                    </ul>
                </p>

                <h2>Your Privacy Rights</h2>
                <p>
                    You may review, change, or terminate your account at any time. If you are a resident in the EEA or UK, you have specific rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure, (iii) to restrict the processing of your personal information, and (iv) if applicable, to data portability.
                </p>

                <h2>Contact Us</h2>
                <p>If you have questions or comments about this notice, you may contact us at join.felsiselam@gmail.com</p>
            </div>
            <Footer />
        </Container>
    );
};

export default PrivacyPolicy;
