import React from 'react';

function Sep_04_2024() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT20" aria-expanded="false" aria-controls="collapsePRT20" style={{ textDecoration: 'none' }} >
                        <h3>መግለጺ ብዛዕባ ኣብ ትግራይ ዝርአ ዘሎ ፖለቲካዊ ኩነታት</h3>
                    </a>
                    <div className="collapse" id="collapsePRT20">
                        <article>
                            <div>
                                <p>ህዝቢ ትግራይ ብፍላይ ካብ ምጅማርን ምክያድን ናይቲ ኣብ 2020 ናይ ጽንተት ኲናት ኣብ ቀጻሊ ናይ
                                    ዕጽዋን ከበባን ሃለዋትን፣ ኣብ ናይ ጽባሕ ኣረመናዊ ኲናት ኩለመዳያዊ ሳዕቤናትን ይርከብ ኣሎ። ኣብ
                                    ርእሲቲ ብሰላሕታ ክጸንት ዝግበር ዘሎ ዕጽዋን ከበባን፣ ኣገደስትን ዓበይትን ግዝኣታቱ ኣብ ትሕቲ ወረርቲ
                                    ይርከቡ ኣለዉ። ብሚልዮናት ዝቑጸር ህዝቢ ድማ ካብ መረበቱ ተፈናቒሉ ኣብ ቀጻሊ ናይ ሰንፈላልን
                                    ጭንቅን መከራን ዘስካሕክሕ ኣተሃላልዋ ይርከብ ኣሎ። ሕጂ ድማ ኣብ ሞንጎ ፖለትከኛታቱ ምውጣጥ
                                    ይርአ ኣሎ። ስለዚ ንሕና ከም ፈልሲ እቲ ኣብ ትግራይ ዘግጥም ዘሎ ኩነታት ሓደ ክባቶም ኣገደስቲ
                                    ጉዳያትና ስለዝኾኑ ነዚ መግለጺዚ ከምውጽእ ኣገዳሲ ኮይኑ ረኺብናዮ።</p>
                                <p>ቅድሚ ኹሉን ልዕሊ ኹሉን ግን፣ ዝኾነ ነገር ክግበር ዝከኣል ግዝኣትካ ምስ ዝረጋገጽ ስለዝኾነ ብመሰረት
                                    ውዕል ፕረቶሪያ ምምላስ ልዑላዊ ግዝኣት ትግራይ ቅድሚ ኩሉ ነገራት ተሰሪዑ ክስረሓሉ ይግባእ። ኩሎም
                                    ኣካላት ትግራይ ኣብዚ ነጥብዚ ዓሲሎም ብንጥፈት ክቃለሱሉን ከረጋግጽዎን የድሊ። እዚ ምስ ዝትግበር
                                    ተመዛበልቲ ናብ ቦትኣኦ ክምለሱን ውሑስ ብዝኾነ መንገዲ መዓልታዊ ህይወቶም ክመርሑ ኣብ ርእሲ
                                    ምኽኣሎም ኣብ ትሕትቲግዚያዊ ምምሕዳር ትግራይ ኣብቲ ዞባታት ብህዝባዊ ተሳትፎ ኣብ ኩሎም
                                    ጽፍሕታት ግዚያዊ ምምሕዳራት ክምስረት ይኽእል።</p>

                                <p>1. ኣብ ሞጎኹም ዘሎ ምውጣጥን ምትህልላኻን ብሓባት ፍታሕ ክትረኽቡሉ ንላቦ።</p>
                                <p>2. ሕጂ መሰረታዊ ሽግር ህዝቢ ትግራይ ጉዳይ ዕጽዋን ከበባን ስለዝኾነ ካብዚ ሰላሕታዊ ጽንተት
                                    ንምድሓን ኩሉ ዓቕምታት ኣተሓባቢሩ ክሰርሕ ንምሕጸን።</p>
                                <p>3. ሎሚ ህዝቢ ትግራይ ንዳግማይ ኲናት ክጸውር ዝኽእል ማእገር ስለዘይብሉ ኩሎም ናይ
                                    ሰላም ኣማራጺታት ጸንቂቕካ ምጥቃም ከም ዘድሊ ነዘኻኽር።</p>
                                <p>4.ኣብ ሞንጎኦም ዘሎ ምውጣጥ ብዘይ ዝኾነ ናይ ካብ ትግራይ ወጻኢ ዝኾነ ሓይሊ
                                    ምትእትታው ንምፍታሕክሰማምዑ ኣጥቢቕና ንላቦ። ብፍላይ ምትእትታው ውልቀ ምልካዊ
                                    ስርዓት ኤርትራ ንቃልሲ ሕዝቢ ኤርትራ ዝሃስን፣ ሽግርን መከራን ህዝቢ ትግራይ ናብ ዝበኣሰ
                                    ኩነት ክኣቱ ጥራሕ ዝገብር ምዃኑ ነተሓሳስብ።</p>
                                <p>5. ጉዳይ ህዝቢ ትግራይ ንኹሎም ፖለቲካውን ማሕበራውን ኣካላት ዝምልከት ዓቢ ጉዳይ
                                    ኢዩ። ስለዚ ድማ ክሳብ ሕጂ ኣብቲ ምምሕዳር ዘይሳተፉ ዘለዉ ኣካልትውን ዘካተተ ሰፊሕ
                                    ዝመሰረቱ ሓቛፊ ምምሕዳር ምግባር ምምስራት እዋናዊ ዕማም ምዃኑ ከነዘኻኽር ንደሊ።</p>
                                <p>6. ጸጥታዊ ትካላት ትግራይ ክሳብ ሕጂ ዘርእይዎ ዘለዉ ሻራ ናይ ዘይምውሳድ መርገጽ
                                    ነመስግንን ነተባብዕን። ሕጂውን ኣብ ሞንጎ ፖለቲካውያን ኣካላት ዝርአ ዘሎ ምፍልላይ ኣብ
                                    ምፍታሕን ጸጥታዊ ርግኣት ህዝቢ ትግራይ ኣብ ምርግጋጽን ታሪኻዊ ሓላፍነቶም ብውሕለት
                                    ከሳልጡ ዝኽእሉ ምዃኖም ዘሎና እምነት ንገልጽ።</p>
                                <p>7. ኣብዚ እዋንዚ መንእሰያት ትግራይ ብብዝሒ ይስደዱ ኣለዉ። እዚ ሓደገኛ ተርእዮዚ
                                    ምስ ብዙሓት ካልኦት ነገራት ዝተኣሳሰርኳ እንተኾነ ግቡእ ቆላሕታ ክውሃቦ ዘድሊ ምዃኑ
                                    ከነተሓሳስብ ንደሊ።</p>
                                <p>ኣብ መወዳእታ ወረጃ ህዝቢ ትግራይን ፖለቲከኛታት ትግራይ ነዞም ክባና ካብ ፈተውቶም
                                    ዝቐርብ ዘሎ መግለጺ ብኣዎንታ ርእዮም ሓድሽ ናይ ምትሕብባርን መሰረታዊ ተደላይነታት ናይ
                                    ምምላስን ዕማማቶም ከሳልጡ ዘሎና እምነትን ተስፋን ክንገልጽ ንደሊ።</p>
                                <p>ዓወት ንህዝቢ ኤርትራ!
                                    <br />
                                    ዓውት ንህዝቢ ትግራይ!
                                    <br />
                                    ምሕዝነት ህዝቢ ኤርትራን ህዝቢ ትግራይን ይደልድል!
                                    <br />
                                    ፈልሲ ሰላም ዲሞክራስን ኤርትራ
                                    <br />
                                    04.09.2024
                                </p>
                            </div>
                        </article>
                        <p><a href={`${process.env.PUBLIC_URL}/assets/pdf/press_release_20_tig.pdf`} target="_blank">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on September 04, 2024
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    {/* If there is an English version available, it can be added here */}
                </div>
            </div>
        </div>
    );
}

export default Sep_04_2024;
