import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card, Modal, Form } from 'react-bootstrap';
import VotingComponent from './VotingComponent';
import EditVotingModal from './EditVotingModal';
import { v4 as uuidv4 } from 'uuid';
import { fetchVotingEvents, createVotingEventWithOptions, updateVoteEvent, deleteVoteEventWithOptionsAndVotes, deleteVoteOption } from './../../api/api';

function VotingManager({ isBranchAdmin }) {
  const [votingEvents, setVotingEvents] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loadExpiredEvents, setLoadExpiredEvents] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newEvent, setNewEvent] = useState(null);
  const [eventToDelete, setEventToDelete] = useState(null);

  useEffect(() => {
    fetchVotingEvents(loadExpiredEvents).then(setVotingEvents);
  }, [isBranchAdmin, loadExpiredEvents]);

  const addVotingEvent = () => {
    setNewEvent({
      id: uuidv4(),
      topic: '',
      description: '',
      deadline: new Date().toISOString(),
      hideResults: false,
      options: []
    });
    setShowEditModal(true);
  };

  const saveNewEvent = async (topic, description, options, deadline, hideResults) => {
    await createVotingEventWithOptions({ topic, description, deadline, hideResults, options });
    setVotingEvents(await fetchVotingEvents(loadExpiredEvents));
    setNewEvent(null);
    setShowEditModal(false);
  };

  const updateExistingVoteEvent = async (updatedEvent, deletedOptionIds) => {
    await updateVoteEvent(updatedEvent);
    for (const optionId of deletedOptionIds) {
      await deleteVoteOption(optionId);
    }
    setVotingEvents((prevEvents) =>
      prevEvents.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
    );
  };

  const confirmDeleteEvent = (eventId) => {
    const event = votingEvents.find(event => event.id === eventId);
    setEventToDelete(event);
    setShowDeleteModal(true);
  };

  const handleDeleteEvent = async () => {
    if (eventToDelete) {
      await deleteVoteEventWithOptionsAndVotes(eventToDelete.id);
      setVotingEvents(await fetchVotingEvents(loadExpiredEvents));
      setShowDeleteModal(false);
      setEventToDelete(null);
    }
  };

  const handleShowClosedEventsChange = (event) => {
    setLoadExpiredEvents(event.target.checked);
  };

  return (
    <>
      {isBranchAdmin && <div className="justify-content-center text-center mb-3">
        <Button variant="primary" onClick={addVotingEvent}>
          Add Voting Event
        </Button>
        <br />
        <br />
        <Form.Check
          type="checkbox"
          label="Show All Closed Events"
          className="d-inline-block ml-3"
          checked={loadExpiredEvents}
          onChange={handleShowClosedEventsChange}
        />
      </div>}
      <Card className="mt-4">
        <Row className="justify-content-center">
          {votingEvents.map((event) => (
            <Col md={6} key={event.id} className="my-3">
              <VotingComponent
                event={event}
                onSave={updateExistingVoteEvent}
                onDelete={confirmDeleteEvent}
                isBranchAdmin={isBranchAdmin}
              />
            </Col>
          ))}
        </Row>
        {newEvent && (
          <EditVotingModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            onSave={saveNewEvent}
            initialTopic={newEvent.topic}
            initialDescription={newEvent.description}
            initialOptions={newEvent.options}
            initialDeadline={newEvent.deadline}
            initialHideResults={newEvent.hideResults}
          />
        )}
        {eventToDelete && (
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete the Voting Event with Topic "{eventToDelete.topic}"?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDeleteEvent}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Card>
    </>
  );
}

export default VotingManager;