import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="border-top">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <i className="bi bi-envelope-fill"></i>
                        <ul className="list-inline text-center">
                            <li className="list-inline-item">
                                <a href="mailto:felsiselam@gmail.com">
                                    <span className="fa-stack fa-lg">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-solid fa-envelope fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                            </li>
                            {/* <li className="list-inline-item">
                                <a href="#!">
                                    <span className="fa-stack fa-lg">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-twitter fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#!">
                                    <span className="fa-stack fa-lg">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                            </li> */}
                            <li className="list-inline-item">
                                <a href="/shop">
                                    <span className="fa-stack fa-lg">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-solid fa fa-shopping-cart fa-stack-1x fa-inverse"></i>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 small text-center text-muted fst-italic"><Link to="/privacy-policy">Privacy Policy</Link></div>
                    <div className="col-lg-4 small text-center text-muted fst-italic">Copyright &copy; felsiselam.org 2022 </div>
                    <div className="col-lg-4 small text-center text-muted fst-italic"><Link to="/terms-and-conditions">Terms and Conditions</Link></div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;