// src/view/protected/TaskTracker.jsx
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col } from "react-bootstrap";
import { fetchTasksForUser, updateTaskStatus, getCurrentAuthenticatedUser } from '../../api/api';
import { getStatusColor } from '../../constants';

function TaskTracker() {
  const [assignments, setAssignments] = useState([]);
  const [expandedAssignmentId, setExpandedAssignmentId] = useState(null);

  useEffect(() => {
    loadTasks();
  }, []);

  const loadTasks = async () => {
    try {
      const user = await getCurrentAuthenticatedUser();
      const tasks = await fetchTasksForUser(user.userId);
      setAssignments(tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleStatusChange = async (id, status) => {
    try {
      await updateTaskStatus(id, status);
      setAssignments(assignments.map(assignment => assignment.id === id ? { ...assignment, status } : assignment));
    } catch (error) {
      try {
        console.log("Task updated by - " + error.data.updateTask?.userID);
        setAssignments(assignments.map(assignment => assignment.id === id ? { ...assignment, status } : assignment));
      } catch (error) {
        console.error('Error updating task status:', error);
      }
    }
  };

  const toggleExpand = (id) => {
    setExpandedAssignmentId(expandedAssignmentId === id ? null : id);
  };

  return (
    <Card className="mt-4">
      <Card.Header>
        <Row>
          <Col>Your Assignments</Col>
          <Col className="text-end"><span style={{ marginRight: '50px' }}>Status</span></Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ maxHeight: '250px', overflowY: 'auto' }}>
        {assignments.length > 0 ? (
          assignments.map((assignment) => (
            <Card key={assignment.id} className="mb-3">
              <Card.Header
                onClick={() => toggleExpand(assignment.id)}
                style={{ cursor: 'pointer', backgroundColor: getStatusColor(assignment.status) }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>{assignment.title}</div>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Form.Select
                      value={assignment.status}
                      onChange={(e) => handleStatusChange(assignment.id, e.target.value)}
                    >
                      <option value="ASSIGNED">ASSIGNED</option>
                      <option value="DECLINED">DECLINED</option>
                      <option value="ACCEPTED">ACCEPTED</option>
                      <option value="DONE">DONE</option>
                    </Form.Select>
                  </div>
                </div>
              </Card.Header>
              {expandedAssignmentId === assignment.id && (
                <Card.Body style={{ backgroundColor: getStatusColor(assignment.status) }}>
                  <p><strong>Assigned By:</strong> {assignment.assignedBy}</p>
                  <p><strong>Due Date:</strong> {new Date(assignment.dueDate).toLocaleDateString()}</p>
                  <p><strong>Description:</strong> {assignment.description}</p>
                </Card.Body>
              )}
            </Card>
          ))
        ) : (
          <p>There are no tasks assigned to you.</p>
        )}
      </Card.Body>
    </Card>
  );
}

export default TaskTracker;