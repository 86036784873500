import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createTaskForMember, fetchTasksForUser, deleteTaskById, sendTaskNotification } from '../../api/api';
import { FaTrash } from 'react-icons/fa';
import { getStatusColor } from '../../constants';
import './../../AssignTaskModal.css';

function AssignTaskModal({ show, handleClose, member, refreshMembers, currentUser }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [memberTasks, setMemberTasks] = useState([]);
  const [notify, setNotify] = useState(false);

  useEffect(() => {
    if (member) {
      loadMemberTasks();
    }
  }, [member]);

  const loadMemberTasks = async () => {
    const tasks = await fetchTasksForUser(member.id);
    setMemberTasks(tasks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createTaskForMember({
        title,
        description,
        dueDate: dueDate.toISOString(),
        assignedBy: `${currentUser.firstName} ${currentUser.lastName}`,
        userID: member.id
      });
    } catch (error) {
      try {
        console.log("Task created for - " + error.data.createTask?.userID);
      } catch (error) {
        if (error.errors[0].errorType === "Unauthorized") {
          alert("Failed to create task because you're not authorized to create task.");
          handleClose();
        } else {
          alert("Failed to create task. Something went wrong. Please try again or contact us.");
          handleClose();
        }
      }
    }

    try {
      if (notify) {
        await sendTaskNotification(member.email, title, dueDate);
      }

      refreshMembers();
      handleClose();
    } catch (error) {
      console.error('Error notifying member:', error);
      alert('Failed to notify member.');
    }
  };

  const handleDeleteTask = async (taskId, taskTitle) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${taskTitle}?`);
    if (confirmDelete) {
      try {
        await deleteTaskById(taskId);
        loadMemberTasks(); // Refresh the task list
      } catch (error) {
        console.error('Error deleting task:', error);
        alert('Failed to delete task.');
      }
    }
  };

  const handleNotifyChange = (e) => {
    setNotify(e.target.checked);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Task to {member.firstName} {member.lastName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Due Date :</Form.Label>
            <span style={{ marginLeft: '15px' }}></span>
            <DatePicker
              selected={dueDate}
              onChange={(date) => setDueDate(date)}
              showTimeSelect
              dateFormat="Pp"
              className="form-control"
              required
            />
          </Form.Group>
          <Form.Group controlId="formNotify" className="mb-3">
            <Form.Check
              type="checkbox"
              label="Notify Member"
              checked={notify}
              onChange={handleNotifyChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Assign Task
          </Button>
        </Form>
        <hr />
        <h5>Current Tasks for {member.firstName}</h5>
        <div className="task-list">
          {memberTasks.length > 0 ? (
            memberTasks.map((task) => (
              <div key={task.id} className="task-row" style={{ backgroundColor: getStatusColor(task.status) }}>
                <span className="task-title">{task.title}</span>
                <span className="task-due-date">{new Date(task.dueDate).toLocaleDateString()}</span>
                <span className="task-status">{task.status}</span>
                <Button variant="link" onClick={() => handleDeleteTask(task.id, task.title)}>
                  <FaTrash />
                </Button>
              </div>
            ))
          ) : (
            <p>No tasks assigned.</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AssignTaskModal;