import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchMembers, loadProfilePicture } from '../../api/api';
import { FaTimes, FaPlus, FaUser } from 'react-icons/fa';

function EditVotingModal({ show, onHide, onSave, initialTopic, initialDescription, initialOptions, initialDeadline, initialHideResults, profilePictures }) {
  const [topic, setTopic] = useState(initialTopic);
  const [description, setDescription] = useState(initialDescription);
  const [options, setOptions] = useState(initialOptions.length > 0 ? initialOptions : []);
  const [deadline, setDeadline] = useState(new Date(initialDeadline));
  const [hideResults, setHideResults] = useState(initialHideResults);
  const [validated, setValidated] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedOptionType, setSelectedOptionType] = useState('STATEMENT');
  const [statement, setStatement] = useState('');
  const [deletedOptionIds, setDeletedOptionIds] = useState([]);
  const [loadedProfilePictures, setLoadedProfilePictures] = useState(profilePictures || {});

  useEffect(() => {
    validateOptions();
    loadMembers();
  }, []);

  useEffect(() => {
    validateOptions(options);
  }, [options]);

  useEffect(() => {
    const fetchMissingProfilePictures = async () => {
      const newProfilePictures = { ...loadedProfilePictures };

      for (const option of options) {
        if (option.type === 'PERSON' && option.email && !newProfilePictures[option.email]) {
          const profilePic = await loadProfilePicture(option.email);
          newProfilePictures[option.email] = profilePic;
        }
      }

      setLoadedProfilePictures(newProfilePictures);
    };

    fetchMissingProfilePictures();
  }, [options]);

  const loadMembers = async () => {
    const membersData = await fetchMembers();
    setMembers(membersData);
  };

  const handleAddOption = async () => {
    if (selectedOptionType === 'STATEMENT' && statement.trim() !== '') {
      setOptions([...options, { type: 'STATEMENT', value: statement }]);
      setStatement('');
    } else if (selectedOptionType === 'PERSON' && searchQuery.trim() !== '') {
      if (`${selectedMember.firstName.toLowerCase()} ${selectedMember.lastName.toLowerCase()}` === searchQuery.toLowerCase()) {
        const profilePic = await loadProfilePicture(selectedMember.email);
        setOptions([...options, { type: 'PERSON', name: `${selectedMember.firstName} ${selectedMember.lastName}`, email: selectedMember.email, profilePic }]);
        setSearchQuery('');
        setFilteredMembers([]);
        setLoadedProfilePictures({ ...loadedProfilePictures, [selectedMember.email]: profilePic });
      }
    }
  };

  const handleRemoveOption = (index, optionId) => {
    if (optionId) {
      setDeletedOptionIds([...deletedOptionIds, optionId]);
    }
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    validateOptions(updatedOptions);
  };

  const validateOptions = (opts = options) => {
    const valid = opts.length >= 2;
    setValidated(valid);
  };

  const handleSave = () => {
    if (validated) {
      onSave(topic, description, options, deadline, hideResults, deletedOptionIds, loadedProfilePictures);
    }
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.trim() === '') {
      setFilteredMembers([]);
    } else {
      setFilteredMembers(members.filter(member =>
        `${member.firstName.toLowerCase()} ${member.lastName.toLowerCase()}`.includes(query.toLowerCase())
      ));
    }
  };

  const handleMemberSelect = (member) => {
    setSearchQuery(`${member.firstName} ${member.lastName}`);
    setSelectedMember(member);
    setFilteredMembers([]);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Voting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Topic</Form.Label>
              <Form.Control
                type="text"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Deadline :</Form.Label>
              <span style={{ marginLeft: '15px' }}></span>
              <DatePicker
                selected={deadline}
                onChange={(date) => setDeadline(date)}
                className="form-control"
                showTimeSelect
                dateFormat="Pp"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Hide Results"
                checked={hideResults}
                onChange={(e) => setHideResults(e.target.checked)}
              />
            </Form.Group>
            <hr />
            <Form.Group className="mb-3 text-center">
              <Form.Label>Add Options</Form.Label>
              <div className="mb-2 d-flex justify-content-center">
                <Form.Check
                  inline
                  type="radio"
                  label="Statement"
                  name="optionType"
                  value="STATEMENT"
                  checked={selectedOptionType === 'STATEMENT'}
                  onChange={() => setSelectedOptionType('STATEMENT')}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Person"
                  name="optionType"
                  value="PERSON"
                  checked={selectedOptionType === 'PERSON'}
                  onChange={() => setSelectedOptionType('PERSON')}
                />
              </div>
              {selectedOptionType === 'STATEMENT' ? (
                <div className="d-flex mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter statement"
                    value={statement}
                    onChange={(e) => setStatement(e.target.value)}
                    className="me-2"
                  />
                  <Button variant="outline-primary" onClick={handleAddOption}>
                    <FaPlus />
                  </Button>
                </div>
              ) : (
                <div className="mb-3">
                  <div className="d-flex">
                    <Form.Control
                      type="text"
                      placeholder="Search Member"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="me-2"
                    />
                    <Button variant="outline-primary" onClick={handleAddOption}>
                      + <FaUser />
                    </Button>
                  </div>
                  {filteredMembers.length > 0 && (
                    <ul className="list-group mt-2">
                      {filteredMembers.map((member) => (
                        <li
                          key={member.email}
                          className="list-group-item list-group-item-action"
                          onClick={() => handleMemberSelect(member)}
                        >
                          {member.firstName} {member.lastName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </Form.Group>
            <hr />
            <Form.Group className="mb-3">
              <Form.Label>Options</Form.Label>
              {options.length > 0 ? (
                options.map((option, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    {option.type === 'PERSON' && (
                      <img
                        src={loadedProfilePictures[option.email] || 'assets/img/avatar-placeholder.jpg'}
                        alt="Profile"
                        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
                      />
                    )}
                    <Form.Control
                      type="text"
                      value={option.type === 'STATEMENT' ? option.value : option.name}
                      readOnly
                      className="me-2"
                    />
                    <Button variant="link" onClick={() => handleRemoveOption(index, option.id)} className="text-danger p-0">
                      <FaTimes />
                    </Button>
                  </div>
                ))
              ) : (
                <p>No options added yet.</p>
              )}
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={!validated}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditVotingModal;