import React from 'react';
import MenuNav from './Menu';
import Footer from './Footer';
import { View } from '@aws-amplify/ui-react';
import BannerTemplate from './banner/BannerTemplate';

function Support() {

    const bannerStyle = {
        backgroundImage: "url('../assets/img/contact_us_img2.png')",
        backgroundSize: "875px",
        backgroundRepeat: "repeat-x"
    };

    return (
        <main>
            <header>
                <BannerTemplate 
                    bannerStyle={bannerStyle}
                    heading="Support us"
                    subHeading="ደግፉና!"/>
            </header>
            <View classNameName="container">
                <main className="mb-4">
                    <div className="container px-4 px-lg-5">
                        <MenuNav />
                        <div className="row gx-4 gx-lg-5 justify-content-center">
                            <div className="col-md-10 col-lg-8 col-xl-7 text-center">
                                <img src="./../assets/img/Zelle-logo.png" alt="Zelle" width="100" height="50" />
                                <br /><br />
                                <h4>SPDE</h4>
                                <h3>EriTigPeace@gmail.com</h3>
                                <br />
                            </div>

                            <hr className="my-4" />
                            <div className="row text-center">
                                <div className="col-sm-6">
                                    <a type="button" className="btn btn-warning shadow p-3 mb-4 rounded" href="https://www.paypal.com/donate/?hosted_button_id=N82NQPFWU3JUS" target="_top"><h4>Donate With PayPal <br /> ወፈያ ብ ፐይፓል</h4></a>
                                </div>
                                <div className="col-sm-6">
                                    <a type="button" className="btn btn-warning shadow p-3 mb-4 rounded" href="https://www.paypal.com/donate/?hosted_button_id=BEZCDBJ3DQ2AG" target="_top"><h4>Membership Payment <br /> ክፍሊት ኣባልነት </h4></a>
                                </div>
                                <h4><span>OR</span></h4>
                            </div>

                            <hr className="my-4" />
                            <div className="card bg-light col-md-12">
                                <div data-bs-toggle="modal" data-bs-target="#supportModal">
                                    <div className="card-header text-center">SEEDLING FOR PEACE AND DEMOCRACY IN ERITREA <br />ፈልሲ ሰላምን ዲሞክራሲን ኤርትራ <br />لشتلة من أجل السلام والديمقراطية في إريتريا</div>
                                </div>
                                <div className="card-body" style={{ backgroundColor: '#03fc7b' }}>
                                    <h4 className="card-title">ን ፈልሲ ሰላምን ዲሞክራሲን ኤርትራ ምሕጋዝ ንእትደሊዩ፡</h4>
                                    <p> 1. ብ ዘል (ካብ ሕ.መ.ኣ) / ኢ-ትራንስፈር (ካብ ሕ.መ.ኣ ወጻኢ) - <b>Eritigpeace@gmail.com</b> </p>
                                    <p> 2. ፐይፓል (ካብ ኩሉ ክፋላት ዓለም) -  <a href="https://www.paypal.com/donate/?hosted_button_id=N82NQPFWU3JUS"> ኣብዚ ጠዉቑ. </a> &nbsp; <b>Eritigpeace@gmail.com</b> </p>
                                    <p> 3. ን ኣባላት ፈልሲ ሰላም፡ ወርሓዊ ወይ ዓመታዊ ክፍሊት ብ ፐይፓል ንምኽፋል -  <a href="https://www.paypal.com/donate/?hosted_button_id=BEZCDBJ3DQ2AG"> ኣብዚ ጠዉቑ. </a> </p>
                                    <h4 className="card-text"> Would you like to help Seedling for Peace and democracy in Eritrea </h4>
                                    <p> 1. Zelle (US) or E-transfer (Outside of US): <b>Eritigpeace@gmail.com</b> </p>
                                    <p> 2. Paypal (From anywhere) <a href="https://www.paypal.com/donate/?hosted_button_id=N82NQPFWU3JUS">paypal.me/felsi2022</a> &nbsp; <b>Eritigpeace@gmail.com</b> </p>
                                    <p> 3. Felsi members: To pay your monthly or yearly membership fee via Zelle <a href="https://www.paypal.com/donate/?hosted_button_id=BEZCDBJ3DQ2AG"> Click here. </a> &nbsp; <b>Eritigpeace@gmail.com</b> </p>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        Felsi is a registered organization with EIN: 88-2290615  <br />
                                        For any question regarding Felsi Finance Team please contact us at <b>SPDE.Fin@gmail.com / 713-364-9464</b>
                                    </small>
                                </div>
                            </div>


                            <hr className="my-4" />
                            <div className="row gx-4 gx-lg-5 justify-content-center">
                                <h5>ኣኣብ እትነብሩሉ ዓዲ፡ ብ ኣካል ወፈያ ንምኽፋል፡ ነዞም ዝስዕቡ ኣባላትና ክትረኽብዎም ትኽእሉ </h5>
                                <h5>To make your contribution in person, you can reach out to the following members.</h5>
                            </div>

                            <hr className="my-4" />
                            <br />
                            <h3>United States of America (USA)</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>   <th scope="col">Telephone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>Yordanos G/meskel</td>   <td>240 461 0067</td>
                                    </tr>
                                    <tr><td>Yosief Asghedom</td>  <td>615 812 3717</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <h3>CANADA</h3>
                            <table className="table">
                                <thead>
                                    <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>Tesfay Tecle</td>   <td>519 562 9819</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <h3>EUROPE</h3>
                            <table className="table">
                                <thead>
                                    <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>  <th scope="col">Country</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>George Tesfazion</td>    <td>31648564575</td>  <td>Holland</td>
                                    </tr>
                                    <tr><td>Weldu Berhane</td>  <td>491788924556</td>  <td>Germany</td>
                                    </tr>
                                    <tr><td>Dr kibreab</td> <td>447951572984</td> <td>UK</td>
                                    </tr>
                                    <tr><td>Mussie Dagnew</td>  <td>4791266615</td>  <td>Norway</td>
                                    </tr>
                                    <tr><td>Kedawi Mogos</td>   <td>4528680079</td>  <td>Denmark</td>
                                    </tr>
                                    <tr><td>Zewdi Sereke</td> <td>46735221382</td>  <td>Sweden</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <h3>ISRAEL</h3>
                            <table className="table">
                                <thead>
                                    <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>Berhe Abadi</td>    <td>0545289517</td>
                                    </tr>
                                    <tr><td>Shishay kidane</td>  <td>972532314112</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <h3>AFRICA, UGANDA</h3>
                            <table className="table">
                                <thead>
                                    <tr><th scope="col">Name</th>   <th scope="col">Telephone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td></td>    <td>256708923173</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </View>
            <Footer />
        </main>
    );
};

export default Support;