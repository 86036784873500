import React from 'react';
import { Container } from "react-bootstrap";
import MenuNav from './Menu';
import Footer from './Footer';

const TermsAndConditions = () => {
    return (
        <Container className="mt-3 dashboard-container">
            <header className="header-container">
            </header>
            <div className="terms-and-conditions">
                <h1>Terms and Conditions</h1>
                <p>Last updated: August 03, 2024</p>

                <h2>Introduction</h2>
                <p>
                    These terms and conditions outline the rules and regulations for the use of Felsiselam's Website, located at https://felsiselam.org.
                </p>

                <h2>Acceptance of Terms</h2>
                <p>
                    By accessing this website we assume you accept these terms and conditions. Do not continue to use Felsiselam if you do not agree to all of the terms and conditions stated on this page.
                </p>

                <h2>Cookies</h2>
                <p>
                    We employ the use of cookies. By accessing Felsiselam, you agreed to use cookies in agreement with the Felsiselam's Privacy Policy.
                </p>

                <h2>License</h2>
                <p>
                    Unless otherwise stated, Felsiselam and/or its licensors own the intellectual property rights for all material on Felsiselam. All intellectual property rights are reserved. You may access this from Felsiselam for your own personal use subjected to restrictions set in these terms and conditions.
                </p>

                <h2>User Comments</h2>
                <p>
                    This Agreement shall begin on the date hereof. Certain parts of this website offer the opportunity for users to post and exchange opinions and information in certain areas of the website. Felsiselam does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Felsiselam, its agents, or affiliates.
                </p>

                <h2>Content Liability</h2>
                <p>
                    We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                </p>

                <h2>Your Privacy</h2>
                <p>Please read our Privacy Policy.</p>

                <h2>Reservation of Rights</h2>
                <p>
                    We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request.
                </p>

                <h2>Removal of Links</h2>
                <p>
                    If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
                </p>

                <h2>Disclaimer</h2>
                <p>
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website.
                </p>

                <h2>Contact Us</h2>
                <p>If you have any questions about our Terms and Conditions, please contact us at join.felsiselam@gmail.com</p>
            </div>
            <Footer />
        </Container>
    );
};

export default TermsAndConditions;
