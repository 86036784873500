import { useState } from 'react';

export default function Jul_16_2023() {
  const [collapsePRT15, setCollapsePRT15] = useState(false);

  const toggleCollapse = (id) => {
    if (id === "collapsePRT15") {
      setCollapsePRT15(!collapsePRT15);
    }
  };

  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRT15")}
            aria-expanded={collapsePRT15}
            aria-controls="collapsePRT15"
            style={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            <h3>ሓጺር መግለጺ ሱምፖዝዮም፡ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ዋሽንግተን ዲሲ</h3>
          </a>
          <div className={`collapse ${collapsePRT15 ? 'show' : ''}`} id="collapsePRT15">
            <article>
              <div>
                <p>ኣብዚ ሎሚ ቅነ ኣብ ደንበ ቃልሲ ህዝቢ ኤርትራ ክልተ ዓበይቲ ታሪኻዊ ተግባራት ተፈጺሞም ቀንዮም ኣለዉ። እቲ ሓደ እቲ ኣብ ከተማማታት ኣዉሮጳ ብበርጌድ ንሓመዱ ዝተፈጸመ ክኸዉን ከሎ፡ እቲ ካልኣይ ከኣ እቲ ኣብ ዋሽንግተን ዲሲ ብፈልሲ ሰላምን ደሞክራስን ኤርትራ፡ ናይ ስርዓት ለውጢ ኣብ ኤርትራ” (Regime Change in Eritrea) ዝጽዉዕ፡ ኣብ ዋሽንግተን ዲሲ፡ ካቶሊክ ዩኒቨርሲቲ ካብ ዕለቲ 14-16 ሓምለ 2023 ዝተኻየደ ስምፖዝዮም እዩ።</p>
                <p>እዚ ስምፖዝዮም እዚ ብሓቂ፡ ብዓይነቱን ትሕዝትኡን ፍሉይን ሃብታምን እዩ ነይሩ። ቀንዲ ዕላምኡ ከኣ፡ እቲ ኣብ ኤርትራ ዘሎ ተቷሊታርያን፡ ኣብ ልዕሊ ህዝቢ ኤርትራን ህዝብታት ጎረባብትናን ብዝፈጸሞ መግለጺ ዘይብሉ ዘሰቅቕ ግፍዕን ጽንተትን፡ ኤርትራዉያንን መሓዙ ህዝቢ ኤርትራ ዝኾኑ፡ ኣብ ኤርትራ ብቕልጡፍ ናይ ስርዓት ለውጢ ከም ዘድሊ ኣብ ሓደ መደምደምታ ዝበጽሑሉ መደረኽ እዩ ነይሩ።</p>
                <p>ኣብዚ ሱምፖዝዮም እዚ፡ ካብ ዝተፈላልያ ክፍለ ግዝኣታትን ከተማታት ኣሜሪካን ካልእን፡ ብኣካል ብዝመጹ ኣባላትን ግዱሳት ደለይቲ ፍትሒን ዝሓቖፈ፡ ከምኡውን ካብ ሙሉእ ዓለም ብማንዛ ዝተሳተፉ ፍሉይ እዩ ነይሩ። ንትሕዝትኡ ብዝምልከት ከኣ፡ መንእሰያትን ደቀንስትዮን ዝሓቖፈ፡ ጸሓፍቲ፡ ጋዜጠኛታት፡ ፖለቲከኛታትን ምሁራትን ኤርትራዉያን፡ ተጋሩን፡ ከምእዉን ካብ ዝተፈላልየያ ሃገራት ብዝመጹ መሓዙት ቃልሲ ህዝቢ ኤርትራ፡ ንዘመናት ዘኻዕበትዎ ዓሙቕን፡ ሰፊሕን መሃሪን ፍልጠቶምን ተመኩሮኦምን ዘካፈልሉ መድረኽ እዩ ነይሩ።</p>
                <p>ከም ዝተኸታተልኩሞ፡ እቲ ትሕዝቶ፡ ኣብ ልዕሊ ህዝቢ ኤርትራ ዝተፈጸመ ህልቂት፡ ገበንን ምግሃስ ሰብኣዊ መስለን ከምእዉን ዘሕደሮ ዓሙቕ ስነ ልቦናዊ መጉዳእቲ ብደቂቕ ዝዘርዘረ፡ ከምእዉን እቲ ኣብ ኤርትራ ዘሎ ሰይጣናዊ ሽፍታ ስርዓት፡ ኣብ ልዕሊ ጎረባብቲ ህዝቢታት ቀርኒ ኣፍሪቃ፡ ኣብ ሱዳን፡ ጅቡቲ፡ የመንን ኢትዮጵያን ብፍላይ ከኣ ኣብ ልዕሊ ሓዉናን ኣካልናን ዝኾነ ህዝቢ ትግራይ ዝፈጸሞ ዘሰቅቕ ወራርን ህልቂትን ዝዳህሰሰ እዩ ነይሩ። እቲ ትሕዝቶ፡ ህዝብና ነቲ ኩነታት ብግቡእ ንኽርድኦ፡ ኣፍልጦ ንኽህብን ሓላፍነት ዘሰክምን ጥራይ ዘይኮነ፡ ነቲ ብድሕሪ ኢሰይያስ ኣብ ሞንጎ ህዝቢ ኤርትራን ህዝቢ ትግራይን ካለኦት ህዝብታትን ክንዮ እሰይያስ ከነረጋግጾ እንደሊ ሰላም፡ ምርግጋእን ምዕባለን መሰረት ዘንጽፍ እዩ።</p>
                <p>እዚ ከምዚ ኢሉ እንከሎ፡ እዚ ሱምፖዝዮም እዚ፡ ናይ ኤርትራ ተቓለስትን ደይቲ ፍትሒን ዘሳተፈ፡ ነቲ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ኣብ ሞንጎ ደንበ ፍትሒ ክህሉ ዝደልዮ ሓበራዊ መኸተን ናይ ህዝብና ሓደነት ዘንጸባርቕ እዩ። እቲ ብወገን ናይ ኤርትራ ዓፋር ሃገራዊ ካዉንስል ፕረሲደንት ኣቶ ኣሕመድ ዮሱፍ መሓመድ ዝቐረበ ትሕዝቶ ሓደ መረጋገአጺ ኣብነት እዩ ነይሩ። እዚ ከኣ ካብ ዝኾነ ይኹን እዋን ንላዕሊ፡ ኣብዚ ሕጂ እዋን ኩሉ ዓቕሚታትና ኣወሃሂድና ነቲ ጸላኢን መንሽሮን ህዝቢ ኤርትራን፡ ጎረባብትናን ዓለምን ዝኾነ ስርዓት ኢሰይያስ፡ ብቑልጡፍ ክንኣልዮ ይግባእ ንብል።</p>
                <p>ኣብ መወዳእታ፡ ነቶም ነዚ ስምፖዝዮም ዕዉት ንኽኸዉን ዘወሃሃዱ፡ ብቁጠባን ካልእን ዘበርከትኩም ኩላትኩም ብኣካልን ብማንዛን ዝተሳተፍኩም ብዙሕ ልባዊ ምስጋና ነቕርብ።</p>
                <p>ፈልሲ ሰላምን ዲሞክራስን ኤርትራ
                  <br />
                  16 ሓምለ 2023
                </p>
              </div>
            </article>
            <p><a href="assets/pdf/press_release_15_tig.pdf">PDF ትግርኛ</a>.</p>
          </div>
          <p className="post-meta">Posted on Jul 16, 2023</p>
        </div>
      </div>
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          {/* Placeholder for the English version */}
        </div>
      </div>
    </div>
  );
}
