import React from 'react';

const PeaceLoveTShirtBlk = () => {
  return (
    <div className="card">
      {/* Product image */}
      <img className="card-img-top" src="../assets/img/tshirt001.png" alt="T-Shirt" />
      {/* Product details */}
      <div className="card-body p-4">
        <div className="text-center">
          {/* Product name */}
          <h5 className="fw-bolder">T-Shirt</h5>
          {/* Product price */}
          $15.00
        </div>
      </div>
      {/* Product actions */}
      <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
        <div className="text-center">
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_self">
            <input type="hidden" name="shopping_url" value="https://felsiselam.org/shop" />
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="VRAW6CCSU6MZQ" />
            <div className="col">
              <input type="hidden" name="on0" value="Choose Color" />
              Choose Color
              <select name="os0">
                <option value="Blue">Blue</option>
                <option value="Black">Black</option>
              </select>
            </div>
            <div className="col pt-3">
              <input type="hidden" name="on1" value="Choose Size" />
              Choose Size
              <select name="os1">
                <option value="Small">Small</option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
                <option value="Extra-Large">Extra-Large</option>
              </select>
            </div>
            <div className="col pt-3">
              <input type="hidden" name="currency_code" value="USD" />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Add to Cart"
              />
            </div>
          </form>
          <span>Or</span>
          <br />
          <a href="#" data-bs-toggle="modal" data-bs-target="#zelle-info-modal-plt">
            <img src="assets/img/Zelle-logo.png" alt="Zelle" width="75" height="35" />
          </a>
        </div>
      </div>

      {/* Zelle Modal */}
      <div
        className="modal fade"
        id="zelle-info-modal-plt"
        tabIndex="-1"
        aria-labelledby="zelleInfoModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="zelleInfoModalTitle">
                Use Zelle to save transaction fee!
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>
                Recipient Name - <strong>Felsi Selam</strong>
              </p>
              <p>
                Send by e-mail - <strong>EriTigPeace@gmail.com</strong>
              </p>
              <hr className="my-4" />
              <p>Memo - Item Name and your shipping address </p>
              Example - <strong>Peace Love T Shirt. Blue. Medium. 123 Main St. Apt 10B. DC 20003 </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeaceLoveTShirtBlk;
