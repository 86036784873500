import React from 'react';
import MenuNav from './Menu';
import Footer from './Footer';
import { View } from '@aws-amplify/ui-react';
import MeleyFlag from './merch/MeleyFlag';
import FelsiMegazine01 from './merch/FelsiMegazine01';
import PeaceLoveTShirtBlk from './merch/PeaceLoveTShirtBlk';
import BannerTemplate from './banner/BannerTemplate';

function Shop() {

    const bannerStyle = {
        backgroundImage: "url('../assets/img/shopBannerGif.gif')",
        backgroundSize: "1000px",
        backgroundRepeat: "repeat-x"
    };

    return (
        <main>
            <header>
                <BannerTemplate 
                    bannerStyle={bannerStyle}
                    heading="Items for sale"
                    subHeading="ዝሽየጥ ኣቕሑ"/>
            </header>
            <View classNameName="container">
                <main className="mb-4">
                    <div className="container px-4 px-lg-5">
                        <MenuNav />
                        <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div className="col-sm-12 text-center">
                            <h5>ኩሉ ኣታዊታት ን ጥርናፈ ፈልሲ ሰላም ይሕግዝ</h5>
                        </div>
                        <div className="col-sm-12 text-center">
                            <h5>All your purchases support the Felsi Selam Coalition</h5>
                        </div>
                        <hr className="my-4" />
                        <div className='col-md-4'>
                            <MeleyFlag />
                        </div>
                        <div className='col-md-4'>
                            <FelsiMegazine01 />
                        </div>
                        <div className='col-md-4'>
                            <PeaceLoveTShirtBlk />
                        </div>
                    </div>
                    <br className="my-4" />
                    </div>
                </main>
            </View>
            <Footer />
        </main>
    );
};

export default Shop;