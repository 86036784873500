import React from 'react';

function Oct_14_2023() {
    return (
        <div className="row gx-4 gx-lg-5">
            <div className="col-sm-6">
                <div className="post-preview">
                    <a data-bs-toggle="collapse" href="#collapsePRT16" aria-expanded="false" aria-controls="collapsePRT16" style={{ textDecoration: 'none' }} >
                        <h3>መግለጺ ጽንዓት - ፈልሲ ሰላምን ደሞክራስን ኤርትራ</h3>
                    </a>
                    <div className="collapse" id="collapsePRT16">
                        <article>
                            <div>
                                <p>ህዝቢ ትግራይ፡ ካብ 14-16 ጥቅምቲ 2023፡ ነቶም መንነቱን ህላዉነቱን ከጥፍኡ ዝመጹ ወረርቲ ሓይሊታት ጽንተት ኢትዮጵያን ሻዕብያን ንምምካት ዝሓለፋ ጀጋኑ ደቁ ንምዝካር፡ ኣብ ብሄራዊ ናይ ሓዘን፡ መርድእን ዝኽሪን ይርከብ። ፈልሲ ሰላምን ዲሞክራስን ኤርትራ በዚ ረዚን ታሪኻዊ ቅንያት’ዚ፡ ከም ፖለቲካው ጥምረት፡ ነቲ ኣብ ውሽጥን ኣብ ወጻኢን ዘሎ ህዝቢ ትግራይ፡ ፈጣሪ ጽንዓት ይሃብካ ንብል። ህዝቢ ትግራይ መስዋእቲ ደቁ ክቅበሎን ብሓቂ ክጸናንዕን እንተኾይኑ ግና፡ እቲ ብወርርቱ ሓይሊታት ተታሒዙ ዘሎ ግዝኣታዊ መሬት ትግራይ ምስ ተመልሰ፡ እቶም ተፈናቒሎም ዘለዉ ዜጋታቱ ናብ ኣባይቶም ተመሊሶም ምስ ተጣየሱ፡ ኣብ ልዕሊ እቶም ገበን ዝፈጸሙ ፍትሒ ክረጋገጽን ከሎ እዩ።</p>
                                <p>ግዝኣታዊ ሓድነት ትግራይ ንምርግጋጽ፡ ነቲ ኣብ ሕዳር 2022 ኣብ ፕሪቶርያ ደቡብ ኣፍሪቃ ዝተኸተመ ዉዕል ሰላም፡ ብመሰረት ሕገ መንግስቲ ኢትዮጲያ ክትግበር ይግባእ። እዚ ነቲ ኣብ ሞንጎ ግዝያዊ መንግስቲ ትግራይን ኣብ ሞንጎ ፈደራል መንግስቲ ዘሎ ምትእምማን የደልድሎ፡ ኣብ ትግራይን ኢትዮጵያን ከምእዉን ኣብ ወርኒ ኣፍሪቃ ዘላቒ ሰላም ንኽሰፍን ዓቢ ኣበርክቶ ኣለዎ።</p>
                                <p>ግዝኣታዊ መሬት ትግራይ እንተ ተመሊሱ፡ እቶም ኣብ ግዜ ኲናት ተፈናቒሎም፡ ንዝሓለፈ ክልተ ዓመታት ብቑሪን ጸሓይን፡ ብሕጽረት መድሃኒት፡ መግቢን ዝስተ ጹሩይ ማይን ክሳቐዩ ዘለዉ መብዛሕትኦም ቆልዑ፡ ደቀንስትዮን ሽማግለታትን ናብ ንቡር ናብራ ንኽምለሱ መንገዲ ዝጸርግ እዩ። እዚ፡ እቶም ተፈናቐልቲ ቀልጢፎም ናብ ማሕበራዊ ሕርሻዊ፡ ንግዳዊን ትምህርታዊን ካልእን መነባብረኦም ክምለሱ፡ ርእሰ ተኣማንነትን ውሕስነትን ክስምዖም ዝገብር እዩ።</p>
                                <p>እቶም ዝተፈናቐሉን፡ ህዝቢ ትግራይ ብሓፈሻ፡ ዉሕስነት ሰላም ክስምዖ እንተኾይኑ ግን፡ ገበነኛታት ኣብ ፍትሒ ዝቐርብሉ ኩነታት ክፍጠር ኣለዎ። እቶም ገበነኛታት፡ ሓደ ሚልዮን ዝኸዉን ህዝቢ ዘህለቑ፡ ክልተ ሚልዮን ዝኸዉን ህዝቢ ዘፈናቐሉ፡ ብዓሰርተታት ኣሽሓት ደቀንስትዮ ትግራይ ዝዓመጹ፡ ነዞም ሎሚ ቅነ ንዝክሮም ዘሎና ዓሰርተታት ኣሽሓት ጀጋኑ ኣባላት ሓይሊታት ምክልኻል ትግራይ ንኽስዉኡ ምኽንያት ዝኾኑ፡ ኮነ ኢሎም፡ ኣብያተ እመንታት፡ ታሪኻዊ ቅርሲታት ዘቃጸሉን ዘዕነዉን፡ ሃብትን ንብረትዝዘመቱን እዮም።</p>
                                <p>ሕዝቢ ትግራይ ግን፡ በዚ ከይተንበርከኸ፡ ብጽንዓት፡ ተወፋይነትን ጅግንነትን ተቓሊሱ፡ ብክቡር መስዋእቲ ደቁ መኪቱ ንዓለም ኣዛሪቡ እዩ። በቶም በጃ ዝሓለፉ ስዉኣት ደቁ ሓሪኑ ኸኣ፡ ነቲ ዝተወረረ መሬቱን ዝተፈናቐሉን ናብ ቦትኦም ብምምላስ ዘላቒ ፍትሒን፡ ሰላምን፡ ሰናይ ምምሕዳርን ልምዓትን ከም ዘረጋግጽ ኣይንጠራጠርን። ፈልሲ ሰላምን ደሞክራስን ኤርትራ ብወገንና፡ ኣብ ጎኒ ቃልሲ ህዝቢ ትግራይ ተሰሊፍና እጃምና ከነበርክት ምዃና ከነረጋግጽ ንደሊ።</p>
                                <p>ጽንዓትን ዓወትን ንህዝቢ ትግራይ
                                    <br />
                                    ፈልሲ ሰላምን ደሞክራስን ኤርትራ
                                    <br />
                                    14 ጥቅምቲ 2023
                                </p>
                            </div>
                        </article>
                        <p><a href="assets/pdf/press_release_16_tig.pdf">PDF ትግርኛ</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Oct 14, 2023
                    </p>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="post-preview">
                    {/* <a data-bs-toggle="collapse" href="#collapsePRE16" aria-expanded="false" aria-controls="collapsePRE16">
                        <h3></h3>
                        </a>
                    <div className="collapse" id="collapsePRE16">
                        <article>
                                <div>
                                    <p></p>
                                    </div>
                            </article>
                        <p><a href="assets/pdf/press_release_16_eng.pdf">PDF English</a>.</p>
                    </div>
                    <p className="post-meta">
                        Posted on Oct 15, 2023
                        </p> */}
                </div>
            </div>
        </div>
    );
};

export default Oct_14_2023;