import { useState } from 'react';

export default function Apr_21_2022() {
    
  const [collapsePRT1, setCollapsePRT1] = useState(false);
  const [collapsePRE1, setCollapsePRE1] = useState(false);

  const toggleCollapse = (id) => {
    if (id === "collapsePRT1") {
      setCollapsePRT1(!collapsePRT1);
    } else if (id === "collapsePRE1") {
      setCollapsePRE1(!collapsePRE1);
    }
  };

  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRT1")}
            aria-expanded={collapsePRT1}
            aria-controls="collapsePRT1"
            style={{ cursor: 'pointer' }}
          >
            <h3>ጋዜጣዊ መግለጺ ፈልሲ ሰላምን ደሞክራስን ኤርትራ</h3>
          </a>
          <div className={`collapse ${collapsePRT1 ? 'show' : ''}`} id="collapsePRT1">
            <article>
                <div>
                    <p>ኤርትራ ሃገርና: ብሰንኪ እቲ ኣብ ኣስመራ ዘሎ ውልቀ መላኺ ስርዓት ኢሰይያስ ኣብ ቃራና መንገዲ ትርከብ። ህዝብና ኸኣ ኣብ ከቢድ ማሕበረ-ቁጠባዊ: ፖለቲካዊን: ወተሃደራዊን ቅልዉላዉ ይርከብ። ህዝብና ካብ’ቲ ኣትይዎ ዘሎ ኣዚዩ ከቢድ ኩነታትን ናይ ምጽናት ሓደጋን ንኽወጽእ ኣብ ቀጻሊ ቃልሲ ይርከብ። ከም መቐጸልታ ብሙሉእ ሓይሉ ነቲ ስርዓት ቦርቑቑ ንምልጋስ ዘተኮረ ፈልሲ ሰላምን ደሞክራስን ዝተባህለ ምንቅስቓስ ከም ዝተተኸለ ንህዝብና ነበስር።</p>
                    <p>ናይ’ዚ ህዝባዊ ሓይሊ ቀንዲ ዕላማን ራኢን: ህዝብን ሃገርን ምድሓን። ህዝብና ንሓዋሩ ብሓርነት ኣብ ጎደና ሰላም: ደሞክራስን ምዕባለን ንኽምርሽ ተኽእሎ ምፍጣር እዩ። ነዚ ክዉን ንምግባር: ንስርዓት ኢሰይያስ ብቕልጡፍ ብምእላይ: ኣብ ኤርትራ ንኩሉ ዝሓቖፈ ግዝያዊ መሰጋገሪ መንግስቲ ኣቑምካ: ስልጣን ንህዝቢ ምርካብ እዩ።</p>
                    <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ ካብ ንጥፋት ውልቀሰባት: ሚድያ ጉጅለታት: ቤርጌሳውያን ማሕበራትን ፖለቲካውያን ሓይሊታትን ዝቖመ ኣብ ውሽጢን ኣብ ወጻእን ኤርትራ ፖለቲካዊን ወተሃደራዊን ሃገራዊ ሓይሊ ዝፈጠረ: ገና’ውን ብተሳትፎን ዋንነትን ኩሉ ህዝቢናን ፖለቲካዊ ሓይሊታትን እንዳ ሰፍሐን እንዳ ዓበየን ዝኸይድ ዘሎ ናይ ለውጢ ሓይሊ እዩ።</p>
                    <p>ናይ ኤርትራ ህላዉነት ከም ሃገር ብዘኽብርን ብዘርብሕን መንገዲ: ምስ ኩሎም ህዝቢታት ጎረባብትን ዓለምን: ብፍላይ ከኣ ምስ ህዝቢ ትግራይ ብምሽራኽ: ነቲ ጸረ ህዝቢ ኤርትራ: ዞባናን ዓለምን ኮይኑ ዘሎ ስርዓቲ ኢሰይያስ ብቕልጡፍ ክእለ ይጽውዕ።</p>
                    <p>በዚ ኣጋጣሚ: ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ነቲ ስርዓት ኢሰይያስ ኣብ ኢትዮጵያ ኣብ ዝሓለፈ ዓመትን መንፈቕን ኣብ ልዕሊ ህዝቢ ትግራይ ዝፈጸሞን ዝፍጽሞን ዘሎ ገበን ዘርኢ ምጽናት: ናይ ኩናት ገበን: ከምኡ’ውን ናይ ንብረት ምዕናውን ምዝማትን ብትሪ ይኹንን።</p>
                    <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ህዝቢ ትግራይ ካብ’ቲ ዘለዎ ከበባን ዕጽዋን ንኽወጽእ ከምኡ’ውን መሰል ርእሰ ውሳኔኡ ንኽረጋገጸሉ ሓቢሩ ይቃለስ። እዚ ምንቅስቓስ ኣብ ሞንጎ ህዝቢታት ኤርትራ: ትግራይ: ኢትዮጵያን ከምኡ’ውን ኩሎም ጎረባብትናን ዘላቒ ዕርቅን ሰላምን ክሰፍን እጃሙ ከበርክት እዩ።</p>
                    <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ኣብ’ዚ ወሳኒ እዋንን መድረኽን: ህዝብና ነዚ ቃልሲ ክትሳተፎን ብኹሉ ክትድግፎን ንጽዉዕ። ተቓወምቲ ሓይሊታት’ውን እቲ ንህዝብኹምን ሃገርኩን ዘለኩም ሰናይ ባህጊን ድሌትን ኣብ ሃገርና ተመሊስኩም ከተተግብሩ ክትክእሉ: ሓቢርና ጸላኢና ንኣልየሉ እዋን ሕጂ ምኻኑ ከነተሓሳስብ ንደሊ። ኩሎም ናይ ግዳም መሻርኽትና ከኣ ዝከኣሎም ክሕግዙና በዚ ኣጋጣሚ ጻውዒትና ነቕርብ።</p>
                    <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ</p>
                    <p>21 ምያዝያ 2022</p>
                </div>
            </article>
            <p><a href="assets/pdf/press_release_1_tig.pdf">PDF ትግርኛ</a>.</p>
          </div>
          <p className="post-meta">Posted on April 21, 2022</p>
        </div>
      </div>
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRE1")}
            aria-expanded={collapsePRE1}
            aria-controls="collapsePRE1"
            style={{ cursor: 'pointer' }}
          >
            <h3>Press Release The Seedling for Peace and Democracy in Eritrea</h3>
          </a>
          <div className={`collapse ${collapsePRE1 ? 'show' : ''}`} id="collapsePRE1">
            <article>
                <div>
                    <p>Today, Eritrea sadly finds itself in a precarious condition mainly due to the ruthless totalitarian
                        rule of President Isaias Afewerki. Mismanaged for many years, it has never come out of a
                        series of violent conflicts its leader has instigated. Consequently, the people of Eritrea have
                        been forced to suffer from immense political, socio-economic demographic and security crises.
                        Despite this, the people of Eritrea have never stopped resisting the PFDJ’s brutal oppression. It
                        is with all this in mind that a new movement, the Seedling for Peace and Democracy in Eritrea
                        (SPDE), has been established, and has already started operating on the ground with the
                        intention of uprooting the Isaias regime in Asmara.</p>
                    <p>The sole objective of the SPDE will be the salvation of Eritrea, thereby ushering a new era of
                        peace, justice, freedom, democracy and development to all its citizens. Cognizant of the fact
                        that this can only be realized after removing the regime first and then laying the groundwork for
                        the establishment of an all-inclusive provisional government, the SPDE is committed to the
                        realization of both with utmost urgency.</p>
                    <p>The SPDE has been established through the coordination and effort of individual
                        activists, media outlets, civic groups and various political organizations both in Diaspora and
                        Tigray. In a short period of time, the Movement has been able to emerge as a political force
                        worth reckoning. This is a work in progress, and in time it intends to expand so as to include
                        individuals and groups with divergent backgrounds and opinions.</p>
                    <p>The SPDE has also adopted a principled stand that it will work with all neighboring forces
                        and peoples who share its vision and respect the national interest and integrity of the Eritrean
                        nation. Against the backdrop of the genocidal war against the people of Tigray, the
                        SPDE seeks maximum cooperation and coordination with the people of Tigray and its forces. It
                        goes without saying that the SPDE strongly condemns the involvement of the Asmara regime
                        in the genocidal war and all types of crimes it committed against the people of Tigray. It also
                        expresses its readiness to stand shoulder to shoulder with the people of Tigray in their struggle
                        until the illegal and inhumane siege is removed and their right for self-determination is
                        recognized and respected.</p>
                    <p>Finally, we call upon all Eritreans to support, stand and strive along us for the realization of
                        a free, peaceful, prosperous and democratic Eritrea.</p>
                    <p>The Seedling for Peace & Democracy in Eritrea</p>
                    <p>April 21, 2022</p>
                </div>
            </article>
            <p><a href="assets/pdf/press_release_1_eng.pdf">PDF English</a>.</p>
          </div>
          <p className="post-meta">Posted on April 21, 2022</p>
        </div>
      </div>
    </div>
  );
}