import React from 'react';

function June_29_2024() {
  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        <div className="post-preview">
          <a data-bs-toggle="collapse" href="#collapsePRT19" aria-expanded="false" aria-controls="collapsePRT19" style={{ textDecoration: 'none' }} >
            <h3>ጋዜጣዊ መግለጺ ቀዳማይ ጉባኤ
                ፈልሲ ሰላምን ዲሞክራስን ኤርትራ</h3>
          </a>
          <div className="collapse" id="collapsePRT19">
            <article>
              <div>
                <p>ቀድማይ ጉባኤና ኣብ ኣገደስቲ መድረኻዊ ጉዳያት እንታይ መርገጻት ሒዙ ወጺኡ?</p>
                <p>ፈልሲ ሰላምን ዲሞክራስን ኤርትራ ካብ ዕለት 28.06.24 ክሳብ ዕለት 29.06.24 ቀዳማይ ጉባኤኡ ኣካይዱ።</p>
                <p>ፈልሲ፣ ቅድሚ ከባቢ ክልተ ዓመት ኣቢሉ ካብ ዝምስረት ብግዚያዊ ዓንዲ ሕግን ግዚያዊ መሪሕነት ኢዩ ክንቀሳቀስ ጸኒሑ። ናይ ቀድማይ ጉባኤና ቀንዲ ዕማማት እምበኣር ቀዋሚ ዓንዲ ሕግን መሪሕነትን ምጽዳቕ ኢዩ ነይሩ።</p>
                <p>ብመሰረት ወጺኡ ዝነበረ መደብ፣ ጉባኤና ብናይ ዝተፈላለያ ውዳበታትን ኣገደስቲ ውልቀሰባትን ናይ ሓጎስን ተደላይነት ብሓብር ኰንካ ምቅላስን ዝገልጽ መልእክኽታት ኢዩ ተጀሚሩ። በዚ መሰረት ዲሞክራሲያዊ ምንቅስቓስ ሓርነት ኩናማ ኤርትራ፣ ምንቅስቓስ ብሩህ መጻኢ ኤርትራ፣ ሓድነት ኤርትራውያን ንፍትሒ፣ ሃገራዊ ግንባር ንድሕነት ህዝቢ ኤርትራን፣ መልእኽታቶም ኣመሓላሊፎም። ተቓላሳይ ሓውና ዶር. ዳኒኤል ህብታይውን ኣገዳሲ ናይ ቃልሲ ማዕዳን ምትብባዕን መልእኽቱ ኣስሚዑ።</p>
                <p>እቲ ምዱብ ናይ ጉባኤ ስራሓትና ንምጅማር፣ ብቐዳምነት ጸብጻብ ዝተፈላለያ ክፍልታት ምስ ቀረበ፣ ዓንዲ ሕግን መርገጽ ጉባኤና ኣብ ኣገዳሲ ጉዳያት ቃልስና ኣጽዲቑ። ባይቶኡ ኣቚሙ ድማ ፣ ኣቦ መንበር ፈጻሚ ኣካል መሪጹ። ኣቦ መንበር ዝረቚሖም ሽዱሽተ ኣባላት ፈጻሚ ኣካል ናብ ባይቶ ቀሪቦም ተቐባልነት ምስ ረኸቡ ድማ፣ ኣፈኛን፣ ምክትል ኣፈኛን ዋና ጸሓፍን ባይቶ ተመሪጾም። ኣብ መወዳእታ ድማ ሰለስተ ዝኣባላታ ክፍሊ ኦዲት መሪጹ ጉባኤና ተዛዚሙ።</p>
                <p>ክቡራት ተቓለስቲ ኤርትራውያን ፈተውትናን</p>
                <p>ሓደ ካብቶም ኣገደስቲ ዛዕባታት ጉባኤና፣ መርገጽ ጉባኤና ኣብ ኣገደስቲ ጉዳያት ቃልስና ዝብል ነጥቢ ኢዩ ነይሩ። ነዞም ዝስዕቡ ጉዳያት ኣልዒሉ ድማ ተመያይጡን ኣጽዲቑን።</p>
                <p>1. ብሰንኪቲ ውልቀ ምልካዊ ግዝኣት ህግደፍ ንኣስታት ሱሳ ዓመት ክወርዶ ዝጸንሐ ዘሰክሕ ናይ ጽንተትን ጥፍኣትን ወፈራ፣ ህዝብና ሎሚ ከም ሕብረተሰብ ናብ ክጸንተሉ ዝኽእል ሓደጋ ተገምጊሙ ኣሎ። መሰረት ናይዚ ኩሉ ድማ እቲ ኣብ ኤርትራ ብዘይ ሕጋዊ መንገዲ ዘማሓድር ዘሎ፣ ውልቀምልካዊ ግዝኣት ህግደፍ ኢዩ። እዚ ግዝኣትዝን መስሪትዎም ዘሎ ናይ ጽንተት ትካላቱን፣ ኣስፊንዎ ዘሎ ጸረ ሕብረተሰብ ባህልታቱን ካብ ሱሮም ከይተማሕዉ ድማ ህዝብና ንቡር ህይወቱ ክምልሰሉ ኣይክእልን ኢዩ። ስለዚ ኢና ድማ፣ ትማልን ሎምን፣ እዚ ግዝኣትዚ ክሳብ ዝሃለወን “ናይ ስርዓት ለውጢ! (Regime Change)” ንብል ዘሎናን፣ ብጽንዓትን ብንቕሓትን ንቃለስ ዘሎናን። እንኮ ጸላኢ ህዝብና ህግደፍ ተኺልዎ ዘሎ ግዝኣትን ኣተሓሳስባታቱን ጥራሕ ኢዮም። እዚ ግዜ ዘይህብ ዕማም ኩሉ ክጓየየሉን በብዓቕሙ ክሳተፍን እቲ እንኮ ናይዚ ግዜዚ መንገዲ ፍታሕ ኢዩ።</p>
                <p>2. ፈልሲ ብሰለስተ ናይ ዝተሓቱ ናይ ሓባር ነጥብታት ዝቖመ፣ ውደባታትን ውልቀሰባትን ዘጠቓለለ ጥምረትዩ። እተን ነጥብታት ድማ፣ ኤርትራ ልዑላዊት ሃገር ምኹና ምእማን፣ እንኮ ጸላኢና ውልቀ ምልካዊ ግዝኣት ህግደፍ ምዃኑን ንሱን ኣተሓሳባታቱን ክመሓዉ ከም ዘለዎም ምእማን፣ ምስ ኩሎም ኣብ ምእላይ እዚ ግዝኣትዚ ጠቕሚ ዘለዎም ኣካላት ብፍላይ ድማ ምስ መንግስትን ህዝብን ትግራይ ብሓባር ኰንካ ምቅላስን ዝብላ ኢየን። ጉባኤና እዘን ነጥብታት እዚኣተን ሕጂውን ናይ መተኣኻኸቢና ነጥብታት ኮይነን ክቕጽላ ጥራሕ ዘይኮነስ፣ ኣበርቲዕና ክንሰርሓለንን፣ ተቓለስቲ ከነፍርየለንን፣ ሓይልታት ክንእከበለንን ከም ዝግብኣና ደጊሙ ኣረጋጊጹ።</p>
                <p>3. ኣብ ጉዳይ ምሕዝነትን ውህደትን ውደባታት ኤርትራ ብዝምልከት ድማ፣ ኣብዚ እዋንዚ ሓደ ካብ መሰረታዊ ተደላይነታት ስለዚ ድማ ቀዳምነት ሂብና ክንሰርሓሉ ከም ዘድልየና ወሲኑ። ንሕና ከምፈልሲ ምስቶም ብዕላማ ዝመሳሰሉናን ዝቀራረቡናን ኣካልት ኣብ ንጹር ዕላማታትን መትከላትን ዝተሰረት ውህደት ክንገብር ቅሩባት ኢና፣ ንሰርሓሉውን ኣሎና። ካብኡ ሓሊፉ ድማ ምስ ዝኾነ ኣንጻርቲ ግዝኣት ዝቃለሱ ውደባታትን ውልቀሰባትን ክሳብቲ ዝነኣሰ ናይ ሓባር ስራሕ ብሓባር ኰና ክንሰርሕ ከም ዘሎና ኢና ንኣምን። ከምኡውን ንገብር ኣሎና። ብመሰረቱ ፈልሲ፣ ብውልቀሰባትን ውደባታትን ዝቖመ፣ ኣብ ልዕሊቶም ውልቀኣባላት፣ ፈልሲ ዝተፈላለዩ ውዳበታትውን ወሲኹ ዝተመስረተን ዝሰርሕ ዘሎን ጥምረት ኢዩ።</p>
                <p>ኣብዚ ቀዳማይ ጉባኤና ኣብ ዲሞክራሲያዊ ምንቅስቓስ ኩናማ (ዲምሓኩኤ)፣ ኣብ ርእሲቲ ብተግባር ብሓባር ክንሰርሕ ምጽናሕና፣ ሕጂ ድማ ነቲ ብዝተፈላለየ ተክኒካዊ ጉዳያት ከይተማልአ ዝጸንሐ ውክልና ኣብ መሪሕነት ፈልሲ ክዉን ንምግባር ናብ ባይቶ ፈልሲ ወካይሉ መዚዙ፣ ኣካል ናይቲ መሪሕነት ፈልሲ ኮይኑ ኣሎ።</p>
                <p>እቶም ኣብ ጉባኤና መልእኽቶም ዘመሓላለፉ ውደባታትውን፣ ተመሳሳሊ ዕላማታት ዘለዎም ውዳቤታትውን ውህደት ምግባር ከም ዘድልየና ኢዮም ኣነጺሮም። ጉባኤና ድማ እዚ ጉዳይዚ ናይ ቀረባ እዋን ህጹጽ ዕማምና ክኸውን ከም ዘለዎ ወሲኑ ኣሎ።</p>
                <p>4. ብዛዕባ ብሓፈሽኡ ብርጌድ ንሓመዱን ኣብ ኢትዮጲያ ዝገበሮ ኣኼባታትን ብዝምልከት ድማ ጉባኤ ከም ዝስዕብ መዚኑን ወሲኑን።</p>
                <p>ብርጌድ ንሓመዱ ከም ህዝባዊ ማዕበል ብኣሽሕት ዝቑጸሩ ተቓወምትን ውድባትን ሲቪካዊ ማሕበራትን ካልእ ውዳበታትን ኣሳቲፉ ዝተራእየ ርሱን ናይ ቃልሲ ናህሪ ኢዩ። ፈልስን ኣባላቱን ድማ ኣብ በቦትኡ ኣብቲ ማዕበል ልሉይ ተራ ጸኒሑና ኣሎናን ኢዩ።</p>
                <p>በዚ መሰረት ኣብ ኢትዮጲያውን ናይዚ ማዕበልዚ ኣኼባታትን ውዳበታትን ይግበር ምህላዉ ብኣዎንታ እንርእዮ ጉዳይ ኢዩ። እቲ ኣብ ኢትዮጲያ ክርአ ጀሚሩ ዘሎ ናይ ብርጌድ ንሓመዱ ስራሓት ንጸላኢና ግዝኣት ኤርትራ ኣስጊእዎ ከም ዘሎ ኩላትና ንዕዘቦ ዘሎና ጉዳይ ኢዩ። ክንቃለሰሉ ዝጸናሕና ጉዳይ ስለዝኾነ ድማ ነቲ ተረኽቦውን ንሕናውን ከም ዓወትና ኢና ንቖጽሮ።</p>
                <p>ብዛዕባ መጻኢ ርክብን ናይ ሓባር ጉዕዞን ምስ ብርጌድ ንሓመዱ ድማ እዞም ዝስዕቡ ጉዳያት ክሳለጡ ወሰንቲ ምዃኖም ብትኹረት መዚንና።</p>
                <ul>
                  <li>ብርጌድ ንሓመዱ ዕላማታቱን ናይ ባይታ መንገዱን ከነጽር</li>
                  <li>በቲ ዝመረጾ ኣገባብ ትካላዊ ኣሰራሓ ኣተኣታዩ ብደረጃ ትካላት ርክባት ክገብር ክጅምር</li>
                  <li>ኣብ ደንቢ ተቓውሞ ዝተፈላለየ ዕላማታትን ኣገባብ ኣቀላልሳን ዘለዎም ዝተፈላለዩ ውዳበታት ኣለዉ። ኣብዚ ጉዳይዚ ብርጌድ ንሓመዱ ናይ ነዊሕን ቀረባን እውን መካይዱ ከነጽር የድልዮ</li>
                </ul>
                <p>5. ምስ መንግስትን ህዝብን ትግራይ ብሓባር ኰንካ ምቅላስ ዝብል ጉዳይ ከም ናይ ኩሉ ግዜ ሕጂውን ኣማራጺ ዘይብሉ ናይ ቃልሲ መንገድና ምዃኑ እውን ኣነጺሩ ጉባኤና።</p>
                <p>ፈልሲ፣ ውልቀ ምልካዊ ግዝኣት ኤርትራ ቀንዲ ኣካል ኮይኑ ምስ ብዙሓት ካልኦት ተሓባቢሩ ኣብ ልዕሊ ህዝቢ ትግራይን ንታሪኻዊ ቅርስታት ሓዊስካ ኣብ ልዕሊ ኩሎም ትካላትን ናይ ጽንተት ኲናት ከም ዘካየድ ይኣምን። ሰራዊት ኤርትራ ኣብ ልዕሊ ህዝቢ ትግራይ ብሓፈሻ፣ ብፍላይ ከኣ ኣብ ልዕሊ ብዓሰርተታት ኣሽሓት ዝቑጸራ ደቀንስትዮ ትግራይ ዘስካሕክሕ ጾታዊ ዓመጽን መጥቃዕትን ገይሩን፣ ገና ይቕጽሎ ኣሎን። እዚ ሕጂ ብዓበይቲ ዓለምለኻዊ ትካላትውን ከይተረፈ ተረጋጊጹ ዘሎ፣ ዘርኢ ተጋሩ ናይ ምጽናት ገበን ንሕና ግቡእና ስለዝኾነ ኣብ ግዚኡ ኣፍልጦ ሂብናዮን ኮኒናዮን ኢና። ነዚ ሃገርና ኤርትራ ፈጺማቶ ዘላ ገበን፣ ከም ሓደ መቃለሲ ነጥቢ ተጠቒምና ገበነኛታት ሰበስልጣን ኤርትራ ኣብ ቤት ፍርዲ ግቡእ መቕጻዕቶም ክረኽቡ ቀጻሊ ቃልሲ ክንገብር ኢና። በዚ ምኽንያት ንሕናን ህዝቢ ትግራይን ናይ ሓባር ጸላኢ ስለዘሎና ብሓባር ኰና ንናይ ሓባር ጠቕሚ ክንቃለስ ባህርያውን መድረኻዊ ጠለብን ኢዩ።</p>
                <p>ኣብ ርእሲዚ ህዝቢ ትግራይ ኣብ መሰል ሃገረ ኤርትራ ዝኣምንን ዝተቓለሰን እንኮ ህዝቢ ኢዩ። ስለዚ ህዝቢ ኤርትራ ካብዚ ህዝቢዚ ንላዕሊ ስትራተጂካዊ መሓዛ የብሉን።</p>
                <p>ውልቀ ምልካዊ ግዝኣት ኤርትራ ንምቅላስ ብወተሃደራዊ ስትራተጂ ዓይኒ ክርአ እንከሎውን ምስ መንግስትን ህዝብን ትግራይ ክንቃለስ ምምራጽ፣ ንመስርሕ ቃልስና ኣብዚ እዋንዚ እቲ እንኮ መንገዲ ኢዩ። ንመጻኢውን ኣብዚ መዐንደሪ ዓለምለኻውን ከባቢያውን ሃገራት ኮይኑ ዘሎ ዞባና ጸጥታና ከነውሕስን ድሕነት ህዝብና ኣረጋጊጽና መንገዲ ሰላምን ምዕባለን ክንሕዝን ምስ ህዝቢ ትግራይ ብሓባር ምስራሕ ካልእ ኣማራጺ ዘይርከቦ ምርጫ ኢዩ።</p>
                <p>6. ምስ ፈደራላዊ መንግስቲ ኢትዮጲያ ክግበር ዝከኣል ናይ ሓባር ስራሕ ብዝምልከት ድማ፣ እቲ ውልቀ መላኺ መራሒ ኤርትራ ኢሰያስ ኣፈወርቂ ምስ ፈደራላዊ መንግስቲ ኢትዮጲያ ዝነበሮ ናይ ኲናትን ጽንተትን ብርሰትን ዝምድና ምሕርፋፉን ኣብዚ በጺሕዎ ደርጃ ምብጽሑ ጉባኤና ብኣዎንታ መዚንዎ። ፈደራላዊ መንግስቲ ኢትዮጲያ ምስ ተቓለስቲ ኤርትራ ኣብ ኢትዮጲያ ክንቀሳቐሱ ምፍቃዱ ድማ ኣገዳሲ ስጉምቲ ምዃኑ ርኢና። ብወገንና ከም ፈልሲ ምስ ፈደራላዊ መንግስቲ ኢትዮጲያ ኣብ ናይ ሕድሕድ ሃገር ልዑላውነት ምክብባርን፣ ኣብ ናይ ሓባር ጠቕሚ ዝተመሰረተን፣ ንጹር ውዕላት ዘለዎን ስምምዕ ክንገብር ቅሩባት ኢና። ምእላይ ውልቀ ምልካዊ ግዝኣት ህግደፍ፣ ንኤርትራ ጥራሕ ዘይኮነስ ንኢትዮጲያን ብሓፈሽኡ ንናይ ዞባና ሰላምን ምርግጋኣን ዕብየትን ኣድላዪ ቅድመ ኩነት ስለዝኾነ ኣብ ኤርትራ ናይ ስርዓት ለውጢ ንምምጻእ ዝዓለመ ምትሕብባር ክንገብር ቅሩባት ጥራሕ ዘይኮናስ ክዉን ክኸውን እውን ክንቃለስ ምዃና ነረጋግጽ።</p>
                <p>7. ብዘይካዚ ምስ ካልኦት ኣብ ከባቢና ሃገራት ዝርከቡ ህዝብታትን ሃገራትን፣ ዓበይቲ ሃገራትን ዓለም ለኻዊ ማሕበራትን ተሓባቢርና ነዚ ኣብ ኤርትራ ስልጣን ሒዙ ዘሎ ናይ ህውከትን ዕግርግርን ሓይሊ ኣብ ምእላይ ይዕበ ይንኣስ ሓገዝን ምትሕብባርን ክንረክብ ክንቃለስ ኢና።</p>
                <p>ኩሉኹም ኣባላት ፈልስን ካልኦት ተቓለስትን ህዝቢ ኤርትራን!</p>
                <p>ጉባኤና በዚ መንገዲዚ ዕማማቱ ወዲኡ ተጠናቒቑ ኣሎ። ንጹራት ዕላማታትን፣ ንጡፍ ውደባታን ጽፉፍ መደባትን ንተዓዋትነት ቃልሲ ኣገደስቲ መበገሲታት ኢዮም። እዚኣቶም ኣብ ባይታ ለውጢ ከመዝግቡ ዝኽእሉ ግን ብንቕሓትን ልባውነትን ምትሕግጋዝን ክንቃለስ ምስ እንኽእል ጥራሕ ኢዩ።</p>
                <p>ዝኸበርካ ህዝቢ ትግራይ!</p>
                <p>ፈልሲ ስትራተጂካዊ መሓዛኻ ምዃኑ ነረጋግጸልካ። ነቲ እዚ ገበንዚ ዝፈጸመ ውልቀ ምልካዊ ግዝኣት ኤርትራን ሰራዊት ኤርትራን ብፍርዲ ግቡእ መቕጻዕቶም ክረኽቡ ምእንቲ ምሳኻ ብሓባር ኮይና ክንቃለስ ምዃና ከነረጋግጽ ንደሊ። ነዚ ኣብ ኤርትራ ነጊሱ ዘሎ ጨካን ግዝኣት ብናይ ሓባር ቅላጽም ክንቃለሶ ንጽውዓካ።</p>
                <p>ንዝተረፉ ህዝብታት ኢትዮጲያን ፈደራላዊ መንግስቲ ኢትዮጲያን ድማ፣ ንናይ ሓባር ጠቕሚ ብሓባር ክንቃለስ ንጽውዓ።</p>
                <p>ንኹሎም ዞባውን ኣህጉራውን መንግስታትን ትካላትን ድማ ፍትሓዊ ቃልስና ተረዲኤን ኣብ ጎንና ደው ክብላ ንጽውዓ።</p>
                <p>ቀዳማይ ጉባኤ ፈሰዲኤ
                  <br />
                  29.06.2024
                </p>
              </div>
            </article>
            <p><a href="assets/pdf/press_release_19_tig.pdf">PDF ትግርኛ</a>.</p>
          </div>
          <p className="post-meta">
            Posted on June 29, 2024
          </p>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="post-preview">
          {/* If there is an English version available, it can be added here */}
        </div>
      </div>
    </div>
  );
}

export default June_29_2024;
