// src/cache.js
const TTL = 600000; // Cache time-to-live (TTL) in milliseconds (10 minutes)

const cache = {
  memberData: {},
  profilePicture: {},
  currentUserGroups: {},
  currentUser: null,
  members: null,
  timestamps: {
    memberData: {},
    profilePicture: {},
    currentUserGroups: {},
    currentUser: null,
    members: null
  }
};

const isCacheValid = (timestamp) => {
  return Date.now() - timestamp < TTL;
};

const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify({ data, timestamp: Date.now() }));
};

const loadFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  if (item) {
    const { data, timestamp } = JSON.parse(item);
    if (isCacheValid(timestamp)) {
      return data;
    }
  }
  return null;
};

export const clearCache = (type = null, key = null) => {
  if (type === 'memberData') {
    if (key) {
      delete cache.memberData[key];
      delete cache.timestamps.memberData[key];
      localStorage.removeItem(`memberData_${key}`);
    } else {
      cache.memberData = {};
      cache.timestamps.memberData = {};
      localStorage.removeItem('memberData');
    }
  } else if (type === 'profilePicture') {
    if (key) {
      delete cache.profilePicture[key];
      delete cache.timestamps.profilePicture[key];
      localStorage.removeItem(`profilePicture_${key}`);
    } else {
      cache.profilePicture = {};
      cache.timestamps.profilePicture = {};
      localStorage.removeItem('profilePicture');
    }
  } else if (type === 'currentUserGroups') {
    cache.currentUserGroups = {};
    cache.timestamps.currentUserGroups = {};
    localStorage.removeItem('currentUserGroups');
  } else if (type === 'currentUser') {
    cache.currentUser = null;
    cache.timestamps.currentUser = null;
    localStorage.removeItem('currentUser');
  } else if (type === 'members') {
    cache.members = null;
    cache.timestamps.members = null;
    localStorage.removeItem('members');
  } else {
    cache.memberData = {};
    cache.profilePicture = {};
    cache.currentUserGroups = {};
    cache.currentUser = null;
    cache.members = null;
    cache.timestamps = {
      memberData: {},
      profilePicture: {},
      currentUserGroups: {},
      currentUser: null,
      members: null
    };
    localStorage.clear();
  }
};

export { cache, isCacheValid, saveToLocalStorage, loadFromLocalStorage };
