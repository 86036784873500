import { useState } from 'react';

export default function Aug_25_2022() {
  const [collapsePRT6, setCollapsePRT6] = useState(false);

  const toggleCollapse = (id) => {
    if (id === "collapsePRT6") {
      setCollapsePRT6(!collapsePRT6);
    }
  };

  return (
    <div className="row gx-4 gx-lg-5">
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          <a
            onClick={() => toggleCollapse("collapsePRT6")}
            aria-expanded={collapsePRT6}
            aria-controls="collapsePRT6"
            style={{ cursor: 'pointer' }}
          >
            <h3>መግለጺ ደገፍ ፈልሲ ሰላምን ደሞክራስን ኤርትራ ንህዝባዊ ምልዕዓላት</h3>
          </a>
          <div className={`collapse ${collapsePRT6 ? 'show' : ''}`} id="collapsePRT6">
          <article>
                    <div>
                        <p>ስርዓት ህግደፍ ነቲ ኣብ ውሽጢ ሃገርና ኣብ ናይ ባርነት ኣርዑት ቆሪናዎ ዘሎ ህዝብና ከይኣኽሎ: ነዚ ኣብ ስደት ዘሎ ከይተረፈ: ብስም ሃገራዊ ፈስቲቫል ዕሱባት ካድረታት ልኢኹ: ብኩምራ ሓሶትን ጽልእን ዝተላዕጠጠ መርዛም ናይ ፕሮፓጋንዳ ቃላት ክነዝሕ ይውዕል ኣሎ። ህዝቢ ኤርትራ ግና ብኣንጻሩ ብሕራነ ንስርዓት ህግደፍ ንምእላይ: ቃልሱ ኣሐይልዎን ናብ ዝለዓለ ብርኪ ኣሰጋጊርዎን ይርከብ። እቲ ሎሚ ቅነ ብመንእሰያት ዝተማእከለ ኣብ ኣዉሮጳን ኣሜሪካን ዝካየድ ዘሎ ትብዓትን: ጥበብን ዝመልኦ ዕዉት ውዳበን ሰለማዊ ሰልፊታትን ህያዉ ኣብነታት እዮም።</p>
                        <p>በዚ ምኽንያት: ስርዓት ህግደፍ ብዙሕ ቁጠባዊን: ሰብኣዊ ሃልኪን ዘፍሰሰሉ ፈስቲቫል: ዘይሓሰቦ ሞራላዊ ውድቀት: ፖለቲካዊ ፍሽለትን ቁጠባዊ ክሳራን ወሪድዎ ኣብ ደንበርበር ይርከብ። ስርዓት ህግደፍ ተኣልዩ ህዝብና ሓራ ክሳብ ዝወጽእ: ከም’ዚ ዝኣመሰለ ህዝባዊ ተቓዉሞ ክቀጻጸል ይግባእ።</p>
                        <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ: ህዝቢን ሃገርን ንምድሓን: ሕጊን ኩነታትን ናይ’ተን ሃገራት ብዝፈቕዶ ዝካየድ ዘሎ ቃልሲ እንዳ ሞጎሰ: ከም ኣድላይነቱ ብቑጠባ: ብሞያዊ ክእለት: ሞራልን ካልእን ክተሓጋገዝ ቁሩብ ምዃኑ ከረጋግጽ ይፈቱ።</p>
                        <p>ፈልሲ ሰላምን ደሞክራስን ኤርትራ</p>
                    </div>
                </article>
                <p><a href="assets/pdf/press_release_6_tig.pdf">PDF ትግርኛ</a>.</p>
          </div>
          <p className="post-meta">Posted on Aug 25, 2022</p>
        </div>
      </div>
      <div className="col-sm-6">
        {/* Post preview */}
        <div className="post-preview">
          {/* Placeholder for the English version */}
        </div>
      </div>
    </div>
  );
}
